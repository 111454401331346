import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import StarIcon from "@material-ui/icons/Star";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
  Button,
  Paper,
  Grid,
  makeStyles,
  withStyles,
  Typography,
  // Link,
  Backdrop,
  CircularProgress,
  Breadcrumbs,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import "./Course.scss";
// import BookmarkIcon from '@material-ui/icons/Bookmark';
import CourseCard from "../../components/Cards/CourseCard/CourseCard";
import CircularProgressNugget from "../../components/CircularProgress/CircularProgressNugget";
import {
  TransitionsModal,
  ObjectPopUp,
  RatingPopUp,
} from "../../components/ModalPopUp/ModalPopUp";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { rcoursedetails } from "../../redux/Mycourse/MyCourseSlice";
import { API, Auth } from "aws-amplify";
import config from "../../aws-exports";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import Renderimage from "../../components/RenderImage/Renderimages";
import NuggetsLoader from "../../components/SkeletonLoader/NuggetsLoader";
import Cookies from "js-cookie";
import { Offline, Online } from "react-detect-offline";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { GridWrapper } from "./Course.Styled";

const StyledButton2 = withStyles({
  root: {
    background: "linear-gradient(45deg, #1C6D9C 100%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 50px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    marginTop: "3rem",
    fontSize: "16px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    "& > div": {
      "@media (max-width: 800px)": {
        //gridTemplateColumns: 'none',
        gridColumnGap: 0,
      },
      "& > div": {
        "@media (max-width: 800px)": {
          padding: "10px",
          gridColumn: "1/3!important",
        },
        // '@media (max-width: 333px)': {
        //   width: '96%!important',
        // },
      },
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    // height: '170px',
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'space-between',
  },
  body: {},
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "37px",
    flexDirection: "row",
  },
  paper1: {
    padding: theme.spacing(3),
    textAlign: "left",
    // height: '220px',
    backgroundColor: "white",
    cursor: "pointer",
  },
  paper2: {
    // padding: theme.spacing(3),
    textAlign: "left",
    backgroundColor: "white",
  },
  paper3: {
    // padding: theme.spacing(3),
    textAlign: "left",
    // height: '460px',
    maxHeight: "500px",
    // marginTop: '45px',
    backgroundColor: "white",
  },
  paper4: {
    textAlign: "left",
    // height: '460px',
    //marginTop: "-208px",
    backgroundColor: "white",
    width: "20vw",
  },
  outline: {
    fontSize: "3rem",
    fill: "none",
    stroke: "#646464",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  filled: {
    fontSize: "3rem",
    fill: "#FFDA46",
    stroke: "#FFDA46",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  bookmarkIconResponsive: {
    cursor: "pointer",
    marginLeft: "1.5rem",
    "@media (max-width: 800px)": {
      marginLeft: 0,
    },
  },
  iconResponsive: {
    fill: "#777",
    fontSize: "2.5rem",
    marginRight: ".5rem",
    marginLeft: "2.5rem",
    "@media (max-width: 400px)": {
      marginLeft: "0.8rem",
    },
  },
  iconResponsiveRating: {
    marginRight: "2.5rem",
    fontFamily: `myfontregular`,
    "@media (max-width: 400px)": {
      marginRight: "0.8rem",
      fontSize: "1.3rem",
    },
  },
  textResponsive: {
    "@media (max-width: 400px)": {
      fontSize: "1.3rem",
    },
  },
  flexHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 400px)": {
      display: "block",
    },
  },
  timeHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: "4rem",
    fontFamily: `myfontregular`,
    "@media (max-width: 1205px)": {
      padding: "0.5rem 1rem!important",
      justifyContent: "end",
    },
  },
  textSection: {
    fontSize: "1.55rem",
    textAlign: "justify",
    fontFamily: `myfontregular`,
    marginRight: "2rem",
    "@media (max-width: 800px)": {
      marginRight: 0,
      marginBottom: "3rem",
    },
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "2rem 0rem",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      //justifyContent: "start",
      width: "50%",
      margin: 0,
    },
  },
  progressDetails: {
    display: "flex",
    width: "100%",
    borderTop: "1px solid #777777",
    // marginTop: "3.5rem",
    alignItems: "center",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      marginTop: 0,
      justifyContent: "space-around",
      gap: "4px",
      borderTop: "none",
    },
  },
  details1: {
    width: "50%",
    height: "165px",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    textAlign: "center",
    borderRight: "1px solid #777",
    justifyContent: "center",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      gap: 0,
      width: "auto",
      borderRight: "none",
      height: "fit-content",
    },
  },
  details2: {
    width: "50%",
    height: "165px",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    textAlign: "center",
    justifyContent: "center",
    "@media (max-width: 800px)": {
      gap: 0,
      width: "auto",
      height: "fit-content",
    },
  },
  responsiveDetails: {
    "@media (max-width: 800px)": {
      display: "flex",
    },
  },
  moduleHeading: {
    padding: "0.8rem 1rem 0rem 1rem",
    marginBottom: "1rem",
    fontWeight: "600",
    "@media (max-width: 800px)": {
      padding: "1rem",
      margin: 0,
    },
  },
  progressHeading: {
    padding: "2.8rem 3rem 0rem 3rem",
    marginBottom: "1rem",
    fontWeight: "600",
    fontFamily: `myfontregular`,
    "@media (max-width: 800px)": {
      padding: 0,
      marginBottom: "2rem",
    },
  },
  contentMain: {
    fontSize: "19px",
    fontWeight: "800",
    "@media (max-width: 800px)": {
      fontSize: "14px",
      fontWeight: "800",
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Course() {
  let userDetails = useSelector(authData);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseResume, setcourseResume] = useState({});
  const [curObject, setCurObject] = useState({});
  const [curNugget, setCurNugget] = useState({});
  const [remaining, setRemaining] = useState({});
  const [curObjIndex, setCurObjIndex] = useState(0);
  const [curNuggetId, setCurNuggetId] = useState("");
  const [curNugIndex, setCurNugIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [tpProgress, setTpProgress] = useState("");
  const [markComplete, setMarkComplete] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [perScore, setPerScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);

  const lastobject = useRef(false);
  const [pause, setPause] = useState(true);
  const scormpPause = useRef(true);

  //let coursedetails = useSelector(state => state.Course);
  const [isLoading, setIsLoading] = useState(true);
  const variants = ["h4", "h4", "h4", "h4"];

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bmId, setBmId] = useState();
  const [isBookmarkedLoaded, setIsBookmarkedLoaded] = useState(false);
  const [showScore, setShowScore] = useState(false);

  const [startQuiz, setStartQuiz] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const coursedestailref = useRef({});
  let { courseId } = useParams();
  const history = useHistory();
  let query = useQuery();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isQuizLoading, setIsQuizLoading] = useState(false);

  let src = `${
    config.aws_cloudfront_url
  }${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
    courseDetails.tid
  }.png`;

  useEffect(() => {
    if (!userDetails && config.aws_org_id != "EXCELSOFT") {
      var obj = {};
      obj.tid = query.get("id");
      obj.type = "course";
      localStorage.setItem("deeplink", JSON.stringify(obj));
      //history.push(`/`);
      if (config.aws_org_id == "AXIATA") {
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    } else if (query.get("course_id") !== undefined) {
      if (
        config.aws_org_id == "EXCELSOFT" &&
        query.get("user_id") !== undefined
      ) {
        getUserVerify();
      } else {
        getTopicDetails();
      }
    }
    window.scrollTo(0, 0);
    window.nextbtn = undefined;
    return () => {
      if (
        coursedestailref.current.userdataset !== undefined &&
        coursedestailref.current.userdataset.userdata == false
      ) {
        updateCourseAnalytics("bounce");
      }
    };
  }, [query.get("id")]);
  // alert(JSON.stringify(courseDetails.prerequisites, null, 2));
  const renderyourprogreess = (param) => {
    console.log(param);
    // let style1='grid-item4';
    return (
      <Paper
        elevation={5}
        className={` ${
          courseDetails.prerequisites &&
          courseDetails.prerequisitescourses?.length > 0
            ? classes.paper3
            : matches
            ? classes.paper3
            : classes.paper4
        } ${param.style1}`}
      >
        <h3 className={classes.progressHeading}>Your progress</h3>
        <div className={classes.responsiveDetails}>
          <Paper elevation={0} className={classes.progressContainer}>
            {open ? (
              <></>
            ) : (
              <>
                <CircularProgressNugget
                  courseDetails={courseDetails}
                  totalProgress={totalProgress}
                />
              </>
            )}
          </Paper>
          {courseDetails.userdataset.userdata === false ||
          tpProgress.charAt(0) == 1 ? (
            <></>
          ) : (
            <>
              <Paper elevation={0} className={classes.btnContainer}>
                {/* <StyledButton2>View assessment scores</StyledButton2> */}
              </Paper>
            </>
          )}

          <div
            className={classes.progressDetails}
            // style={{
            //   display: "flex",
            //   width: "100%",
            //   borderTop: "1px solid #777",
            //   marginTop:
            //     courseDetails.userdataset.userdata === false ||
            //     tpProgress.charAt(0) == 1
            //       ? "4.5rem"
            //       : "3.5rem",
            //   alignItems: "center",

            //   fontFamily: `myfontregular`,
            // }}
          >
            <div className={classes.details1}>
              <p className="centeritem">
                <div className={"bgimage modules "}></div>
              </p>
              <Typography
                component="h5"
                variant="h5"
                style={{ fontFamily: `myfontregular` }}
              >
                Modules remaining
              </Typography>

              <Typography
                component="h4"
                variant="h4"
                className={classes.contentMain}
              >
                {remaining.mremaining}
              </Typography>
            </div>
            <div className={classes.details2}>
              <p className="centeritem">
                {/* <div className={'bgimage timer '}></div> */}
                <AccessTimeIcon
                  style={{
                    fill: "#000",
                    fontSize: "2.8rem",
                    opacity: ".5",
                  }}
                />
              </p>
              <Typography
                component="h5"
                variant="h5"
                style={{ fontFamily: `myfontregular` }}
              >
                Time remaining
              </Typography>

              <Typography
                component="h4"
                variant="h4"
                className={classes.contentMain}
              >
                {Math.floor(remaining.tremaining / 3600)}h{" "}
                {Math.floor((remaining.tremaining % 3600) / 60)}m{" "}
                {Math.floor((remaining.tremaining % 3600) % 60)}s
              </Typography>
            </div>
          </div>
        </div>
      </Paper>
    );
  };
  async function getUserVerify() {
    try {
      setLoading(true);
      var bodyParam = {};
      bodyParam.body = {};
      bodyParam.body.user_id = query.get("user_id");
      bodyParam.body.course_id = query.get("course_id");
      console.log("getUserVerified==", bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserVerified",
        bodyParam
      );
      let udtt = { ...userDetails };
      udtt.uData = response.users;
      dispatch(awsSignIn(udtt));
      getTopicDetails(response.users);
    } catch (error) {
      setLoading(false);
    }
  }
  async function getTopicDetails(udtt) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.username,
        tenant: userDetails.locale,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        topicid: query.get("id"),
        urid: userDetails?.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      config.aws_org_id == "EXCELSOFT" &&
      query.get("user_id") !== undefined
    ) {
      bodyParam.body.urid = udtt.ur_id;
      bodyParam.body.tenant = udtt.oid;
      bodyParam.body.topicid = query.get("course_id");
    }
    setLoading(true);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getTopicDetails",
        bodyParam
      );

      coursedestailref.current.userdataset = response.userdataset;
      coursedestailref.current.tid = response.tid;
      setCourseDetails(response);
      console.log(response);
      if (
        response.userdataset !== undefined &&
        response.userdataset.userprogress !== undefined &&
        response.userdataset.userprogress.bookmark === true
      ) {
        setIsBookmarked(true);
      } else {
        setIsBookmarked(false);
      }
      setcourseResume(response.nresume);
      setRemaining({
        mremaining: response.mremaining,
        tremaining: response.tremaining,
      });
      if (response.userdataset.userdata == true) {
        setTotalProgress(
          response.userdataset.cobj === undefined
            ? 0
            : response.userdataset.cobj
        );
        setTpProgress(response.userdataset.tp);
      }
      updateRecentViewed(response);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      setLoading(false);
      setIsLoading(false);
    } catch (err) {
      setLoading(false);
      setIsLoading(false);
      console.error(err);
    }
  }

  async function addAndRemoveBookmark(val) {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tid: courseDetails.tid,
          type: "topics",
          eid: userDetails.username,
          userfullname: userDetails.name,
          emailid: userDetails?.email,
          tenant: userDetails?.locale,
          ur_id: userDetails?.uData?.ur_id,
          bookmark: val === 1 ? true : false,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (val === 1) {
        bodyParam.body.bookmark_date = 1;
      }
      // let apiname = val === 1 ? "/addBookMark" : "/deleteBookmark";

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );

      val === 1 ? setIsBookmarked(true) : setIsBookmarked(false);
      setIsBookmarkedLoaded(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function getQuizScore(objects) {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setLoading(true);
      setIsQuizLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          emailid: userDetails.email,
          obj_id: objects.oid,
          ur_id: userDetails.uData.ur_id,
          tid: courseDetails.tid,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuizScore",
        bodyParam
      );

      setShowScore(true);
      setLoading(false);
      setIsQuizLoading(false);
      setStartQuiz(true);
      if (response && response.length > 0) {
        setPerScore(response[response?.length - 1]);
        setTotalScore(response[response?.length - 1]);
      }
    } catch (error) {
      console.error(error);
      setLoading(true);
      setIsQuizLoading(false);
    }
  }
  async function updateRecentViewed(cDetails) {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          tid: cDetails.tid,
          title: cDetails.ttitle,
          type: "topic",
          pdate: new Date().getTime(),
          tduration: cDetails.tduration,
          noofnuggets: cDetails.noofnuggets,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateRecentViewed",
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function updateCourseAnalytics(ctype) {
    const bodyParam1 = {
      body: {
        oid: config.aws_org_id,
        tid: courseDetails.tid,
        email: userDetails.username,
        tenant: userDetails.locale,
        ctype,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (ctype == "bounce") {
      bodyParam1.body.bounce_cnt = 1;
    }
    if (ctype == "completed") {
      bodyParam1.body.comp_date = 1;
    }
    //updateCourseAnalytics
    console.log(ctype, "updateTopicReport=", bodyParam1.body);
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam1
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function ProgramView(pid) {
    if (userDetails.location.pFrom !== undefined) {
      return `/ExploreCourseView?from=0&id=${pid}`;
    } else {
      return `/ExploreCourseView?id=${pid}`;
    }
  }
  async function GetApiCall(json, obj2) {
    var dd = await fetch(
      "https://kqd29za2e4.execute-api.ap-south-1.amazonaws.com/EN938437/getScormData",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: json,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.nextbtn = "get";
        console.log("GetApiCall Success:", data.body);
        if (data?.body?.stime !== undefined && data?.body?.stime !== null) {
          //window.API.LMSSetValue("cmi.core.session_time",data.body.stime);
          obj2.stime = data.body.stime;
          console.log("session time set value done");
        }
        if (
          data?.body?.suspend_data !== undefined &&
          data?.body?.suspend_data !== null
        ) {
          //data["cmi.suspend_data"] = data.body.suspend_data;
          obj2.sdta = data.body.suspend_data;
          window.cudetails = obj2;

          console.log("suspend data set value done");
        } else {
          obj2.sdta = "";
          window.cudetails = obj2;
        }
        if (data?.body?.op === 2) {
          window.cudetails.op = 2;
        }

        return true;
        //return data.body.suspend_data;
      })
      .catch((error) => {
        window.nextbtn = "get";
        console.error("LMSSetValue Error:", error);
        return true;
      });
    return dd;
  }

  return (
    <div>
      {courseDetails.id !== "1" && courseDetails.tstatus !== false ? (
        <>
          {userDetails !== 0 &&
            userDetails !== undefined &&
            userDetails.location !== undefined && (
              <>
                {userDetails.location.from !== undefined &&
                  (userDetails.location.from === "Home" ||
                    userDetails.location.from === "Bookmarks" ||
                    userDetails.location.from === "View All Courses" ||
                    userDetails.location.from === "Explore" ||
                    userDetails.location.from === "Search") && (
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          fontSize="large"
                          style={{ fill: "#1974b5" }}
                        />
                      }
                      aria-label="breadcrumb"
                      style={{ marginBottom: "2rem", cursor: "pointer" }}
                    >
                      {userDetails.location.from == "Home" && (
                        <Link
                          color="inherit"
                          to={
                            userDetails.location.from === "Home" ? "/home" : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.from === "Home"
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.from === "Home"
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                        >
                          {userDetails.location.from}
                        </Link>
                      )}

                      {userDetails.location.from == "View All Courses" ? (
                        <Link
                          color="inherit"
                          to={
                            userDetails.location.from === "View All Courses"
                              ? "/viewallcourses"
                              : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.from === "View All Courses"
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.from === "View All Courses"
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                        >
                          {userDetails.location.from}
                        </Link>
                      ) : (
                        ""
                      )}

                      {userDetails.location.from == "Explore" && (
                        <Link
                          to={
                            userDetails.location.from === "Explore"
                              ? "/home"
                              : "#"
                          }
                          style={{
                            color: "#777",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: "context-menu",
                          }}
                          color="inherit"
                        >
                          {userDetails.location.from}
                        </Link>
                      )}

                      {userDetails.location.from == "Search" && (
                        <Link
                          to={
                            userDetails.location.from === "Search"
                              ? "/home"
                              : "#"
                          }
                          style={{
                            color: "#777",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: "context-menu",
                          }}
                          color="inherit"
                        >
                          {userDetails.location.from}
                        </Link>
                      )}

                      {userDetails.location.from == "Bookmarks" && (
                        <Link
                          color="inherit"
                          to={
                            userDetails.location.from === "Bookmarks"
                              ? "/bookmarks"
                              : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.from === "Bookmarks"
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.from === "Bookmarks"
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                        >
                          {userDetails.location.from}
                        </Link>
                      )}

                      {userDetails &&
                        userDetails?.location &&
                        userDetails?.location.topicName && (
                          <Link
                            to={
                              userDetails.location.courseName
                                ? `/explore/${userDetails?.location?.topicName?.replaceAll(
                                    /\s/g,
                                    "-"
                                  )}/${userDetails?.location?.topicId}`
                                : "#"
                            }
                            style={{
                              color: `${
                                userDetails.location.courseName
                                  ? "#1974b5"
                                  : "#777"
                              }`,
                              fontSize: "1.5rem",
                              textDecoration: "none",
                              cursor: `${
                                userDetails.location.courseName
                                  ? "pointer"
                                  : "context-menu"
                              }`,
                            }}
                            color="inherit"
                          >
                            {userDetails.location.topicName}
                          </Link>
                        )}

                      {userDetails.location.viewAll && (
                        <Link
                          to={
                            userDetails.location.viewAllRoute
                              ? userDetails.location.viewAllRoute
                              : "/ExploreViewAll"
                          }
                          color="inherit"
                          style={{
                            color: "#1974b5",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          View all
                        </Link>
                      )}
                      {userDetails.location.programName && (
                        <Link
                          to={ProgramView(userDetails.location.programId)}
                          style={{
                            color: "#1974b5",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                          }}
                          color="inherit"
                        >
                          {userDetails.location.programName}
                        </Link>
                      )}

                      {userDetails.location.courseName && (
                        <Link
                          style={{
                            color: "#777",
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: "context-menu",
                          }}
                          color="inherit"
                        >
                          {userDetails.location.courseName}
                        </Link>
                      )}
                    </Breadcrumbs>
                  )}
              </>
            )}

          <div className="flex-between">
            <div className="flex-container flex-container-responsive">
              {isLoading ? (
                <>
                  <Typography
                    component="h1"
                    variant="h3"
                    className="loadingcss"
                  >
                    <Skeleton animation="wave" />
                  </Typography>
                </>
              ) : (
                <>
                  <h3 style={{ fontWeight: "600" }}>{courseDetails.ttitle}</h3>
                </>
              )}

              <div className="responsive">
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ minWidth: "80px", margin: "5px" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <>
                    <span
                      className="mx responsiveNone"
                      style={{
                        color: "#555555",
                        borderRight: "2px solid #555555",
                        height: "25px",
                      }}
                    ></span>
                    <StarRoundedIcon
                      className="icon icon-star"
                      style={{ marginRight: ".5rem" }}
                    />
                    <span className={classes.iconResponsiveRating}>
                      {courseDetails?.star?.toFixed(1) || "0.0"}
                    </span>
                  </>
                )}

                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ minWidth: "80px", margin: "5px" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <>
                    <div
                      className={"bgimage modules "}
                      style={{ height: "30px", width: "25px" }}
                    ></div>
                    <span className={classes.textResponsive}>
                      {courseDetails.noofnuggets} Modules
                    </span>
                  </>
                )}

                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ minWidth: "80px", margin: "5px" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : (
                  <>
                    <AccessTimeIcon className={classes.iconResponsive} />
                    <span className={classes.textResponsive}>
                      {Math.floor(courseDetails.tduration / 3600)}h{" "}
                      {Math.floor((courseDetails.tduration % 3600) / 60)}m{" "}
                      {Math.floor((courseDetails.tduration % 3600) % 60)}s
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="flex-container">
              {isLoading ? (
                <></>
              ) : (
                <>
                  {courseDetails.userdataset.userdata == true &&
                  tpProgress !== undefined &&
                  !open &&
                  courseDetails.userdataset.userprogress !== undefined &&
                  (courseDetails.userdataset.userprogress.rating ===
                    undefined ||
                    courseDetails.userdataset.userprogress.rating === null) &&
                  (tpProgress.charAt(0) == 2 || tpProgress.charAt(0) == 3) ? (
                    <>
                      <RatingPopUp courseDetails={courseDetails} />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {isLoading ? (
                <>
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ minWidth: "80px", margin: "5px" }}
                  >
                    <Skeleton animation="wave" />
                  </Typography>
                </>
              ) : (
                <>
                  {isBookmarked ? (
                    <div
                      className={classes.bookmarkIconResponsive}
                      onClick={() => {
                        setIsBookmarkedLoaded(true);
                        addAndRemoveBookmark(0);
                      }}
                    >
                      <BookmarkRoundedIcon className={classes.filled} />
                    </div>
                  ) : (
                    <div
                      className={classes.bookmarkIconResponsive}
                      onClick={() => {
                        setIsBookmarkedLoaded(true);
                        addAndRemoveBookmark(1);
                      }}
                    >
                      <BookmarkRoundedIcon className={classes.outline} />
                    </div>
                  )}{" "}
                </>
              )}

              <Backdrop className={classes.backdrop} open={isBookmarkedLoaded}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>

          <div className={classes.root}>
            <GridWrapper
              layout={
                courseDetails.prerequisites &&
                courseDetails.prerequisitescourses?.length > 0
                  ? true
                  : false
              }
            >
              <Paper elevation={5} className={`${classes.paper} grid-item1`}>
                <div>
                  <h3 style={{ fontWeight: "600", marginBottom: "1rem" }}>
                    Overview
                  </h3>
                  {isLoading ? (
                    <>
                      {variants.map((variant) => (
                        <Typography
                          component="h1"
                          variant={variant}
                          className="loadingcss"
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      ))}
                    </>
                  ) : (
                    <>
                      <Paper
                        elevation={0}
                        className={`${classes.body} responsiveOverview`}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.textSection}
                          gutterBottom
                        >
                          {courseDetails.tdescription}
                        </Typography>
                        <img src={src} className="responsiveImage" />
                      </Paper>
                    </>
                  )}
                </div>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h1"
                      style={{ margin: "6px auto" }}
                    >
                      <Skeleton animation="wave" width={150} height={60} />
                    </Typography>
                  </>
                ) : (
                  <>
                    {courseDetails.userdataset.userdata ? (
                      <Paper
                        style={{ marginTop: "30px" }}
                        elevation={0}
                        className={classes.btnContainer}
                      >
                        <ObjectPopUp
                          open={open}
                          setOpen={setOpen}
                          setCourseDetails={setCourseDetails}
                          courseDetails={courseDetails}
                          setCurObject={setCurObject}
                          curObject={curObject}
                          setCurNugget={setCurNugget}
                          curNugget={curNugget}
                          setCurObjIndex={setCurObjIndex}
                          curObjIndex={curObjIndex}
                          setCurNuggetId={setCurNuggetId}
                          curNuggetId={curNuggetId}
                          progress={progress}
                          setProgress={setProgress}
                          curNugIndex={curNugIndex}
                          setCurNugIndex={setCurNugIndex}
                          markComplete={markComplete}
                          setMarkComplete={setMarkComplete}
                          setStartTime={setStartTime}
                          startTime={startTime}
                          courseResume={courseResume}
                          setcourseResume={setcourseResume}
                          remaining={remaining}
                          setRemaining={setRemaining}
                          setTotalProgress={setTotalProgress}
                          totalProgress={totalProgress}
                          tpProgress={tpProgress}
                          setTpProgress={setTpProgress}
                          perScore={perScore}
                          setPerScore={setPerScore}
                          totalScore={totalScore}
                          setTotalScore={setTotalScore}
                          showScore={showScore}
                          setShowScore={setShowScore}
                          startQuiz={startQuiz}
                          setStartQuiz={setStartQuiz}
                          loading={loading}
                          setLoading={setLoading}
                          certificate={certificate}
                          setCertificate={setCertificate}
                          isLoading={isQuizLoading}
                          setIsLoading={setIsQuizLoading}
                          updateCourseAnalytics={updateCourseAnalytics}
                          scormpPause={scormpPause}
                          getTopicDetails={getTopicDetails}
                          lastobject={lastobject}
                        />
                      </Paper>
                    ) : (
                      <Paper elevation={0} className={classes.btnContainer}>
                        <TransitionsModal
                          open={open}
                          setOpen={setOpen}
                          setCourseDetails={setCourseDetails}
                          setCurNugget={setCurNugget}
                          setCurObject={setCurObject}
                          setCurObjIndex={setCurObjIndex}
                          setCurNuggetId={setCurNuggetId}
                          setCurNugIndex={setCurNugIndex}
                          setCertificate={setCertificate}
                          setProgress={setProgress}
                          setMarkComplete={setMarkComplete}
                          setStartTime={setStartTime}
                          courseDetails={courseDetails}
                          setTpProgress={setTpProgress}
                          setcourseResume={setcourseResume}
                          updateCourseAnalytics={updateCourseAnalytics}
                          scormpPause={scormpPause}
                        />
                      </Paper>
                    )}
                  </>
                )}
              </Paper>

              <Paper elevation={5} className={`${classes.paper} grid-item2`}>
                <h3 className={classes.moduleHeading}>Modules</h3>
                {isLoading ? (
                  <>
                    <NuggetsLoader type="nuggetobject"></NuggetsLoader>
                    <NuggetsLoader type="nuggetobject"></NuggetsLoader>
                    <NuggetsLoader type="nuggetobject"></NuggetsLoader>
                  </>
                ) : (
                  <>
                    {courseDetails.nuggets.map((nuggets, nindex) => (
                      <>
                        <div
                          style={{
                            marginTop: "2rem",
                            marginBottom: "1rem",
                            fontFamily: `myfontregular`,
                          }}
                        >
                          <div className={classes.flexHeader}>
                            <Typography
                              component="h4"
                              variant="h5"
                              className="modulesSubHead modulesSubHeadResponsive"
                              style={{
                                fontFamily: `myfontregular`,
                                width: "fit-content",
                              }}
                            >
                              {nuggets.ntitle}
                            </Typography>
                            <Typography className={classes.timeHeader}>
                              <AccessTimeIcon
                                style={{
                                  fontSize: "21px",
                                  marginRight: "8px",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "15px",
                                  width: "max-content",
                                }}
                              >
                                {Math.floor(nuggets.nduration / 3600)}h{" "}
                                {Math.floor((nuggets.nduration % 3600) / 60)}m{" "}
                                {Math.floor((nuggets.nduration % 3600) % 60)}s
                              </span>
                            </Typography>
                          </div>
                        </div>
                        {nuggets.objects.map((objects, index) => (
                          <div className="objflex-between">
                            <Typography
                              component="h3"
                              variant="h5"
                              className="modulesObjecticon"
                            >
                              <Renderimage
                                type={objects.otype.toLowerCase()}
                                ostatus={objects.op}
                              ></Renderimage>
                            </Typography>
                            <Typography
                              component="h3"
                              variant="h5"
                              className="modulesObject"
                            >
                              {objects.op === 0 ? (
                                <>{objects.otitle}</>
                              ) : (
                                <>
                                  <Link
                                    component="button"
                                    color="inherit"
                                    style={{
                                      fontSize: "1.6rem",
                                      textAlign: "left",
                                      color: "#1C6D9C",
                                      background: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                      fontFamily: `myfontregular`,
                                    }}
                                    variant="body2"
                                    onClick={async () => {
                                      let obj = {};
                                      obj.curNugget = nuggets;
                                      obj.curObject = objects;
                                      obj.robject = index;
                                      setCurNugget(nuggets);

                                      setCurObject(objects);
                                      setCurObjIndex(index);
                                      setCurNuggetId(nuggets.nid);
                                      setCurNugIndex(nindex);
                                      setLoading(false);
                                      setIsQuizLoading(false);
                                      setCertificate(false);
                                      if (
                                        objects.qtype == 1 &&
                                        objects.op == 2
                                      ) {
                                        setLoading(true);
                                        setLoading(false);
                                        setIsQuizLoading(false);
                                        getQuizScore(objects);
                                        // getTopicDetails();
                                      }

                                      if (
                                        objects.op == 1 &&
                                        courseDetails.userdataset !==
                                          undefined &&
                                        courseDetails.userdataset.tresume !==
                                          undefined &&
                                        courseDetails.userdataset.tresume.oid ==
                                          objects.oid
                                      ) {
                                        setProgress(
                                          courseDetails.userdataset.tresume
                                            .obtime
                                        );
                                      } else {
                                        setProgress(
                                          Number(
                                            nuggets.objects[index].oduration
                                          )
                                        );
                                      }
                                      var obj2 = {};
                                      obj2.ur_id = userDetails.uData?.ur_id;
                                      obj2.cid = query.get("id");
                                      obj2.tid = query.get("id");
                                      obj2.objid = objects.oid;
                                      obj2.atype = 0;
                                      obj2.schema = config.schema;
                                      obj2.curObjIndex = index;
                                      obj2.objlength =
                                        nuggets.objects.length - 1;
                                      obj2.curNugIndex = nindex;
                                      obj2.nuggetlength =
                                        courseDetails.nuggets.length - 1;

                                      console.log(
                                        "object clcikc",
                                        objects.otype,
                                        objects.op
                                      );
                                      if (
                                        objects.otype === "scorm" &&
                                        objects.op == 1
                                      ) {
                                        scormpPause.current = false;
                                        var tobj = JSON.stringify(obj2);
                                        var val = await GetApiCall(tobj, obj2);
                                        if (val) {
                                          setMarkComplete(false);
                                          setStartTime(new Date().getTime());
                                          setOpen(true);
                                        }
                                      } else {
                                        setMarkComplete(false);
                                        setStartTime(new Date().getTime());
                                        setOpen(true);
                                      }
                                    }}
                                  >
                                    {objects.otitle}
                                  </Link>

                                  {/* <Offline>
                                    <Link
                                      component='button'
                                      color='inherit'
                                      style={{
                                        fontSize: '1.6rem',
                                        textAlign: 'left',
                                      }}
                                      variant='body2'
                                    >
                                      {objects.otitle}
                                    </Link>
                                  </Offline> */}
                                </>
                              )}
                            </Typography>
                          </div>
                        ))}
                      </>
                    ))}
                  </>
                )}
              </Paper>

              {isLoading ? (
                <>
                  <Paper
                    elevation={5}
                    className={`${classes.paper} grid-item3`}
                  >
                    <h3 style={{ marginBottom: "2.4rem", fontWeight: "600" }}>
                      Prerequisites
                    </h3>
                    <Typography
                      component="h1"
                      variant="h5"
                      style={{ minWidth: "150px" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </Paper>
                </>
              ) : (
                <>
                  {courseDetails.prerequisites &&
                    courseDetails.prerequisitescourses?.length > 0 && (
                      <Paper
                        elevation={5}
                        className={`${classes.paper} grid-item3`}
                        onClick={() => {
                          let breadcrumb = { ...userDetails };

                          breadcrumb.location = {
                            from: "Home",
                            courseName:
                              courseDetails.prerequisitescourses[0]?.ttitle,
                          };
                          dispatch(awsSignIn(breadcrumb));

                          history.push(
                            `/course?id=${courseDetails.prerequisitescourses[0]?.tid}`
                          );
                          // window.location.reload();
                        }}
                      >
                        <h3
                          variant="h4"
                          style={{
                            marginBottom: "2.4rem",
                            fontWeight: "600",
                          }}
                        >
                          Prerequisites
                        </h3>
                        <CourseCard
                          title={courseDetails.prerequisitescourses[0]?.ttitle}
                          tid={courseDetails.prerequisitescourses[0]?.tid}
                          courseId={query.get("id")}
                        />
                      </Paper>
                    )}
                </>
              )}

              {isLoading ? (
                <>
                  <Paper
                    elevation={5}
                    className={`${classes.paper} grid-item4`}
                  >
                    <h3 className={classes.progressHeading}>Your progress</h3>
                    <Paper
                      elevation={0}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "4rem",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="h1"
                        style={{ fontFamily: `myfontregular` }}
                      >
                        <Skeleton
                          animation="wave"
                          variant="circle"
                          width={140}
                          height={140}
                        />
                      </Typography>
                    </Paper>

                    <div className={classes.progressDetails}>
                      <div className={classes.details1}>
                        {/* <span>icon</span> */}
                        <Typography
                          component="h5"
                          variant="h5"
                          style={{ fontFamily: `myfontregular` }}
                        >
                          Modules remaining
                        </Typography>

                        <Typography
                          component="h1"
                          variant="h5"
                          style={{
                            minWidth: "10px",
                            padding: "0 80px",
                            fontFamily: `myfontregular`,
                          }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </div>
                      <div className={classes.details2}>
                        {/* <span>icon</span> */}
                        <Typography component="h5" variant="h5">
                          Time remaining
                        </Typography>

                        <Typography
                          component="h1"
                          variant="h5"
                          style={{
                            minWidth: "10px",
                            padding: "0 80px",
                            fontFamily: `myfontregular`,
                          }}
                        >
                          <Skeleton animation="wave" />
                        </Typography>
                      </div>
                    </div>
                  </Paper>
                </>
              ) : (
                <>
                  {courseDetails.prerequisites === true ? (
                    <>
                      {renderyourprogreess(
                        courseDetails,
                        (courseDetails.style1 = "grid-item4")
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      {renderyourprogreess(
                        courseDetails,
                        (courseDetails.style1 = "grid-item5")
                      )}{" "}
                    </>
                  )}
                </>
              )}
            </GridWrapper>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "90vh",
            width: "100vw",
            display: "grid",
            placeItems: "center",
          }}
        >
          {config.aws_org_id != "EXCELSOFT" ? (
            <div
              style={{
                width: "400px",
                height: "200px",
                background: "#1974b5",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: ".8rem",
              }}
            >
              <div style={{ margin: "2rem 0", textAlign: "center" }}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "1.75rem",
                    fontWeight: "600",
                  }}
                >
                  Course you are looking for could not be found
                </p>
                <button
                  style={{
                    border: "none",
                    color: "#1974b5",
                    background: "#fff",
                    padding: ".5rem 2rem",
                    marginTop: "2rem",
                    borderRadius: ".4rem",
                    fontSize: "1.45rem",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/home")}
                >
                  Go to dashboard
                </button>
              </div>
              <div
                style={{
                  marginBottom: "2rem",
                  color: "#fff",
                }}
              >
                <span style={{ marginRight: ".5rem" }}> Please contact</span>
                <a href="https://www.help@excelearn.com">help@excelearn.com</a>
                <span style={{ marginLeft: ".5rem" }}> for assistance</span>
              </div>
            </div>
          ) : (
            <>Contact Admin</>
          )}
        </div>
      )}
    </div>
  );
}

export default Course;
