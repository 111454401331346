import "./assets/scss/reset.scss";
import "./assets/scss/base.scss";
import LandingPage from "./pages/LandingPage/LandingPage";
// import Loign from "./pages/Login/Login.js";
import SignInAndSignUp from "./components/SignInAndSignUp/SignInAndSignUp";

import "./App.scss";

import React from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Course from "./pages/Course/Course";
import RadioObject from "./pages/RadioObject/RadioObject";
import Explore from "./pages/Explore/Explore";
import CourseView from "./pages/Course/CourseView/CourseView";
import ExploreViewAll from "./pages/Explore/ExploreViewAll";
import ExploreCourseView from "./pages/Explore/ExploreCourseView";
import ExploreCourseViews from "./pages/Explore/ExploreCourseViews";
import MyProfile from "./pages/MyProfile/MyProfile";
import BookmarkView from "./pages/BookmarkView/BookmarkView";
// import AllProgramCard from './components/Cards/AllProgramCard/AllprogramCard';
import AllProgramView from "./pages/AllProgramView/AllProgramView";
import ViewAll from "./pages/ViewAll/ViewAll";
import Calender from "./pages/Calender/Calender";
import SharedObject from "./pages/SharedObject/SharedObject";
import { useDispatch, useSelector } from "react-redux";
import { authData, awsSignIn } from "./redux/auth/authSlice";
import ViewAllCourses from "./pages/ViewAllCourses/ViewAllCourses";
import AllPrograms from "./pages/ViewAll/AllPrograms/AllPrograms";
import MyPrograms from "./pages/ViewAll/MyPrograms/MyPrograms";
import ContinueLearning from "./pages/ViewAll/ContinueLearning/ContinueLearning";
import AllTopics from "./pages/ViewAll/AllTopics/AllTopics";
import { useNavigatorStatus } from "react-navigator-status";

import Layout from "./components/Layout/Layout";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import "../src/font/NunitoSans-Regular.ttf";
import config, { Theme } from "./aws-exports";
import Discussion from "./pages/Discussion/Discussion";
import { ThemeProvider } from "styled-components";
import { useQuery } from "react-query";
import { API } from "aws-amplify";
import CardLarge from "./components/Cards/CardLarge/CardLarge";
function App() {
  const userAuthStateValue = useSelector(authData);
  const isOnline = useNavigatorStatus();
  const location = useLocation();
  const Alert = ({ isOnline }) => {
    const [showAlert, setShowAlert] = React.useState(false);

    React.useEffect(() => {
      let isMounted = true;

      if (isOnline && showAlert && isMounted) {
        setShowAlert(true);

        setTimeout(() => {
          if (isMounted) {
            setShowAlert(false);
          }
        }, 4000);
      }

      if (!isOnline && isMounted) {
        setShowAlert(true);
      }

      return () => {
        isMounted = false;
      };
    }, [isOnline]);

    return (
      <div
        style={{
          fontFamily: `myfontregular`,
        }}
      >
        {showAlert && (
          <div
            style={{
              color: "white",
              // padding: 20,
              // marginBottom: 20,
              // background: isOnline ? `rgb(59, 201, 149)` : `#ff5733`,

              fontFamily: `myfontregular`,
              fontWeight: "500",
              fontSize: "14px",
              padding: "5px 10px",
              width: "fit-content",
              height: "fit-content",
              position: "absolute",
              right: "35%",
              top: 90,
              zIndex: 1000,
              border: "1px solid red",
              borderRadius: "5px",
              background: "red",
            }}
          >
            {isOnline
              ? `You are online`
              : `You are offline please check your connection`}
          </div>
        )}
      </div>
    );
  };

  if (
    (config.aws_org_id === "EXCELSOFT" || userAuthStateValue) &&
    location.pathname !== "/sharingobject"
  ) {
    return (
      <ThemeProvider theme={Theme}>
        <Layout>
          <AnimatePresence>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={SignInAndSignUp}>
                {userAuthStateValue ? (
                  <Redirect to="/home" />
                ) : (
                  <SignInAndSignUp />
                )}
              </Route>
              <Route exact path="/home" component={LandingPage} />
              <Route exact path="/course" component={Course} />
              <Route exact path="/content" component={RadioObject} />
              <Route exact path="/ExploreViewAll" component={ExploreViewAll} />
              <Route
                exact
                path="/ExploreCourseView"
                component={ExploreCourseView}
              />
              <Route
                exact
                path="/ExploreCourseViews"
                component={ExploreCourseViews}
              />
              <Route exact path="/explore/:name/:id" component={Explore} />
              <Route exact path="/courseview" component={CourseView} />
              <Route exact path="/programview" component={AllProgramView} />
              <Route exact path="/viewallcontent" component={ViewAll} />
              <Route exact path="/profile" component={MyProfile} />
              <Route exact path="/bookmarks" component={BookmarkView} />
              <Route exact path="/calender" component={Calender} />
              <Route path="/viewAllCourses" component={ViewAllCourses} />
              <Route path="/viewAllPrograms" component={AllPrograms} />
              <Route path="/viewAllTopics" component={AllTopics} />
              <Route path="/discussion" component={Discussion} />
              <Route
                path="/viewAllContinueLearning"
                component={ContinueLearning}
              />
              <Route path="/viewAllMyPrograms" component={MyPrograms} />

              <Route path="*" component={SignInAndSignUp} />
            </Switch>
          </AnimatePresence>
          {isOnline === false ? <Alert isOnline={isOnline} /> : null}
        </Layout>
      </ThemeProvider>
    );
  }

  // else if (config.aws_org_id === "NSE" && userAuthStateValue) {
  //   return (
  //     <ThemeProvider theme={Theme}>
  //       <Layout>
  //         <AnimatePresence>
  //           <Switch location={location} key={location.pathname}>
  //             <Route exact path="/" component={SignInAndSignUp}>
  //               {userAuthStateValue ? (
  //                 <Redirect to="/home" />
  //               ) : (
  //                 <SignInAndSignUp />
  //               )}
  //             </Route>
  //             <Route exact path="/home" component={Home} />
  //             <Route exact path="/course" component={Course} />
  //             <Route path="*" component={Home} />
  //           </Switch>
  //         </AnimatePresence>
  //         {isOnline === false ? <Alert isOnline={isOnline} /> : null}
  //       </Layout>
  //     </ThemeProvider>
  //   );
  // }
  else if (location.pathname === "/sharingobject") {
    return (
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route path="/sharingobject" component={SharedObject} />
          <Route exact path="/" component={SignInAndSignUp} />
          <Route path="*" component={SignInAndSignUp} />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  } else {
    return (
      <AnimatePresence>
        <Switch>
          <Route exact path="/" component={SignInAndSignUp} />
          <Route path="*" component={SignInAndSignUp} />
        </Switch>
        {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      </AnimatePresence>
    );
  }
}

export default App;

function Home() {
  const userDetails = useSelector(authData);
  const userId = userDetails?.uData?.id;
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, error, isLoading } = useQuery(["courses", userId], async () => {
    try {
      const res = await API.get(
        config.aws_cloud_logic_custom_name,
        `/getcourses/${userId}?type=login`
      );
      return res;
    } catch (error) {}
  });

  return (
    <div>
      {!isLoading &&
        data?.data?.map((item, idx) => (
          <CardLarge
            title={item?.tn}
            tid={item.tid}
            count={item.noofnuggets}
            // handleBookmark={addAndRemoveBookmark}
            star={item.ravg}
            comp_date={item.comp_date}
            sub_date={item.sub_date}
            bookmark={item.bk}
            // heading={heading}
            handleLocation={() => {
              let breadcrumb = { ...userDetails };

              breadcrumb.location = {
                from: "Home",
                courseName: item?.tn,
              };
              dispatch(awsSignIn(breadcrumb));
              history.push(`course?id=${item?.tid}`);
            }}
            index={idx}
            elseWhere={true}
          />
        ))}
    </div>
  );
}
