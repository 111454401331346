import React, { useState, useEffect, useCallback } from "react";
import "./CardSmall.scss";
// Swiper
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { breakpoints2 } from "../../Carousel/utils/Breakpoints";
import "swiper/components/navigation/navigation.scss";
// import Swiper core and required modules

import { useHistory, Link } from "react-router-dom";
import { API } from "aws-amplify";
import config from "../../../aws-exports";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

import { setCategoryData } from "../../../redux/CategoryData/CategoryData";

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import { setViewAllArrData } from "../../../redux/HomeData/HomeDataSlice";
import { useQuery } from "react-query";
import Loading from "../../Loading/Loading";

const CardSmall = ({ heading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const { isLoading, error, data } = useQuery(
    ["getCategories", config.aws_org_id],
    async () => {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getCategories",
          bodyParam
        );

        const { topics } = response;
        dispatch(setCategoryData(topics));
        return topics;
      } catch (error) {
        console.error(error);
        return error.message;
      }
    }
  );

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleLocation = (name, id) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "Home",
      topicName: name,
      topicId: id,
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`explore/${name.replace(/\s/g, "-")}/${id}`);
  };

  function exploreAllTopics() {
    dispatch(setViewAllArrData(data));
  }

  console.log(data);
  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <div className="carousel-container">
        <div>
          <h2 className="carousel-heading">{heading}</h2>
          {/* {data?.length >= 15 ? (
            <Link
              to="viewallcontent"
              style={{
                marginRight: "2rem",
                fontSize: "1.7rem",
                marginTop: "3.5rem",
                fontWeight: "600",
              }}
              className="viewAllActive"
              onClick={exploreAllTopics}
            >
              View all
            </Link>
          ) : (
            ""
          )} */}
        </div>
        <div className="swiper_wrapper">
          {!isLoading && data.length > 0 && (
            <Swiper
              ref={sliderRef}
              breakpoints={breakpoints2}
              spaceBetween={178}
              slidesPerGroup={4}
              slidesPerView={4}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: false }}
              className="slide-container"
            >
              {data?.map(({ name, id, ct_img }, idx) => (
                <SwiperSlide
                  key={idx}
                  className="slider-container"
                  onClick={() => handleLocation(name, id)}
                >
                  <div className="card-sm">
                    <div
                      style={{
                        backgroundImage: `linear-gradient(to right bottom, rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${
                          config.aws_cloudfront_url
                        }${config.aws_org_id.toLowerCase()}-resources/images/category-images/${ct_img})`,
                      }}
                      className="back-img"
                    >
                      <div className="card-img-overlay text-white d-flex justify-content-center align-items-end">
                        <h3>{name}</h3>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <span class="arrow arrow-right" onClick={handleNext}></span>
          <span class="arrow arrow-left" onClick={handlePrev}></span>

          <div className="flex-loading">
            {isLoading && (
              <React.Fragment>
                {[...Array(4).fill(0)].map((_, index) => (
                  <Loading />
                ))}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(CardSmall);
