import React from "react";
import { StyledCard } from "./ContentCard.styles";
import HelpOutlineSharpIcon from "@material-ui/icons/HelpOutlineSharp";
// import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import HeadsetIcon from "@material-ui/icons/Headset";
// import DescriptionIcon from "@material-ui/icons/Description";
// import HtmlIcon from "../../svg/HtmlIcon";

import { ReactComponent as DocumentIcon } from "../../../assets/svg/doc.svg";
import HtmlIcon from "@material-ui/icons/Code";
import Subject from "@material-ui/icons/Subject";
// import Interactivity from "../../svg/Interactivity";

// import { ReactComponent as Audio } from "../../../assets/svg/Audio.svg";
import {
  Bookmark,
  BookmarkBorderOutlined,
  QueryBuilderOutlined,
  StarRounded,
} from "@material-ui/icons";
import { CardContent } from "@material-ui/core";
const renderIcon = (param) => {
  if (param === "video" || param === "youtube" || param === "vimeo") {
    return (
      <PlayCircleOutlineIcon style={{ fill: "black", fontSize: "3rem" }} />
    );
  } else if (param === "pdf") {
    return <DocumentIcon style={{ fill: "black", height: "3rem" }} />;
  } else if (param === "Interactivity") {
    return <TouchAppIcon style={{ fill: "black", fontSize: "3rem" }} />;
  } else if (param === "audio") {
    return <HeadsetIcon style={{ fill: "black", fontSize: "3rem" }} />;
  } else if (param === "quiz") {
    return <HelpOutlineSharpIcon style={{ fill: "black", fontSize: "3rem" }} />;
  } else if (param === "scorm") {
    return (
      <PlayCircleOutlineIcon style={{ fill: "black", fontSize: "3rem" }} />
    );
  } else if (param === "html") {
    return <HtmlIcon style={{ fill: "black", fontSize: "3rem" }} />;
  }
};
const renderBgColor = (param) => {
  if (param === "video" || param === "youtube" || param === "vimeo") {
    return "#E2D2FE";
  } else if (param === "pdf" || param === "html") {
    return "#FDE1AB";
  } else if (param?.toLowerCase() === "interactivity") {
    return " #CCF0BF";
  } else if (param === "quiz") {
    return "#FFD27E";
  } else if (param === "scorm") {
    return "#BAE5F4";
  } else if (param === "audio") {
    return "#EECFCF";
  }
};
export default function ContentCard({ otype, title, dur, tid, ...rest }) {
  return (
    <StyledCard color={renderBgColor(otype)}>
      <CardContent className="card-content" onClick={rest.handleLocation}>
        <div className="card-content--header">
          {renderIcon(otype)}
          <div className="card-content--header-star-container">
            <StarRounded className="star" />
            <span className="rating" style={{margin:"5px"}}>
              {rest?.star ? rest.star.toFixed(1)  : "0.0"}
            </span>
          </div>
        </div>
        <div className="para-container">
          <p>{title}</p>
        </div>
      </CardContent>
      <div className="card-actions">
        <div className="card-actions--footer">
          {!rest.bookmarkPage && (
            <div className="card-actions--footer-time">
              <QueryBuilderOutlined className="clock-icon" />
              <DurationMinAndSec duration={dur} />
            </div>
          )}
          {rest?.bookmarkPage && (
            <div className="bookmark_date-container">
              <p className="bookmark_date-text">Bookmark Date</p>
              <span>:</span>
              <p className="bookmark_date-date">{rest.date}</p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <Bookmark className="bookmark-icon fill" onClick={rest.onClick} />
          )}
          {rest?.bookmark && !rest?.bookmarkPage && (
            <Bookmark
              className="bookmark-icon fill"
              onClick={() => rest.handleBookmark(1, tid, rest.index)}
            />
          )}
          {!rest?.bookmark && !rest?.bookmarkPage && (
            <BookmarkBorderOutlined
              className="bookmark-icon"
              onClick={() => rest.handleBookmark(0, tid, rest.index)}
            />
          )}
        </div>
      </div>
    </StyledCard>
  );
}

const DurationMinAndSec = ({ duration }) => {
  let min = Math.floor(duration / 60)
    .toString()
    .padStart(2, "0");
  let sec = Math.floor(duration % 60)
    .toString()
    .padStart(2, "0");

  if (isNaN(min) && isNaN(sec)) {
    min = "00";
    sec = "00";
  }

  return (
    <span>
      {min}m {sec}s
    </span>
  );
};
