import React from "react";
import Header from "../../../components/Header/Header";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import CardLarge from "../../../components/Cards/CardLarge/CardLarge";
import { useEffect } from "react";
import config from "../../../aws-exports";

export default function ContinueLearning() {
  const { viewAllArr } = useSelector((state) => state.home);
  console.log(JSON.stringify(viewAllArr, null, 2));
  let userDetails = useSelector(authData);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLocation = (tid, ttitle) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "Home",
      courseName: ttitle,
      viewAll: true,
      viewAllRoute: "/viewAllContinueLearning",
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`course?id=${tid}`);
  };
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="large" style={{ fill: "#1974b5" }} />
        }
        aria-label="breadcrumb"
        style={{ marginTop: "2rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
        >
          Home
        </Link>
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          View all
        </Link>
      </Breadcrumbs>
      <div className="flex-between">
        <div className="flex-container"></div>
      </div>
      <div className="featureList">
        {viewAllArr.map(
          ({ ttitle, pid, tid, noofnuggets, pcount, starAvg, cobj, tobj }) => (
            <div
              style={{ marginBottom: "2rem" }}
              onClick={() => handleLocation(tid, ttitle)}
              key={pid + "abd"}
            >
              <CardLarge
                ttitle={ttitle}
                tid={tid}
                course={noofnuggets}
                progress={pcount}
                star={starAvg}
                cobj={cobj ? cobj : 0}
                tobj={tobj ? tobj : 0}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}
