import { Box } from "@mui/system";
import styled from "styled-components";
export const Status = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: ${({ type, theme }) =>
    type === 1 ? theme.color.primary : theme.color.secondary};
  font-size: 12px;
  padding: 1px 1.5px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 3px;
  text-transform: uppercase;
`;
