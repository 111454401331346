import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import config from "../../aws-exports";

import { useParams, useLocation } from "react-router-dom";
import API from "@aws-amplify/api";

import SharedHeader from "../../components/Header/SharedHeader/SharedHeader";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function SharedObject() {
  const params = useParams();
  let query = useQuery();
  const [sharedObject, setSharedObject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let val2 = query.get("val2");
  let val1 = query.get("val1");
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSharedObject();
    setIsLoading(true);
  }, [val2, val1]);

  const getSharedObject = async () => {
    // alert(JSON.stringify(params, null, 2));
    const bodyParam = {
      body: {
        val2,
        val1,
        val3: config.aws_org_id,
        linkview: 1,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getSharedObject",
        bodyParam
      );
      console.log(response);
      setSharedObject(response);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      setIsLoading(false);
      updateSharedObject();
    } catch (err) {
      console.error(err);
    }
  };
  const updateSharedObject = async () => {
    // alert(JSON.stringify(params, null, 2));
    const bodyParam = {
      body: {
        tenant: val2,
        id: val1,
        oid: config.aws_org_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateSharedAnalytics",
        bodyParam
      );
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <SharedHeader />
          {sharedObject.ourl ? (
            <div style={{ width: "100vw", height: "92.5vh" }}>
              <iframe
                title="This is a unique title"
                src={sharedObject.ourl}
                allowFullScreen
                height="100%"
                width="100%"
                frameBorder="no"
              />
            </div>
          ) : (
            <div
              style={{
                height: "90vh",
                width: "100vw",
                display: "grid",
                placeItems: "center",
              }}
            >
              <div
                style={{
                  width: "400px",
                  height: "200px",
                  background: "#1974b5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: ".8rem",
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontSize: "1.75rem",
                    fontWeight: "600",
                  }}
                >
                  There is nothing here to display
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SharedObject;
