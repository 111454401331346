import { createSlice } from '@reduxjs/toolkit';

export const courseSlice = createSlice({
  name: 'Course',
  initialState: {
    value: {},
  },
  reducers: {
    rcoursedetails: (state, action) => {
      state.value = {...action.payload};
    },
  },
});

// Action creators are generated for each case reducer function
export const { rcoursedetails } = courseSlice.actions;

export default courseSlice.reducer;
