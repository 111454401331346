import { Box } from "@material-ui/core";
import React, { useContext, useState } from "react";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import {
  Alert,
  CircularProgress,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
  SignInAndSignUpValues,
} from "./SignInAndSignUp";

import "./phoneInput.styles.css";
import CustomInput from "../CustomInput/CustomInput";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useForm } from "../../Hooks/useForm";
import { Theme } from "../../aws-exports";
import Auth from "@aws-amplify/auth";
import validateSignUp from "./SignInSignUpValidationRules/SignUpValidationRules";
import { HeadingPrimary } from "../Styles/Heading";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import config from "../../aws-exports";
const SignUp = () => {
  const { setSignUp, setSignIn } = useContext(SignInAndSignUpValues);

  const [errors, setErrors] = useState({});

  const [signUpBtnLoader, setSignUpBtnLoader] = useState(false);
  const {
    values,
    handleChange,
    setValues,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(validateSignUp(values)).length !== 0) {
      setErrors(validateSignUp(values));
      return;
    } else {
      const { name, signUpPassword, email } = values;
      setSignUpBtnLoader(true);
      try {
        const response = await Auth.signUp({
          username: email,
          password: signUpPassword,
          attributes: {
            email, // optional
            phone_number: values.phone_number, // optional - E.164 number convention
            // other custom attributes
            name: name,
            locale: "EXCELTEAMONE",
          },
        });
        console.log("successfully signed up!");
        console.log(response);
        let body = {
          tenant: "EXCELTEAMONE",
          type: "signup",
          eid: response.userSub,
          emailid: email,
          gen: 0,
          first_name: name,
          last_name: null,
          dev: 2,
          st_id: 1,
          cno: values.phone_number,
          schema: config.schema,
        };

        Swal.fire({
          title: "Successfully signed Up!",
          text: "You will be able to sign in now!",
          icon: "success",
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            addUser(body);
            setSignUp(false);
            setSignIn(true);
          }
        });

        setSignUpBtnLoader(false);
      } catch (error) {
        console.log("error signing up:", error);
        setErrors(cognitoValidation(error));
        setSignUpBtnLoader(false);
      }
    }
  };

  const addUser = async (user) => {
    const bodyParam = {
      body: user,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/addUser",
        bodyParam
      );
    } catch (err) {
      console.log("error adding user:", err);
    }
  };
  return (
    <React.Fragment>
      <Box style={{ textAlign: "center" }}>
        <HeadingPrimary>Create an account</HeadingPrimary>
      </Box>

      <form
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        style={displayCenter}
      >
        <React.Fragment>
          <Box component="div" style={inputBoxStyle}>
            <CustomInput
              id="input-Uname"
              placeholder="Username"
              name="name"
              value={values.name || ""}
              error={errors.name}
              type="text"
              onChange={handleChange("name")}
            >
              <PersonOutlineOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box component="div" style={inputBoxStyle}>
            <PhoneInput
              defaultCountry="IN"
              className="PhoneInput"
              name="phone"
              style={errors.phoneNumber && { borderBottomColor: "#D32f2f" }}
              placeholder="Enter phone number"
              value={values.phone_number || ""}
              onChange={(value) =>
                setValues((values) => ({ ...values, phone_number: value }))
              }
            />
            {
              <p
                style={{
                  color: "#D32f2f",
                  fontSize: "14px",
                  fontFamily: "Roboto ",
                  marginTop: ".5rem",
                }}
              >
                {errors.phoneNumber}
              </p>
            }
          </Box>
          <Box component="div" style={inputBoxStyle}>
            <CustomInput
              id="input-name-1"
              placeholder="Email"
              name="email"
              type="email"
              value={values.email || ""}
              error={errors.email}
              onChange={handleChange("email")}
            >
              <EmailOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box component="div" style={inputBoxStyle}>
            <CustomInput
              id="input-signUpPassword"
              placeholder="Password"
              type="password"
              name="signUpPassword"
              inputValues={values}
              value={values.signUpPassword || ""}
              error={errors.password}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              onChange={handleChange("signUpPassword")}
            >
              <LockOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>

          <Box
            component="div"
            style={{ textAlign: "center", marginTop: "2rem" }}
          >
            {signUpBtnLoader ? (
              <CustomButton disabled loading="true">
                <CircularProgress color="inherit" size={20} />
              </CustomButton>
            ) : (
              <CustomButton primary type="submit">
                SignUp
              </CustomButton>
            )}
          </Box>
          <Typography
            onClick={() => {
              setSignIn(true);
              setSignUp(false);
            }}
            variant="caption"
            display="block"
            gutterBottom
            style={{
              fontSize: "1.4rem",
              textAlign: "center",
              cursor: "pointer",
              marginTop: "2rem",
            }}
          >
            Already have an account?
            <span
              style={{
                textDecoration: "underline",
                color: Theme.color.secondary,
                fontWeight: "bold",
              }}
            >
              Login here
            </span>
          </Typography>
        </React.Fragment>
      </form>
    </React.Fragment>
  );
};

export default SignUp;
