import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActions, IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Box } from "@mui/system";
import { HeadingSecondary } from "../Styles/Heading";
import { Theme } from "../../aws-exports";
import config from "../../aws-exports";

import { Favorite } from "@mui/icons-material";
import { Status } from "./Status";
import { styled } from "@mui/material/styles";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { StyledCard } from "./ContentCard/ContentCard.styles";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
export const CardPrimary = ({
  auth,
  title,
  addToFavorite,
  addToBookMarkList,
  imageId,
  tenant,
  img,
  handleLocation,
  sessionType,
  sessionDate,
}) => (
  <StyledCard onClick={handleLocation}>
    <Status component="span" type={sessionType && sessionType}>
      {sessionType === 1 && "Live web"}
      {sessionType === 2 && "Classroom"}
      {sessionType === 3 && "hybrid"}
    </Status>
    <div className="img-container" onClick={handleLocation}>
      <img
        src={`${
          imageId && tenant
            ? `https://${config.aws_cloudfront_url}/usp-resources/images/session-images/${imageId}.png`
            : img
        }`}
        alt={title}
      />

      <Status component="span" type={sessionType && sessionType}>
        {sessionType === 1 && "Live web"}
        {sessionType === 2 && "Classroom"}
        {sessionType === 3 && "hybrid"}
      </Status>
    </div>

    <CardContent className="card-content">
      <LightTooltip title={title} placement="top-start">
        <div className="para-container">
          <p>{title}</p>
        </div>
      </LightTooltip>
    </CardContent>
    <CardActions className="card-actions">
      <div className="card-actions--footer">
        <div className="para-container">
          <p>{sessionDate && sessionDate}</p>
        </div>
        {auth && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!addToFavorite ? (
              <FavoriteBorderIcon className="icon" />
            ) : (
              <Favorite className="icon" />
            )}
            {/* <IconButton onClick={addToBookMarkList}>
              <BookmarkBorderOutlinedIcon />
            </IconButton> */}
          </Box>
        )}
      </div>
    </CardActions>
  </StyledCard>
);

export default CardPrimary;
