import React, { useEffect, useRef, useState } from "react";
import "./RadioObjects.scss";
import Header from "../../components/Header/Header";
import StarIcon from "@material-ui/icons/Star";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/core";
// import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import Button from "@material-ui/core/Button";
import Tippy from "@tippyjs/react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// import 'tippy.js/dist/tippy.css';

// import BookmarkIcon from '@material-ui/icons/Bookmark';

import {
  CardMedia,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
// import { RatingPopUp } from '../../components/ModalPopUp/ModalPopUp';
// react- redux
import { useSelector, useDispatch } from "react-redux";

import config from "../../aws-exports";

//React router
import { useParams } from "react-router-dom";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import API from "@aws-amplify/api";
// import ReactPlayer from 'react-player';
// import { Document, Page, pdfjs } from 'react-pdf';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from "@material-ui/core/Snackbar";
// import { Alert } from '@material-ui/lab';
import MuiAlert from "@material-ui/lab/Alert";
// import Quiz from '../../components/Quiz/Quiz';
import ReactPlayer from "react-player";
import { saveAs } from "file-saver";
import axios from "axios";
import CustomizedDialogs from "../../components/Dialogs/Dialogs";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";

// import { setIsRated } from '../../redux/RateContent/RateContent';
import { Skeleton } from "@material-ui/lab";
import PlayerMain from "../../components/ReactPlayerControls/PlayerMain";
import { withStyles } from "@material-ui/styles";
import screenfull from "screenfull";
import { Fullscreen, HelpOutline, Timer } from "@material-ui/icons";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Cookies from "js-cookie";
import { func } from "prop-types";
import { hideAll } from "tippy.js";
import SummativeQuiz from "../../components/Quiz/SummativeQuiz";
import FormativeQuiz from "../../components/Quiz/FormativeQuiz";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    height: "610px",
    width: "1100px",
    backgroundColor: "white",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0rem",
    position: "relative",
    textAlign: "center",
    '@media (max-width: 550px)': {
      height: "410px",
    },
  },
  paperVideo: {
    height: "610px",
    width: "1100px",
    backgroundColor: "white",
    overflowY: "hidden",
    overflowX: "hidden",
    position: "relative",
  },
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: '#fff',
  // },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  quizroot: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "white",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "white",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  responsive: {
    [theme.breakpoints.up(1300)]: {
      fontSize: "1rem",
    },
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    '@media (max-width: 550px)': {
      marginBottom: "1rem",
    },
  },
  ratingButton: {
    fontSize: "1.4rem",
    width: "150px",
    color: "#01498e",
    borderColor: "#01498e",
    textTransform: "none",
    '@media (max-width: 550px)': {
      width: "auto",
    },
  },
  downloadButton: {
    background: "transparent",
    border: "none",
    height: "3rem",
    width: "3rem",
    marginRight: "2rem",
    cursor: "pointer",
    '@media (max-width: 550px)': {
      marginRight: "1rem",
    },
  },
  bookmarkIcon: {
    cursor: "pointer",
    marginLeft: "1.5rem",
    '@media (max-width: 550px)': {
      marginLeft: "0.5rem",
    },
  },
  WebViewScroll: {
    overflowX: "hidden",
  },
  loaderSkeleton: {
    width: "500px", 
    height: "5rem",
    '@media (max-width: 750px)': {
      width: "200px",
    },
  },
}));
const useBookMarkStyles = makeStyles({
  outline: {
    fontSize: "3rem",
    fill: "none",
    stroke: "#646464",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  filled: {
    fontSize: "3rem",
    fill: "#FFDA46",
    stroke: "#FFDA46",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
});

const StyledButtonQuiz = withStyles({
  root: {
    background: "linear-gradient(45deg, #01498e 90%, #fff 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 50px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    marginTop: "3rem",
    fontSize: "16px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
const defaultTippyProps = {
  // animation: "shift-away-subtle",
  arrow: true,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom",
  // delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,
  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function RadioObject(props) {
  const classes = useStyles();
  const classBookmark = useBookMarkStyles();
  // const [open, setOpen] = React.useState(false);
  const retake = useRef(false);
  const [tryBtnShow, setTryBtnShow] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [rateValue, setRateValue] = React.useState(1);
  const [btnLoad, setBtnLoad] = React.useState(false);
  const [backDrop, setBackDrop] = React.useState(true);
  const [copied, setCopied] = React.useState(false);
  const [copiedValue, setCopiedValue] = React.useState("");
  const [sharedObject, setSharedObject] = React.useState({});
  const [sTime, setSTime] = React.useState(new Date().getTime());
  const [error, setError] = useState("");

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const [isLoadingRate, setIsLoadingRate] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [isQuizLoading, setIsQuizLoading] = useState(false);
  const [btnClick, setBtnClick] = useState(false);
  const sharedReport = useRef({});

  let { objId } = useParams();
  const copyToClipBoard = useRef();
  const btnCopyClick = useRef();
  const full = useRef(null);
  const [bmId, setBmId] = useState();
  const [bDate, setBDate] = useState();
  // const { featuredArr } = useSelector(state => state.home);
  // const { isRated } = useSelector(state => state.rate);
  const [objLoad, setObjLoad] = React.useState(true);
  const vid = useRef();
  const scrollTop = useRef();

  const [avgStar, setAvgStar] = React.useState(0);
  //const [isRateds, setIsRated] = React.useState(true);
  const [isRated, setIsRated] = useState(true);
  const [isBookmarked, setIsBookmarked] = React.useState(false);
  const [isBookmarkedLoaded, setIsBookmarkedLoaded] = React.useState(false);
  const [cpLinkButton, setCpLinkButton] = React.useState(false);

  const [isSubmited, setIsSubmited] = useState(false);

  const userDetails = useSelector(authData);

  // const filterObject = featuredArr.filter(arr => arr.tid === objId);
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [fullScreen, setFullScreen] = useState(true);

  const [more, setMore] = React.useState("");

  const [quizQuestion, setQuizQuestion] = useState([]);
  const [question, setQuestion] = useState([]);
  const [perScore, setPerScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [qtype, setQtype] = useState();
  const [errvalidatte, setErrvalidatte] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [report, setReport] = useState({});

  const [ansShow, setAnsShow] = useState(false);
  const [value, setValue] = React.useState("female");
  const [userData, setUserData] = useState({});
  const [startQuiz, setStartQuiz] = useState(false);
  const count = useRef(0);
  const refobj = useRef();
  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      tick();
    }, 1000);
    if (userDetails === 0) {
      var obj = {};
      obj.tid = query.get("id");
      obj.type = "content";
      localStorage.setItem("deeplink", JSON.stringify(obj));
      //history.push(`/`);
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      window.scrollTo(0, 0);

      getSharedObject();
      // getBookmarkStatus();

      // getSharableLink();
      // getObjectRating();
      return () => {
        if (query.get("id") !== undefined) {
          updateObjectAnalytics(
            query.get("id"),
            sharedReport.current,
            count.current
          );
        }
        console.log(count.current);
        clearInterval(interval);
      };
    }
  }, [isRated]);
  const tick = () => {
    count.current += 1;
  };

  const getSharableLink = async () => {
    const bodyParam = {
      body: {
        tenant: userDetails.locale,
        objid: query.get("id"),
        oid: config.aws_org_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getSharablelink",
        bodyParam
      );

      if (response === "true" || response === true) {
        setCpLinkButton(true);
      } else {
        setCpLinkButton(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateObjectAnalytics = async (objectId, sharedObject, count) => {
    const bodyParam = {
      body: {
        objid: objectId,
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
        tspent: count,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if ((new Date().getTime() - sTime) / 1000 <= 30) {
      bodyParam.body.bounce_cnt = 1;
    } else {
      if (
        sharedObject?.unique_val == null ||
        sharedObject?.unique_val === undefined
      ) {
        bodyParam.body.unique_date = 1;
        bodyParam.body.view_cnt = 1;
      } else {
        bodyParam.body.view_cnt = 1;
      }
    }
    console.log("updateObjectAnalytics==" + JSON.stringify(bodyParam.body));

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  };

  async function updateObjectView(res) {
    const bodyParam = {
      body: {
        tenant: userDetails.locale,
        oid: config.aws_org_id,
        tid: query.get("id"),
        title: res?.rows[0].title,
        eid: userDetails.username,
        otype: res?.rows[0]?.otype,
        type: "object",
        pdate: new Date().getTime(),
        ourl: res.rows[0]?.url,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("recenet===", JSON.stringify(bodyParam, null, 2));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateRecentViewed",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  }

  function downloadPdf() {
    let filename, type, durl;
    if (
      sharedObject.otype !== undefined &&
      sharedObject.otype.includes("pdf")
    ) {
      type = "application/pdf";
      filename = sharedObject.oname + ".pdf";
      durl = sharedObject.url;
    }
    if (
      sharedObject.otype !== undefined &&
      sharedObject.otype.includes("html")
    ) {
      type = "text/html";
      filename = sharedObject.oname + ".pdf";
      durl = sharedObject.durl;
    }
    if (
      sharedObject.otype !== undefined &&
      sharedObject.otype.includes("Interactivity")
    ) {
      type = "application/pdf";
      if (
        sharedObject.tid === "OaaqWd4CzWMaL6tU" ||
        sharedObject.tid === "GVKKMdARctFMnWdq" ||
        sharedObject.tid === "KcJZnObtwavbEcfL"
      ) {
        filename = sharedObject.oname + ".pptx";
        durl = sharedObject.durl;
      } else {
        filename = sharedObject.oname + ".pdf";
        durl = sharedObject.durl;
      }
    }
    if (
      sharedObject.dtype !== undefined &&
      sharedObject.dtype.includes("ppt")
    ) {
      filename = sharedObject.oname + ".pptx";
      durl = sharedObject.durl;
    } else if (
      sharedObject.dtype !== undefined &&
      sharedObject.dtype === "pdf"
    ) {
      filename = sharedObject.oname + ".pdf";
      durl = sharedObject.durl;
    } else if (
      sharedObject.dtype !== undefined &&
      sharedObject.dtype.includes("docx")
    ) {
      filename = sharedObject.oname + ".docx";
      durl = sharedObject.durl;
      type =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    console.log("come to", filename, durl);

    axios
      .get(durl, {
        responseType: "blob",
        method: "GET",
        headers: {
          "Content-Type": type,
        },
      })
      .then((res) => {
        let file = new Blob([res.data], { type: type });
        saveAs(file, filename);
      });
  }

  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ fontSize: "1.6rem" }}
      />
    );
  }
  const handleClick = () => {
    copyToClipBoard.current.select();

    document.execCommand("copy");
    setOpenSnackbar(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const getSharedObject = async () => {
    setIsBookmarkedLoaded(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        objid: query.get("id"),
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
        // val2: userDetails.locale,
        // val1: query.get("id"),
        // val3: config.aws_org_id,
        // linkview: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getSharedObject",
        bodyParam
      );
      if (parseInt(response.id) === 0) {
        updateObjectView(response);
      }
      // console.log(JSON.stringify(response, null, 2));
      setSharedObject(response.rows[0]);
      sharedReport.current = response.report[0];
      if (response.report.length > 0) {
        setReport(response?.report[0]);
      }
      setQtype(response.rows !== undefined && response.rows[0] !== undefined && response.rows[0].qtype !== undefined
        && response.rows[0].qtype !== null ? Number(response.rows[0].qtype) : 2);

      refobj.current = response.otype;
      setIsLoading(false);
      setBackDrop(false);
      console.log(JSON.stringify(response));
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      setObjLoad(false);
      setIsBookmarkedLoaded(false);
    } catch (err) {
      console.error(err);
    }
  };

  const addAndRemoveBookmark = async (val) => {
    setIsBookmarkedLoaded(true);
    const bodyParam = {
      body: {
        objid: query.get("id"),
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (val === 0) {
      bodyParam.body.bookmark = true;
      bodyParam.body.bookmark_date = 1;
    } else {
      bodyParam.body.bookmark = false;
    }

    try {
      console.log(JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      setIsBookmarkedLoaded(false);
      getSharedObject();
    } catch (err) {
      console.error(err);
    }
  };

  const syncObjectsData = async () => {
    setBtnLoad(true);
    const bodyParam = {
      body: {
        objid: query.get("id"),
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    bodyParam.body.rating = rateValue;
    bodyParam.body.rating_date = 1;
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      if (response) {
        setBtnLoad(false);
        setOpen(false);
        getSharedObject();
      }
    } catch (err) {
      setBtnLoad(false);
      console.error(err);
    }
  };

  async function getQuiz(obj) {
    setIsQuizLoading(true);
    const bodyParam = {
      body: {
        quizid: obj,
        oid: config.aws_org_id,
        schema: config.schema,
        action: "get",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuiz",
        bodyParam
      );
      console.log(response);
      const { qitems } = response;

      setQuestion(qitems);
      setIsQuizLoading(false);
      setStartQuiz(true);
    } catch (error) {
      console.error(error);
      setIsQuizLoading(false);
    }
  }

  function renderObjects() {
    let objType = sharedObject.otype;
    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "Interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "scorm":
        return WebView(); // loadScormView(curObject);
      case "quiz":
        return tempView();
      case "vimeo":
        return youtubeView(); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }

  function VideoView() {
    // return sharedObject.ourl;
    return <PlayerMain url={sharedObject.url} objName={sharedObject.title} />;
    // return <PlayerMain url={sharedObject.ourl} objName={sharedObject.title} />;
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CardMedia
            image={sharedObject.url}
            height="100%"
            component="audio"
            frameBorder={0}
            controls
          />
          {/* <ReactPlayer url={sharedObject.ourl} controls={true} /> */}
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer url={sharedObject.url} controls={true} />
        </div>
      </div>
    );
  }

  function youtubeView() {
    // alert(sharedObject.otype)
    return (
      <CardMedia
        image={
          sharedObject.otype !== "vimeo"
            ? `https://www.youtube.com/embed/${sharedObject.ourl}?rel=0&showinfo=0`
            : `https://player.vimeo.com/video/${sharedObject.ourl}?title=0&loop=0`
        }
        height="100%"
        component="iframe"
        frameBorder={0}
        controls
      />
    );
  }
  function PdfView() {
    return (
      <CardMedia
        image={`${sharedObject.url}#toolbar=0`}
        component="iframe"
        height="100%"
        frameBorder={0}
      />
    );
  }

  function WebView() {
    return (
      <CardMedia
        image={sharedObject.url}
        height="100%"
        component="iframe"
        width="100%"
        className={classes.WebViewScroll}
        frameBorder={0}
      />
    );
  }

  function validate() {
    console.log(JSON.stringify(quizQuestion));
    setErrvalidatte("");
    for (let i = 0; i < quizQuestion.length; i++) {
      if (quizQuestion[i].value == undefined) {
        setErrvalidatte("Please answer all the below questions");
        return;
      }
      if (i == quizQuestion.length - 1) {
        setAnsShow(true);
      }
    }
  }

  function tempView() {
    return (
      <React.Fragment>
        {qtype === 2 && (
          <React.Fragment>
            <FormativeQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              qpage={"object"}
            />
            {!startQuiz && (
              <div className="formative_start_button_container">
                <button
                  className="button-3"
                  onClick={() => {
                    getQuiz(query.get("id"));
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        fontSize: "2.5rem",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    "Start Quiz"
                  )}
                </button>
              </div>
            )}
          </React.Fragment>
        )}
        {qtype === 1 && (
          <React.Fragment>
            <SummativeQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              getQuiz={getQuiz}
              score={perScore}
              setPerScore={setPerScore}
              setShowScore={setShowScore}
              showScore={showScore}
              totalScore={totalScore}
              setTotalScore={setTotalScore}
              qpage={"object"}
            />
            {!startQuiz && !showScore && (
              <div className="formative_start_button_container-2">
                <div style={{ alignSelf: "center" }}>
                  <button
                    className="button-3"
                    onClick={() => {
                      getQuiz(query.get("id"));
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{
                          fontSize: "2.5rem",
                          height: "2.5rem",
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      "Start Quiz"
                    )}
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  const handleRateClick = () => {
    setOpen(true);
  };

  function handlePause() {
    vid.current.pause();
  }
  function handlePlay() {
    vid.current.play();
  }
  const toggleFullScreen = () => {
    screenfull.toggle(full.current);
  };
  const onFullScreen = () => {
    setFullScreen(!fullScreen);
  };
  const handleTLocation = (loc) => {
    let breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: loc.from === "Home" ? "Home" : "Explore",
      text: loc.topicName,
    };
    dispatch(awsSignIn(breadcrumb));
  };

  return (
    <div>
      {sharedObject.id !== 1 ? (
        <>
          {userDetails !== 0 &&
            userDetails !== undefined &&
            userDetails.location !== undefined && (
              <>
                {userDetails.location.from !== undefined &&
                (userDetails.location.from === "Home" ||
                  userDetails.location.from === "Bookmarks" ||
                  userDetails.location.from === "Explore" ||
                  userDetails.location.from === "Search") ? (
                  <Breadcrumbs
                    separator={
                      <NavigateNextIcon
                        fontSize="large"
                        style={{ fill: "#1974b5" }}
                      />
                    }
                    aria-label="breadcrumb"
                    style={{ marginBottom: "2rem", cursor: "pointer" }}
                  >
                    {userDetails.location.from == "Home" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Home" ? "/home" : "#"
                        }
                        style={{
                          color: `${
                            userDetails.location.from === "Home"
                              ? "#1974b5"
                              : "#777"
                          }`,
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: `${
                            userDetails.location.from === "Home"
                              ? "pointer"
                              : "context-menu"
                          }`,
                        }}
                      >
                        {userDetails.location.from}
                      </Link>
                    ) : (
                      ""
                    )}

                    {userDetails.location.from == "Search" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Search" ? "/home" : "#"
                        }
                        style={{
                          color: "#777",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "context-menu",
                        }}
                      >
                        {userDetails.location.from}
                      </Link>
                    ) : (
                      ""
                    )}

{userDetails.location.from == "Explore" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Explore" ? "/home" : "#"
                        }
                        style={{
                          color: "#777",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "context-menu",
                        }}
                      >
                        {userDetails.location.from}
                      </Link>
                    ) : (
                      ""
                    )}


                    {userDetails.location.from == "Bookmarks" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Bookmarks"
                            ? "/bookmarks"
                            : "#"
                        }
                        style={{
                          color: `${
                            userDetails.location.from === "Bookmarks"
                              ? "#1974b5"
                              : "#777"
                          }`,
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: `${
                            userDetails.location.from === "Bookmarks"
                              ? "pointer"
                              : "context-menu"
                          }`,
                        }}
                      >
                        {userDetails.location.from}
                      </Link>
                    ) : (
                      ""
                    )}

                    {userDetails.location.topicName && (
                      <Link
                        to={
                          userDetails.location.contentName
                            ? `/explore/${userDetails.location.topicName.replaceAll(
                                /\s/g,
                                "-"
                              )}/${userDetails.location.topicId}`
                            : "#"
                        }
                        style={{
                          color: `${
                            userDetails.location.contentName
                              ? "#1974b5"
                              : "#777"
                          }`,
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: `${
                            userDetails.location.contentName
                              ? "pointer"
                              : "context-menu"
                          }`,
                        }}
                        color="inherit"
                      >
                        {userDetails.location.topicName}
                      </Link>
                    )}

                    {userDetails.location.viewAll && (
                      <Link
                        to={
                          userDetails.location.viewAllRoute
                            ? userDetails.location.viewAllRoute
                            : "/ExploreCourseView"
                        }
                        color="inherit"
                        style={{
                          color: "#1974b5",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        View all
                      </Link>
                    )}

                    {userDetails.location.contentName && (
                      <Link
                        style={{
                          color: "#777",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "context-menu",
                        }}
                        color="inherit"
                      >
                        {userDetails.location.contentName}
                      </Link>
                    )}
                  </Breadcrumbs>
                ) : (
                  ""
                )}
              </>
            )}

          <div className="flex-between">
            <div className={classes.mainContainer}>
              <h3 style={{ fontWeight: "600" }} className="responsive">
                {isLoading ? (
                  <Skeleton className={classes.loaderSkeleton} />
                ) : (
                  <>{sharedObject.oname}</>
                )}
              </h3>

              {isLoading ? (
                  <Skeleton style={{ width: "60px", height: "5rem" ,margin:"10px"}} />
                ) : (
                  <>              <span className="ml vLine"></span>
                  <StarIcon className="icon icon-star" />
                  <span style={{margin:"5px"}}>{sharedObject?.star?.toFixed(1) || "0.0"}</span></>
                )}
              


              {sharedObject.otype === "video" ? (
                <Tippy
                  interactive={true}
                  content={
                    <div
                      style={{
                        background: "transparent",
                        color: "black",
                        padding: "1rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: sharedObject.odesc
                          ? sharedObject.odesc
                          : "No description",
                      }}
                    >
                      {/* {sharedObject.desc ? sharedObject.desc : "No description"} */}
                    </div>
                  }
                  {...defaultTippyProps}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlinedIcon
                      className="ml"
                      color="primary"
                      style={{
                        fill: "#13599a",
                        color: "#13599a",
                        fontSize: "2.5rem",
                      }}
                    />
                  </div>
                </Tippy>
              ) : null}

              <span>{more}</span>
            </div>
            <div className="flex-container">
              {sharedObject.otype === "pdf" ||
              sharedObject.otype === "Interactivity" ? (
                <>
                  {isLoading ? (
                    <Skeleton style={{ width: "50px", height: "5rem" }} />
                  ) : (
                    <button onClick={() => downloadPdf()} className={classes.downloadButton} download>
                      <img src={downloadPdfSvg} alt="download" height="100%" />
                    </button>
                  )}
                </>
              ) : null}
              {/* {isLoadingRate ? (
                <Skeleton style={{ width: "300px", height: "5rem" }} />
              ) : (
                <>{isRated.current ? null : <CustomizedDialogs />}</>
              )} */}

              {!objLoad && Object.keys(report).length === 0 && (
                <Button
                  variant="outlined"
                  className={classes.ratingButton}
                  onClick={() => {
                    handleRateClick();
                    setRateValue(1);
                  }}
                >
                  Rate content
                </Button>
              )}
              {report?.rating === null && (
                <Button
                  variant="outlined"
                  className={classes.ratingButton}
                  onClick={() => {
                    handleRateClick();
                    setRateValue(1);
                  }}
                >
                  Rate content
                </Button>
               )}
              {open && (
                <CustomizedDialogs
                  open={open}
                  setOpen={setOpen}
                  value={rateValue}
                  setValue={setRateValue}
                  syncObjectsData={syncObjectsData}
                  btnLoad={btnLoad}
                  setBtnLoad={setBtnLoad}
                />
              )}
              {sharedObject.shareable && (
                <Button
                  variant="outlined"
                  style={{
                    fontSize: "1.5rem",
                    marginLeft: "2rem",
                    width: "120px",
                    color: "#01498e",
                    borderColor: "#01498e",
                    textTransform: "none",
                  }}
                  className={classes.responsive}
                  onClick={handleClick}
                >
                  Copy link
                </Button>
              )}

              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Copied the link..!"
              >
                <Alert onClose={handleClose} severity="success">
                  Copied the link!
                </Alert>
              </Snackbar>


              {isLoading ? (
                  <Skeleton style={{ width: "80px", height: "5rem" ,margin:"10px"}} />
                ) : (
                  <>        {report?.bookmark ? (
                    <div
                      className={classes.bookmarkIcon}
                      onClick={() => {
                        setIsBookmarkedLoaded(true);
                        addAndRemoveBookmark(1);
                      }}
                    >
                      <BookmarkRoundedIcon className={classBookmark.filled} />
                    </div>
                  ) : (
                    <div
                      style={{ cursor: "pointer", marginLeft: "1.5rem" }}
                      onClick={() => {
                        setIsBookmarkedLoaded(true);
                        addAndRemoveBookmark(0);
                      }}
                    >
                      <BookmarkRoundedIcon className={classBookmark.outline} />
                    </div>
                  )}      </>
                )}


             
              {/* <Backdrop className={classes.backdrop} open={isBookmarkedLoaded}>
                <CircularProgress color="inherit" />
              </Backdrop> */}
            </div>
          </div>

          <div className={classes.root}>
            <Paper
              ref={full}
              elevation={8}
              className={
                sharedObject.otype === "video" ||
                sharedObject.otype === "vimeo" ||
                sharedObject.otype === "youtube"
                  ? classes.paperVideo
                  : classes.paper
              }
            >
              {sharedObject.otype === "video" ||
              sharedObject.otype === "vimeo" ||
              sharedObject.otype === "youtube" ? (
                <div
                  onClick={() => handlePlay()}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    zIndex: "100000000",
                  }}between
                >
                  {isPlaying === true ? (
                    <PlayCircleOutlineRoundedIcon
                      style={{
                        fill: "#fff",
                        fontSize: "10rem",
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </div>
              ) : null}

              {objLoad ? "full loader..." : renderObjects()}
              <input
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: "-1000",
                }}
                type="text"
                id="input"
                ref={copyToClipBoard}
                value={`${config.DOMAIN}#/sharingobject?val1=${query.get(
                  "id"
                )}&val2=${userDetails.locale}`}
              />
              {sharedObject.otype === "pdf" ||
              sharedObject.otype === "Interactivity" ? (
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    right: "50px",
                  }}
                  onClick={() => {
                    toggleFullScreen();
                    onFullScreen();
                  }}
                  className={classes.bottomIcons}
                >
                  {fullScreen ? (
                    <Fullscreen
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  ) : (
                    <FullscreenExitIcon
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  )}
                </IconButton>
              ) : null}
            </Paper>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "90vh",
            width: "100vw",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div
            style={{
              width: "400px",
              height: "200px",
              background: "#1974b5",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: ".8rem",
            }}
          >
            <div style={{ margin: "2rem 0", textAlign: "center" }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                }}
              >
                Content you are looking for could not be found
              </p>
              <button
                style={{
                  border: "none",
                  color: "#1974b5",
                  background: "#fff",
                  padding: ".5rem 2rem",
                  marginTop: "2rem",
                  borderRadius: ".4rem",
                  fontSize: "1.45rem",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/home")}
              >
                Go to dashboard
              </button>
            </div>
            <div
              style={{
                marginBottom: "2rem",
                color: "#fff",
              }}
            >
              <span style={{ marginRight: ".5rem" }}> Please contact</span>
              <a href="https://www.help@marketron.com">help@marketron.com</a>
              <span style={{ marginLeft: ".5rem" }}> for assistance</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RadioObject;
