import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import { API, Auth } from "aws-amplify";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import { Box, Breadcrumbs, Grid } from "@material-ui/core";
import "../../components/Cards/CardLarge/CardLarge.scss";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

// import { render } from '@testing-library/react';
// import StarIcon from '@material-ui/icons/Star';

import { makeStyles } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import "./bookmark.scss";
import ContentCard from "../../components/Cards/ContentCard/ContentCard";
import Carousel from "../../components/Carousel/Carousel";
import BookmarkContentCarousel from "../../components/Carousel/BookmarkContentCarousel/BookmarkContentCarousel";
import { useQuery } from "react-query";

const useStyles = makeStyles({
  outline: {
    fontSize: "3rem",
    fill: "none",
    stroke: "#646464",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  filled: {
    fontSize: "3rem",
    fill: "#FFDA46",
    stroke: "#FFDA46",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  GifDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '& > img': {
      '@media (max-width: 500px)': {
        width: "100%",
      },
    },
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    '@media (max-width: 500px)': {
      display: "block",
    },
  },
});

function BookmarkView() {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { isLoading, error, data } = useQuery("/listBookMark", async () => {
    const bodyParam = {
      body: {
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
        tenant: userDetails?.locale,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(userDetails, "===", bodyParam);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listBookMark",
        bodyParam
      );
      const { items } = response;

      console.log("BOokmark", items);
      console.log("List", list);

      return items;
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    if (!userDetails || userDetails === 0) {
      if(config.aws_org_id == 'AXIATA'){
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    }
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    if (data) {
      setList(data);
    }
  }, [data]);

  const classes = useStyles();
  const history = useHistory();
  function bookMarkFilled() {
    return classes.filled;
  }

  /*  let src1 = `${config.aws_cloudfront_url}marketron-resources/images/program-images/${pid}.png`; */

  async function deleteBookmark(tid, type) {
    const bodyParam = {
      body: {
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (type === 1) {
      bodyParam.body.tid = tid;
    } else {
      bodyParam.body.objid = tid;
    }
    bodyParam.body.bookmark = false;
    let book = list.filter((prev) => prev.tid !== tid);
    setList(book);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        type === 1 ? "/updateTopicReport" : "/updateContentReport",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  }
  let src = "";
  const handleLocation = (data, btype, tid) => {
    let breadcrumb = { ...userDetails };
    let value = data[0];
    if (btype === 1) {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        courseName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`course?id=${tid}`);
    } else {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        contentName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`content?id=${tid}`);
    }
  };

  // btype => 2 => otype
  // btype => 1 => Only Title => bitem_id => IMAGE

  return (
    <motion.div>
      <div>
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="large" style={{ fill: "#1974b5" }} />
          }
          aria-label="breadcrumb"
          style={{ marginBottom: "5rem" }}
        >
          <Link
            style={{
              color: "#777",
              fontSize: "1.5rem",
              textDecoration: "none",
              cursor: "context-menu",
            }}
            color="inherit"
          >
            Bookmarks
          </Link>
        </Breadcrumbs>

        {/* <h2>My Bookmarks</h2> */}
        <div>
          {!isLoading ? (
            <>
              {list?.length === 0 ? (
                <div className={classes.GifDiv}>
                  {" "}
                  <img src={noThingIsHere} alt="noThingIsHere" />
                </div>
              ) : (
                <BookmarkContentCarousel
                  heading="My Bookmarks"
                  cardData={list}
                  setData={setList}
                  loading={isLoading}
                />
              )}
            </>
          ) : (
            <div className={classes.mainDiv}>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
export default BookmarkView;
