import {
  Avatar,
  makeStyles,
  Typography,
  CardMedia,
  Box,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
//import Header from "../../components/Header/Header";
import ProfilePopUp from "../../components/ProfilePopUp/ProfilePopUp";
import config from "../../aws-exports";
import { API, Auth } from "aws-amplify";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import "../../components/ModalPopUp/certificate.scss";
import ENABLED from "../../assets/ENABLED.png";
import EXCELSOFT from "../../assets/EXCELSOFT.png";
import AXIATA from "../../assets/AXIATA.png";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import moment from "moment";
import { Block } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  root121: {
    width: "300px",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    "@media (max-width: 430px)": {
      margin: "auto",
    },
  },
  certificateContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "3rem",
    marginLeft: "3rem",
    cursor: "pointer",
    "@media (max-width: 430px)": {
      marginLeft: 0,
    },
  },
  myProfileSection: {
    padding: "30px",
    margin: "1rem 1rem 3rem 1rem",
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    "@media (max-width: 430px)": {
      padding: "1rem",
    },
  },
  certificateSection: {
    padding: "30px 30px 80px 30px",
    margin: "1rem",
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    "@media (max-width: 430px)": {
      padding: "1rem",
    },
  },
  profileSection: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2rem",
    marginLeft: "3rem",
    "@media (max-width: 430px)": {
      display: "block",
      marginLeft: 0,
    },
  },
  profileName: {
    marginLeft: "3rem",
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    "@media (max-width: 430px)": {
      marginLeft: 0,
      marginTop: "2rem",
      textAlign: "center",
    },
  },
  mainHeader: {
    marginBottom: "2.5rem",
    marginLeft: "3rem",
    fontSize: "2rem",
    fontWeight: "600",
    paddingTop: "7px",
    "@media (max-width: 375px)": {
      marginBottom: "2rem",
      marginLeft: "1rem",
    },
  },
  imageResponsive: {
    "@media (max-width: 605px)": {
      width: "-webkit-fill-available",
    },
  },
}));
const MyProfile = () => {
  const classes = useStyles();
  const userDetails = useSelector(authData);
  const [certificate, setCertificate] = useState(false);
  const [certPopUp, setCertPopUp] = useState(false);
  const [certificateList, setCertificateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [sindex, setSindex] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  let src = `${
    config.aws_cloudfront_url
  }${config.aws_org_id.toLowerCase()}-resources/certificate/`;
  useEffect(() => {
    if (userDetails === 0) {
      //history.push(`/`);
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      let filterstoredata = { ...userDetails };
      delete filterstoredata.filter;
      dispatch(awsSignIn(filterstoredata));
      getUserCertificate();
      console.log("userDetails", userDetails);
    }
  }, []);

  async function getUserCertificate() {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          emailid: userDetails.email,
          ur_id: userDetails.uData.ur_id,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log(
        "prpfile get user cert body response===" +
          JSON.stringify(bodyParam.body)
      );
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listUserCerts",
        bodyParam
      );
      const { certificates } = response;
      if (response.errorType === undefined) {
        setCertificate(true);
        console.log(certificates, "certificates==");
        setCertificateList(certificates);
        const expires = new Date().getTime() + 60 * 60 * 1000;
        console.log(JSON.stringify(response));
        Cookies.set("CloudFront-Expires", expires);
        Cookies.set("CloudFront-Policy", response.Policy);
        Cookies.set("CloudFront-Signature", response.Signature);
        Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <div className={classes.myProfileSection}>
        <h2 variant="h3" className={classes.mainHeader}>
          {" "}
          My Profile{" "}
        </h2>
        <div className={classes.profileSection}>
          <div>
            <Avatar sizes="large" className={classes.large} />
          </div>
          <div className={classes.profileName}>
            <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
              {userDetails.name}
            </h3>
            <h4 style={{ fontWeight: "400" }}>{userDetails.emailid}</h4>
            <h4 style={{ fontWeight: "400" }}>
              {userDetails && userDetails.uData && userDetails.uData.did}
            </h4>
          </div>
        </div>
        {/* <hr style={{ color: "#777", background: "#777" }} />
         */}
      </div>

      {!loading && certificateList.length === 0 ? (
        <div
          style={{
            padding: "30px 30px 80px 30px",
            margin: "1rem",
            background: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={noThingIsHere}
            alt="noThingIsHere"
            className={classes.imageResponsive}
          />
        </div>
      ) : (
        <div className={classes.certificateSection}>
          <h2 variant="h3" className={classes.mainHeader}>
            {" "}
            Certificates{" "}
          </h2>
          <div className={classes.certificateContainer}>
            {loading ? (
              <></>
            ) : (
              <>
                <>
                  {certPopUp ? (
                    <>
                      <ProfilePopUp
                        open={open}
                        setOpen={setOpen}
                        profileDetails={profileDetails}
                        certificateList={certificateList}
                        sindex={sindex}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>

                <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                  {certificateList?.map((item, idx) => (
                    <div
                      className="container-1"
                      key={idx + "certificate"}
                      onClick={() => {
                        setProfileDetails({ ...item, name: userDetails.name });
                        setOpen(true);
                        setCertPopUp(true);
                      }}
                    >
                      <div className="img-c">
                        <img
                          src={
                            config.aws_org_id === "ENABLED"
                              ? ENABLED
                              : config.aws_org_id === "EXCELEARN"
                              ? EXCELSOFT
                              : AXIATA
                          }
                          alt="logo"
                          height="30"
                        />
                      </div>
                      <div className="org-container-1">
                        {/* <div className="img-c">
                  <img src={usp} alt="logo" height="30" />
                </div> */}
                        <p>Certificate awarded to</p>
                      </div>
                      <h1 className="name-1">{userDetails.name}</h1>
                      <p className="complete">for successfully completing</p>
                      <div className="title-container-1">
                        <h2 className="title-1">
                          {item?.ttitle !== undefined
                            ? item?.ttitle
                            : item?.ttitle}
                        </h2>
                      </div>

                      <p className="date-1">
                        Issued on{" "}
                        {item?.compdate !== undefined &&
                          moment(new Date(item?.compdate)).format(
                            "DD MMM YYYY"
                          )}
                      </p>
                      {/* <div className="img-c">
                <img src={signature} alt="signature" height="50" />
              </div> */}
                    </div>
                  ))}
                </Box>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
