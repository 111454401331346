import React, { useState, useEffect } from "react";

// import config from '../../../aws-exports';

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import config from "../../aws-exports";
// import { awsSignIn, authData } from '../../../redux/auth/authSlice';
// import { decrement, increment } from './counterSlice'

// import BookmarkIcon from '@material-ui/icons/Bookmark';

import { Box, makeStyles, Typography, Breadcrumbs } from "@material-ui/core";
// import { RatingPopUp } from '../../components/ModalPopUp/ModalPopUp';

//React router
// import { useParams } from 'react-router-dom';
import { useHistory, useParams, Link, useLocation } from "react-router-dom";

import { Skeleton } from "@material-ui/lab";
import Header from "../../components/Header/Header";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import API from "@aws-amplify/api";
import {
  counterSlice,
  setAllProgram,
} from "../../redux/Myprogram/MyProgramSlice";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function AllProgramView() {
  const { ids } = useSelector((state) => state.counter);
  const { aLoading } = useSelector((state) => state.rate);
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  const [courseList, setCourseList] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const refcourselist = React.useRef();
  const { pid, tid } = useParams();
  const [isload, setIsload] = useState(true);
  const history = useHistory();
  const query = useQuery();
  console.log(
    "userDetails.allCourses==" + JSON.stringify(userDetails.allCourses)
  );
  const handleLocation = (TID, TN) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: breadcrumb.location.from,
      programName: breadcrumb.location.programName,
      courseName: TN,
    };

    if (userDetails.location.viewAll && userDetails.location.viewAllRoute) {
      breadcrumb.location.viewAll = userDetails.location.viewAll;
      breadcrumb.location.viewAllRoute = userDetails.location.viewAllRoute;
    }

    dispatch(awsSignIn(breadcrumb));
    history.push(`/course?id=${TID}`);
  };
  useEffect(() => {
    if (userDetails === 0) {
      let obj = {};
      obj.type = "program";
      obj.pid = query.get("id");
      localStorage.setItem("deeplink", JSON.stringify(obj));
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      listPrograms();
      if (userDetails.allCourses !== undefined) {
        //setCourseList(userDetails.allCourses);

        sortfun(userDetails.allCourses);
      }
    }
  }, []);
  function sortfun(courseList1) {
    courseList1 = courseList1
      .slice()
      .sort((a, b) => b.sqno - a.sqno)
      .reverse();

    setCourseList(courseList1);
  }

  const listPrograms = async () => {
    const bodyParam = {
      body: {
        tenant: userDetails.locale,
        oid: config.aws_org_id,
        pid: query.get("id"),
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // alert(JSON.stringify(bodyParam, null, 2));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listPrograms",
        bodyParam
      );
      console.log(response);
      const { programs } = response;
      let courses = programs[0].courses;
      setCourses(courses);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div>
      {(userDetails.location.from === "Home" ||
        userDetails.location.from === "Search" ||
        userDetails.location.from === "All Program") &&
      userDetails.location.from !== undefined ? (
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="large" style={{ fill: "#1974b5" }} />
          }
          aria-label="breadcrumb"
          style={{ marginBottom: "3rem" }}
        >
          <Link
            color="inherit"
            to="/home"
            style={{
              color: "#1974b5",
              fontSize: "1.5rem",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            Home
          </Link>
          {userDetails.location.viewAll ? (
            <Link
              to="/viewAllPrograms"
              style={{
                color: "#1974b5",
                fontSize: "1.5rem",
                textDecoration: "none",
                cursor: "pointer",
              }}
              color="inherit"
            >
              View all
            </Link>
          ) : null}
          <Link
            style={{
              color: "#777",
              fontSize: "1.5rem",
              textDecoration: "none",
            }}
            color="inherit"
          >
            {userDetails.location.programName}
          </Link>
        </Breadcrumbs>
      ) : (
        ""
      )}
      <div className="flex-between">
        <div className="flex-container">
          <h3 style={{ fontWeight: "600" }}>
            {userDetails.location.programName}
          </h3>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "4rem",
          }}
        >
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            marginTop: "2.5rem",
          }}
        >
          {courses !== undefined ? (
            <>
              {courses.map(({ tid, ttitle, noofnuggets, starAvg }) => (
                <div onClick={() => handleLocation(tid, ttitle)}>
                  <CardLarge
                    tid={tid}
                    ttitle={ttitle}
                    course={noofnuggets}
                    star={starAvg}
                  />
                </div>
              ))}
            </>
          ) : (
            <div>
              <h1>Nothing here yet</h1>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AllProgramView;
