import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import config from "../../../aws-exports";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Rating } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
    paddingBottom: "0",
  },

  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    textAlign: "justify",
    padding: "0!important",
  },
  cover: {
    height: "13rem",
    backgroundSize: "contain",
    marginBottom: "1rem",
  },
  sizeLarge: {
    fontSize: "2.8rem",
  },
}));

export default function CourseCard({ title, tid }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
    <CardMedia
        className={classes.cover}
        image={
          config.aws_cloudfront_url +
          config.aws_org_id.toLowerCase() +
          "-resources/images/topic-images/" +
          tid +
          ".png"
        }
        title="Live from space album cover"
      />
    <Card
      className={classes.root}
      // onClick={() => history.push(`/course?id=${tid}`)}
    >
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography
            component="h5"
            variant="h5"
            style={{ marginBottom: "2rem", cursor: "pointer" }}
          >
            {title}
          </Typography>
          <div style={{ height: "25px" }}></div>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {/* <AccessTimeIcon style={{ fontSize: '21px', marginRight: '8px' }} />
            <span style={{ fontSize: '1.5rem' }}>10h 20m</span> */}
          </Typography>

          {/* <Rating
            name='size-large'
            className={classes.sizeLarge}
            defaultValue={2}
            readOnly
          /> */}
        </CardContent>
      </div>
    </Card>
    </>
  );
}
