import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import Header from "../../components/Header/Header";
import StarIcon from "@material-ui/icons/Star";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Button from "@material-ui/core/Button";
import { API } from "aws-amplify";
import config, { Theme } from "../../aws-exports";

import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { motion, AnimatePresence } from "framer-motion";
import noThingIsHere from "../../assets/Nothing here yet.gif";

import {
  Box,
  Breadcrumbs,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { RatingPopUp } from "../../components/ModalPopUp/ModalPopUp";

//React router
import { useParams } from "react-router-dom";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import { Skeleton } from "@material-ui/lab";
import ExploreSort from "./ExploreSort/ExploreSort";
import ExploreFilter from "./ExploreFilter/ExploreFilter";
import ProgramExploreSort from "./ExploreSort/ProgramExploreSort";
import ContentCard from "../../components/Cards/ContentCard/ContentCard";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: "12px",
  },
  gridContent: {
    // marginTop: "2rem",
    boxShadow: Theme.boxShadow,
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "5px",
    '& > div > div': {
      margin: 0,
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Explore() {
  const userDetails = useSelector(authData);
  const classes = useStyles();
  console.log(userDetails);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [ctype, setCtypeF] = useState("");
  const [adProductF, setAdProductF] = useState("");
  const [mrToolsF, setMrToolsF] = useState("");
  const [durF, setDurF] = useState("");

  const [anchorElS, setAnchorElS] = useState(null);
  const [openS, setOpenS] = useState(false);
  const [stype, setStypeF] = useState("");
  const [stypeV, setStypeFV] = useState("Newest");
  const [pstypeV, setPStypeFV] = useState("Newest");
  const [pstype, setPStypeF] = useState("CON");
  const [cstype, setCStypeF] = useState("CON");
  const [cstypeV, setCStypeFV] = useState("Newest");

  const [oObjectData, setOObjectData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  // const [program, setProgram] = useState(true);
  const [courses, setCourses] = useState([]);
  let { name, id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // let courseName = name.replace('-', ' ');
  useEffect(() => {
    window.scrollTo(0, 0);

    if (userDetails === 0) {
      //history.push(`/`);
      window.location.href = config.aws_cloudfront_lurl;
    } else if (userDetails.curExploreData.type == "program") {
      setProgramData(userDetails.curExploreData.data);
      // let filterstoredata = { ...userDetails };
      // delete filterstoredata.filter;
      // dispatch(awsSignIn(filterstoredata));

      if (userDetails.filter !== undefined && userDetails.filter.setPStypeF) {
        setPStypeF(userDetails.filter.setPStypeF);
        sortFun(userDetails.filter.setPStypeF, userDetails.curExploreData.data);

        commansetsort(userDetails.filter.setPStypeF);
      }
    } else if (userDetails.curExploreData.type == "content") {
      setObjectData(userDetails.curExploreData.data);
      setOObjectData(userDetails.curExploreData.data);

      if (
        userDetails.filter !== undefined &&
        userDetails.filter.setctypeF !== undefined
      ) {
        setAdProductF(userDetails.filter.setAdProductF);
        setMrToolsF(userDetails.filter.setMrToolsF);
        setDurF(userDetails.filter.setDurF);
        setCtypeF(userDetails.filter.setctypeF);
        setStypeF(userDetails.filter.setStypeF);
        if (
          userDetails.filter.setdurF != "" ||
          userDetails.filter.setctypeF != ""
        ) {
          commansetsort(userDetails.filter.setStypeF);
          filter(userDetails.filter.setctypeF, userDetails.filter.setdurF);
        } else if (
          userDetails.filter.setStypeF !== undefined &&
          userDetails.filter.setStypeF != ""
        ) {
          sortFun(
            userDetails.filter.setStypeF,
            userDetails.curExploreData.data
          );
          commansetsort(userDetails.filter.setStypeF);
        }
      } else if (userDetails.curExploreData.from != "search") {
        let ctp =
          userDetails.filter === undefined ||
          userDetails.filter.setStypeF === undefined
            ? "CON"
            : userDetails.filter.setStypeF;
        setStypeFV("Newest");
        setStypeF(ctp);
        sortFun(ctp, userDetails.curExploreData.data);
        commansetsort(ctp);
      } else if (userDetails.curExploreData.from == "search") {
        let ctp =
          userDetails.filter === undefined ||
          userDetails.filter.setStypeF === undefined
            ? "CON"
            : userDetails.filter.setStypeF;

        setStypeF(ctp);
        sortFun(ctp, userDetails.curExploreData.data);
        commansetsort(ctp);
        setStypeFV("Select");
      }

      // setProgram(false);
    } else {
      if (userDetails.curExploreData.type === "Courses") {
        setCourses(userDetails.curExploreData.courses);
        // setProgram(false);
      }
    }

    //edGetCategoryData();
  }, []);

  console.log(userDetails.curExploreData.data);

  function programClick(PNAME, PID, courses) {
    let sdata = { ...userDetails };
    sdata.curExploreProgramData = {
      from: userDetails.curExploreData.from !== "search" ? "explore" : "search",
      PNAME: PNAME,
      PID: PID,
      courses: courses,
      TNAME: userDetails.curExploreData.topicName,
      TOPICID: userDetails.curExploreData.topicId,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      viewAll: sdata.location.viewAll,
      programName: PNAME,
      programId: PID,
    };
    dispatch(awsSignIn(sdata));
    history.push(`/ExploreCourseView?id=${PID}`);
  }

  let breadcrumb = { ...userDetails };
  const handleLocation = (OD, OBJID) => {
    breadcrumb.location = {
      from: breadcrumb.location.from,
      topicName: breadcrumb.location.topicName,
      contentName: OD.ON,
      topicId: breadcrumb.location.topicId,
      viewAll: true,
      viewAllRoute: "/ExploreViewAll",
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`/content?id=${OBJID}`);
  };
  const handleCourseLocation = (TID, TN) => {
    breadcrumb.location = {
      prevLoc: userDetails.location.from === "Home" ? "Home" : "Explore",
      from: userDetails.location.from,
      topicName: userDetails.curExploreData.topicName,
      courseName: TN,
      topicId: userDetails.curExploreData.topicId,
      viewall: true,
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`course/${TID}`);
  };
  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  const contentTypeChange = (event) => {
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setDurF = durF;
    filterstoredata.filter.setMrToolsF = mrToolsF;
    console.log(userDetails.filter);
    if (userDetails.filter === undefined) {
      filterstoredata.filter.setCDurF = "";
      filterstoredata.filter.vtype = "content";
      filterstoredata.filter.setCMrToolsF = "";
    } else {
      if (filterstoredata.filter.vtype !== undefined) {
        filterstoredata.filter.vtype = userDetails.filter.vtype;
      } else {
        filterstoredata.filter.vtype = "content";
      }

      if (userDetails.filter.setCDurF !== undefined) {
        filterstoredata.filter.setCDurF = userDetails.filter.setCDurF;
      } else {
        filterstoredata.filter.setCDurF = "";
      }

      if (userDetails.filter.setCMrToolsF !== undefined) {
        filterstoredata.filter.setCMrToolsF = userDetails.filter.setCMrToolsF;
      } else {
        filterstoredata.filter.setCMrToolsF = "";
      }
    }

    filterstoredata.filter.setMrToolsF = mrToolsF;

    let val = event.target.value;
    if (ctype == event.target.value) {
      setCtypeF("");
      val = "";
      filterstoredata.filter.setCtypeF = "";
    } else {
      setCtypeF(event.target.value);
      filterstoredata.filter.setCtypeF = event.target.value;
    }
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setAdProductF = adProductF;
    if (userDetails.filter === undefined) {
      filterstoredata.filter.setCStypeF = "";
      filterstoredata.filter.setPStypeF = "";
      filterstoredata.filter.setCAdProductF = "";
    } else {
      filterstoredata.filter.setCStypeF = userDetails.filter.setCStypeF;
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setCAdProductF = userDetails.filter.setCAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }

    dispatch(awsSignIn(filterstoredata));
    filter(val, durF);
  };

  const DurHandleChange = (event) => {
    let val = event.target.value;

    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    if (durF == event.target.value) {
      setDurF("");
      val = "";
      filterstoredata.filter.setDurF = "";
    } else {
      setDurF(event.target.value);
      filterstoredata.filter.setDurF = event.target.value;
    }
    if (userDetails.filter !== undefined) {
      filterstoredata.filter.setCDurF = userDetails.filter.setCDurF;
      filterstoredata.filter.setCMrToolsF = userDetails.filter.setCMrToolsF;
      filterstoredata.filter.setCStypeF = userDetails.filter.setCStypeF;
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setCAdProductF = userDetails.filter.setCAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }
    dispatch(awsSignIn(filterstoredata));
    filter(ctype, val);
  };

  function filterO(adprod, mrtool, dur) {
    let data = [...oObjectData];

    // All Value
    if (adprod != "" && mrtool != "" && dur != "") {
    }

    // ad & mr
    if (adprod != "" && mrtool != "" && dur == "") {
    }

    // ad && dur
    if (adprod != "" && mrtool == "" && dur != "") {
    }

    // mr && dur
    if (adprod == "" && mrtool != "" && dur != "") {
    }

    // ad
    if (adprod != "" && mrtool == "" && dur == "") {
    }

    // mr
    if (adprod == "" && mrtool != "" && dur == "") {
    }

    // dur
    if (adprod == "" && mrtool == "" && dur != "") {
    }
  }

  function filter(otype, dur) {
    let data = [...userDetails.curExploreData.data];
    // alert(JSON.stringify({ otype, dur }));
    let filteredData = data.filter(function(e) {
      // let obj = { ...e.OD };
      let res = false;
      let o = false;
      // let a = false;
      // let m = false;
      let d = false;

      if (otype != "") {
        if (otype == e.otype) {
          o = true;
        }
        if (
          otype == "video" &&
          (e.otype == "scorm" || e.otype == "youtube" || e.otype == "vimeo")
        ) {
          o = true;
        }
      }

      if (dur != "") {
        if (dur == "5") {
          if (e.dur < 300) {
            d = true;
          }
        } else if (dur == "15") {
          if (e.dur < 900) {
            d = true;
          }
        } else if (dur == "30") {
          if (e.dur < 1800) {
            d = true;
          }
        } else if (dur == "30_60") {
          if (e.dur >= 1800 && e.dur <= 3600) {
            d = true;
          }
        } else if (dur == "60") {
          if (e.dur > 3600) {
            d = true;
          }
        }
      }

      // All Value
      if (otype != "" && dur != "") {
        if (o && d) {
          res = true;
        }
      }

      // otype
      if (otype != "" && dur == "") {
        if (o) {
          res = true;
        }
      }

      // dur
      if (otype == "" && dur != "") {
        if (d) {
          res = true;
        }
      }
      if (otype == "" && dur == "") {
        res = true;
      }

      return res;
    });
    console.log(data);
    setObjectData(filteredData);
    let valstype = stype;

    if (userDetails.filter !== undefined) {
      valstype = userDetails.filter.setStypeF;
    } else if (valstype == "") {
      valstype = "CON";
    }

    sortFun(valstype, filteredData);
    setOpenSnackbar(true);
  }

  function clear() {
    setObjectData(oObjectData);
    setCtypeF("");
    setAdProductF("");
    setMrToolsF("");
    setDurF("");
    setAnchorEl(null);
    setOpen(!open);
    setStypeF(stype);
    setStypeFV(stypeV);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    sortFun(stype, userDetails.curExploreData.data);
  }

  function filterby() {
    return (
      <div>
        <ListItem button onClick={handleClick}>
          {/* <ListItemText className={classes.heading} primary="Filter By" /> */}
          <div style={{ width: "90px", display: "flex", gap: "1rem" }}>
            <Typography className={classes.heading}>Filter By</Typography>
            <span>{open ? <ExpandLess /> : <ExpandMore />}</span>
          </div>
        </ListItem>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ maxHeight: "80%" }}
        >
          <ListItem button>
            <div style={{ width: "200px" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography className={classes.heading}>
                    Content Type
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="ContentType"
                        name="ContentType"
                        value={ctype}
                        onChange={contentTypeChange}
                      >
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="html"
                          control={<Radio />}
                          label="eBooks"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="pdf"
                          control={<Radio />}
                          label="One Sheets"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="interactivity"
                          control={<Radio />}
                          label="Presentation Slides"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="video"
                          control={<Radio />}
                          label="Videos"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>Duration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="Duration"
                        name="Duration"
                        value={durF}
                        onChange={DurHandleChange}
                      >
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="5"
                          control={<Radio />}
                          label="<5 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="15"
                          control={<Radio />}
                          label="<15 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="30"
                          control={<Radio />}
                          label="<30 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="30_60"
                          control={<Radio />}
                          label="30 m to 60 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="60"
                          control={<Radio />}
                          label=">60 m"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* <StyledMenuItem>
            
            <ListItemText primary="Drafts" />
          </StyledMenuItem>
          <StyledMenuItem>
            
            <ListItemText primary="Inbox" />
          </StyledMenuItem> */}
          </ListItem>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={clear}
              style={{ width: "100px" }}
            >
              Clear
            </Button>
          </div>
        </StyledMenu>
      </div>
    );
  }

  const handleClickS = (event) => {
    setAnchorElS(event.currentTarget);
    setOpenS(!openS);
  };

  const handleCloseS = () => {
    setAnchorElS(null);
    setOpenS(!openS);
  };

  const sortTypeChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    if (userDetails.curExploreData.type == "program") {
      console.log(programData);
      sortFun(val, userDetails.curExploreData.data);
      setPStypeF(event.target.value);
      filterstoredata.filter.setPStypeF = event.target.value;
      filterstoredata.filter.setStypeF = stype;
    } else {
      sortFun(val, objectData);
      setStypeF(event.target.value);
      filterstoredata.filter.setStypeF = event.target.value;
    }
    commansetsort(val);
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setDurF = durF;
    if (userDetails.filter !== undefined) {
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setCDurF = userDetails.filter.setCDurF;
      filterstoredata.filter.setCMrToolsF = userDetails.filter.setCMrToolsF;
      filterstoredata.filter.setCStypeF = userDetails.filter.setCStypeF;

      filterstoredata.filter.setCAdProductF = userDetails.filter.setCAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }
    dispatch(awsSignIn(filterstoredata));
  };

  function sortFun(val, obdata, vtype) {
    let data = obdata == undefined ? [...objectData] : [...obdata];
    // let cdata =cdata == undefined ? [...courseData] : [...obdata];

    if (vtype == "program") {
      data.sort((a, b) => b[val] - a[val]); //.reverse();
      setProgramData(data);
    } else {
      if (val == "AZ") {
        console.log(data);
        data.sort((a, b) => {
          if (a.oname < b.oname) {
            return -1;
          }
          if (a.oname > b.oname) {
            return 1;
          }
          return 0;
        });
      } else if (val == "TV") {
        data.sort((a, b) => {
          return b.sortdt.tv - a.sortdt.tv;
        });
      } else if (val == "BKMD") {
        data.sort((a, b) => {
          return b?.sortdt?.bookmark - a?.sortdt?.bookmark;
        });
      } else if (val == "SHRD") {
        data.sort((a, b) => {
          return b.sortdt.share - a.sortdt.share;
        });
      } else if (val == "STAR") {
        data.sort((a, b) => {
          return b.sortdt.rate - a.sortdt.rate;
        });
      } else if (val == "CON") {
        data.sort((a, b) => {
          return b["con"] - a["con"];
        });
      } else {
        data.sort((a, b) => b[val] - a[val]); //.reverse();
      }

      setObjectData(data);
    }
  }
  const commansetsort = (val) => {
    if (userDetails.curExploreData.type == "program") {
      if (val == "TV") {
        setPStypeFV("Most Viewed");
      }
      if (val == "CON") {
        setPStypeFV("Newest");
      }
    } else {
      if (val == "TV") {
        setStypeFV("Most Viewed");
      }
      if (val == "STAR") {
        setStypeFV("Rating");
      }
      if (val == "BKMD") {
        setStypeFV("Most Bookmarked");
      }
      if (val == "SHRD") {
        setStypeFV("Most Shared");
      }
      if (val == "CON") {
        setStypeFV("Newest");
      }
      if (val == "AZ") {
        setStypeFV("A-Z");
      }
    }
  };
  // function sortBy() {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <ListItem button onClick={handleClickS}>
  //         {/* <ListItemText className={classes.heading} primary="Filter By" /> */}
  //         <Typography
  //           className={classes.heading}
  //           style={{ width: "208px", display: "flex" }}
  //         >
  //           <span style={{ flexGrow: "30%" }}> Sort By </span>
  //           <span style={{ color: "blue", flexGrow: "1", textAlign: "center" }}>
  //             {stypeV}
  //           </span>
  //         </Typography>
  //         {openS ? <ExpandLess /> : <ExpandMore />}
  //       </ListItem>
  //       <StyledMenu
  //         id="customized-menu"
  //         anchorEl={anchorElS}
  //         keepMounted
  //         open={Boolean(anchorElS)}
  //         onClose={handleCloseS}
  //         style={{ maxHeight: "80%" }}
  //       >
  //         <ListItem button>
  //           <div style={{ width: "200px" }}>
  //             <FormControl component="fieldset">
  //               <RadioGroup
  //                 aria-label="sortType"
  //                 name="sortType"
  //                 value={stype}
  //                 onChange={sortTypeChange}
  //               >
  //                 <FormControlLabel
  //                   classes={{ label: classes.label }}
  //                   value="TV"
  //                   control={<Radio />}
  //                   label="Most Viewed"
  //                 />
  //                 <FormControlLabel
  //                   classes={{ label: classes.label }}
  //                   value="STAR"
  //                   control={<Radio />}
  //                   label="Rating"
  //                 />
  //                 <FormControlLabel
  //                   classes={{ label: classes.label }}
  //                   value="BKMD"
  //                   control={<Radio />}
  //                   label="Most Bookmarked"
  //                 />
  //                 <FormControlLabel
  //                   classes={{ label: classes.label }}
  //                   value="SHRD"
  //                   control={<Radio />}
  //                   label="Most Shared"
  //                 />
  //                 <FormControlLabel
  //                   classes={{ label: classes.label }}
  //                   value="CON"
  //                   control={<Radio />}
  //                   label="Newest"
  //                 />
  //               </RadioGroup>
  //             </FormControl>
  //           </div>
  //         </ListItem>
  //       </StyledMenu>
  //     </div>
  //   );
  // }

  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ fontSize: "1.6rem" }}
      />
    );
  }

  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div>
      {userDetails !== 0 && (
        <>
          {userDetails.location.from !== undefined &&
            (userDetails.location.from === "Home" ||
              userDetails.location.from === "Explore" ||
              userDetails.location.from === "Search") && (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="large"
                    style={{ fill: "#1974b5" }}
                  />
                }
                aria-label="breadcrumb"
                style={{ marginBottom: "3rem" }}
              >
                <Link
                  color="inherit"
                  to={userDetails.location.from === "Home" ? "/home" : "#"}
                  style={{
                    color: `${
                      userDetails.location.from === "Home" ? "#1974b5" : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                  }}
                >
                  {userDetails.location.from}
                </Link>
                {userDetails.location.topicName && (
                  <Link
                    style={{
                      color: "#1974b5",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                    color="inherit"
                    to={`/explore/${userDetails.location.topicName}/${userDetails.location.topicId}`}
                  >
                    {userDetails.location.topicName}
                  </Link>
                )}
                {userDetails.location.viewAll && (
                  <Link
                    color="inherit"
                    to="/ExploreViewAll"
                    style={{
                      color: "#777",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                  >
                    View all
                  </Link>
                )}
              </Breadcrumbs>
            )}

          <div className="flex-between">
            <div className="flex-container" style={{ width: "100%" }}>
              <h3 style={{ width: "100%", fontWeight: "600" }}>
                {/* {userDetails.curExploreData.courseName
                  ? userDetails.curExploreData.courseName.replaceAll("-", " ")
                  : userDetails.curExploreData.topicName.replaceAll("-", " ")}
                {programData !== undefined ? null : (
                  <strong
                    style={{
                      float: "right",
                      paddingRight: "4.0rem",
                      cursor: "pointer",
                      display: "flex",
                    }}
                  ></strong>
                )} */}
                Content & Collateral
              </h3>
            </div>
            {userDetails.curExploreData.type === "program" ? (
              <div>
                <ProgramExploreSort
                  sortTypeChange={sortTypeChange}
                  pstypeV={pstypeV}
                  pstype={pstype}
                  stype={userDetails.curExploreData.from}
                />
              </div>
            ) : null}
            {userDetails.curExploreData.type !== "program" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div>
                  <ExploreSort
                    handleClickS={handleClickS}
                    anchorElS={anchorElS}
                    handleCloseS={handleCloseS}
                    sortTypeChange={sortTypeChange}
                    stypeV={stypeV}
                    openS={openS}
                    stype={stype}
                    sorttp={userDetails.curExploreData.from}
                  />

                  {/* {userDetails.curExploreData.from == "search" ? "" : sortBy()}{" "} */}
                </div>

                {/* <span style={{ width: "115px" }}>{filterby()}</span> */}
                <span>
                  {
                    <ExploreFilter
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      contentTypeChange={contentTypeChange}
                      adProductF={adProductF}
                      DurHandleChange={DurHandleChange}
                      open={open}
                      ctype={ctype}
                      clear={clear}
                      mrToolsF={mrToolsF}
                      durF={durF}
                    />
                  }
                </span>
              </div>
            ) : null}
          </div>
          <Grid container spacing={2} className={classes.gridContent}>
            {programData !== undefined
              ? programData.map(({ PNAME, PID, courses }, idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={idx + "hello"}
                    onClick={() => {
                      programClick(PNAME, PID, courses);
                    }}
                  >
                    <CardLarge
                      pid={PID}
                      ttitle={PNAME}
                      course={courses.length}
                      pcount={true}
                    />
                  </Grid>
                ))
              : null}
            {objectData !== undefined &&
              objectData.map(({ objid, otype, oname, dur, sortdt }, idx) => {
                let OD = {
                  OTYPE: otype,
                  ON: oname,
                  DUR: dur,
                };
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    onClick={() => {
                      handleLocation(OD, objid);
                    }}
                  >
                    <ContentCard
                      // key={objid}
                      otype={otype}
                      star={sortdt.rate}
                      // ttitle={oname}
                      title={oname}
                      // star={star}
                      dur={dur}
                    />
                  </Grid>
                );
              })}

            {courses !== undefined ? (
              <>
                {courses.map(({ tn, TID, TNUGGETS }, idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    onClick={() => handleCourseLocation(TID, tn)}
                  >
                    <CardLarge
                      tid={TID}
                      title={tn}
                      type={"topic"}
                      course={TNUGGETS}
                    />
                  </Grid>
                ))}
              </>
            ) : null}
          </Grid>
          {objectData.length === 0 && programData.length === 0 ? (
            <div
              style={{
                height: "75vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "grey",
              }}
            >
              <AnimatePresence>
                <motion.div
                  className="slick-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, ease: "easeInOut" },
                  }}
                >
                  <img src={noThingIsHere} alt="noThingIsHere" />
                  {/* <h2>
                    No{" "}
                    {userDetails.curExploreData.type == "content"
                      ? "  Content  "
                      : "  Courses  "}
                    Present
                  </h2> */}
                </motion.div>
              </AnimatePresence>
            </div>
          ) : null}
          {/* <pre>{JSON.stringify(objectData, null, 2)}</pre> */}

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseA}
            message="Copied the link..!"
          >
            <Alert onClose={handleCloseA} severity="success">
              {objectData.length} Results
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}

export default Explore;
