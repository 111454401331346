import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Slick from "../../components/Carousel/Slick";
import { SwiperSlide } from "swiper/react";
import { Link, useHistory } from "react-router-dom";
import { Box, Breadcrumbs, makeStyles } from "@material-ui/core";
import ExploreFilter from "./ExploreFilter/ExploreFilter";
import ExploreSort from "./ExploreSort/ExploreSort";
//React router
import { useParams } from "react-router-dom";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import { Skeleton } from "@material-ui/lab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CourseExploreFilter from "./ExploreFilter/CourseExploreFilter";
import CourseExploreSort from "./ExploreSort/CourseExploreSort";
import "./Explore.scss";
import { useQuery } from "react-query";
import ContentCard from "../../components/Cards/ContentCard/ContentCard";
import { AnimatePresence, motion } from "framer-motion";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import filtericon from "../../assets/filter.png";
import sorticon from "../../assets/sort.png";

const useStyles = makeStyles((theme) => ({
  GifDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width: 500px)': {
      display: "contents",
    },
  },
  headerLinks: {
    marginLeft: "2rem",
    marginBottom: "2.5rem",
    '@media (max-width: 500px)': {
      marginLeft: 0,
      marginBottom: 0,
    },
  },
  mainHeader: {
    fontWeight: "600",
    marginLeft: "2rem",
    '@media (max-width: 500px)': {
      marginLeft: 0,
    },
  },
  noThingIsHereImg: {
    '@media (max-width: 500px)': {
      width:"100%",
      height: "100%",
    },
  },
}));

const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
function Explore() {
  const classes = useStyles();
  const userDetails = useSelector(authData);
  const { data: categoryData } = useSelector((state) => state.category);

  const [open, setOpen] = useState(false);
  const [copen, setCOpen] = useState(false);

  const [adProductF, setAdProductF] = useState("");
  const [oObjectData, setOObjectData] = useState([]);
  const [mrToolsF, setMrToolsF] = useState("");
  const [durF, setdurF] = useState("");
  const [ctype, setctypeF] = useState("");
  const [stype, setStypeF] = useState("CON");
  const [stypeV, setStypeFV] = useState("Newest");
  const [openS, setOpenS] = useState(false);
  const [anchorCEl, setAnchorCEl] = useState(null);
  const [anchorElS, setAnchorElS] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  //course setstate
  const [cdurF, setCDurF] = useState("");
  const [cctype, setCctypeF] = useState("");
  const [cstype, setCStypeF] = useState("CON");
  const [cstypeV, setCStypeFV] = useState("Newest");
  const [pstype, setPStypeF] = useState("CON");
  const [pstypeV, setPStypeFV] = useState("Newest");
  const [crOpenS, setCrOpenS] = useState(false);

  const [canchorElS, setCAnchorElS] = useState(null);
  const [panchorElS, setPAnchorElS] = useState(null);

  const [cadProductF, setCAdProductF] = useState("");
  const [cmrToolsF, setCMrToolsF] = useState("");

  const [programData, setProgramData] = useState([]);
  const [oprogramData, setOProgramData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [oCourseData, setOCourseData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  const [crOpen, setCrOpen] = useState(false);
  const [pOpen, setpOpen] = useState(false);
  let { name, id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setvisible] = useState(false);
  const [visibleCourse, setvisibleCourse] = useState(false);
  const show = () => setvisible(!visible);
  const hide = () => setvisible(false);
  const showCourse = () => setvisibleCourse(!visibleCourse);
  const [isLoading, setIsLoading] = useState(true);

  let courseName = name.replace("-", " ");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!userDetails || userDetails === 0) {
      if (config.aws_org_id == "AXIATA") {
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    } else {
      if (id === "search") {
        searchData();
      } else {
        getCategoryData();
      }

      // setIsLoading(true);
      //sortFun(stype, oObjectData);
    }

    console.log("userDetails", userDetails);
  }, []);
  async function getCategoryData() {
    const bodyParam = {
      body: {
        tid: id,
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        schema: config.schema,
        ur_id: userDetails?.uData?.ur_id,
      },
      headers: {
        "content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setIsLoading(true);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/edGetCategoryData",
        bodyParam
      );

      if (response !== undefined) {
        let { objects, courses, programs } = response;
        let copyObject = [...objects];
        let filldata = { ...userDetails };

        let temp = [];
        let OCourse = [];
        for (let i = 0; i < courses?.length; i++) {
          if (
            courses[i].pid != undefined &&
            courses[i].pid !== 0 &&
            courses[i].pid.replace(/\s/g, "").length != 0
          ) {
            temp.push(courses[i]);
          } else {
            OCourse.push(courses[i]);
          }
        }

        OCourse?.sort((a, b) => b["con"] - a["con"]);
        setCourseData(OCourse);
        setOCourseData(OCourse);

        let result = groupByKey(temp, "pid");

        let program = [];
        for (const [key, value] of Object.entries(result)) {
          let obj = {};
          obj.pid = value[0].pid;
          obj.pname = value[0].pname;
          obj.courses = value;
          if (programs[value[0].pid] != undefined) {
            obj.con = programs[value[0].pid].con;
            obj.tv = programs[value[0].pid].tv;
          }
          program.push(obj);
        }
        program?.sort((a, b) => b["con"] - a["con"]);
        setProgramData(program);
        setOProgramData(program);

        copyObject?.sort((a, b) => b["con"] - a["con"]);
        setObjectData(copyObject);
        setOObjectData(copyObject);

        if (filldata.filter === undefined) {
          filldata.filter = {};
          filldata.filter.cdata = OCourse;
          filldata.filter.pdata = program;
          filldata.filter.odata = copyObject;

          dispatch(awsSignIn(filldata));
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }
  async function filterset() {
    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setCStypeF !== undefined
    ) {
      setCStypeF(userDetails.filter.setCStypeF);
      setctypeF(userDetails.filter.setctypeF);
      if (
        (userDetails.filter.setCAdProductF != "" ||
          userDetails.filter.setCMrToolsF != "" ||
          userDetails.filter.setCDurF != "") &&
        userDetails.filter.cdata !== undefined
      ) {
        filter(
          "",
          userDetails.filter.setCAdProductF,
          userDetails.filter.setCMrToolsF,
          userDetails.filter.setCDurF,
          "course"
        );
      } else if (
        userDetails.filter.setCStypeF != "" &&
        userDetails.filter.cdata !== undefined
      ) {
        sortFun(
          userDetails.filter.setCStypeF,
          userDetails.filter.cdata,
          "course"
        );

        commansetsort(userDetails.filter.setCStypeF, "course");
      } else {
        commansetsort("con", "course");
      }

      if (id == "search" && userDetails.filter.setCStypeF !== "AZ") {
        setCStypeFV("Select");
      }
    } else if (
      userDetails.filter !== undefined &&
      userDetails.filter.setCStypeF !== undefined &&
      userDetails.filter.setCStypeF != "" &&
      userDetails.filter.cdata !== undefined
    ) {
      sortFun(
        userDetails.filter.setCStypeF,
        userDetails.filter.cdata,
        "course"
      );
    }

    if (
      id == "search" &&
      (userDetails.filter === undefined ||
        userDetails.filter.setCStypeF === undefined ||
        userDetails.filter.setCStypeF !== "AZ")
    ) {
      setCStypeFV("Select");
    } else if (userDetails.filter !== undefined) {
      commansetsort(userDetails.filter.setCStypeF, "course");
    }
    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setPStypeF != undefined &&
      userDetails.filter.pdata !== undefined
    ) {
      setPStypeF(userDetails.filter.setPStypeF);
      sortFun(
        userDetails.filter.setPStypeF,
        userDetails.filter.pdata,
        "program"
      );
      commansetsort(userDetails.filter.setPStypeF, "program");
    }

    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setAdProductF !== undefined
    ) {
      setAdProductF(userDetails.filter.setAdProductF);
      setMrToolsF(userDetails.filter.setMrToolsF);
      setdurF(userDetails.filter.setdurF);
      setCDurF(userDetails.filter.setCDurF);
      setStypeF(userDetails.filter.setStypeF);
      setStypeF(userDetails.filter.setStypeF);

      if (
        (userDetails.filter.setAdProductF != "" ||
          userDetails.filter.setMrToolsF != "" ||
          userDetails.filter.setdurF != "" ||
          userDetails.filter.setctypeF != "") &&
        userDetails.filter.odata !== undefined
      ) {
        filter(
          userDetails.filter.setctypeF,
          userDetails.filter.setAdProductF,
          userDetails.filter.setMrToolsF,
          userDetails.filter.setdurF,
          "content"
        );
      } else if (
        userDetails.filter.setStypeF != "" &&
        userDetails.filter.odata !== undefined
      ) {
        sortFun(
          userDetails.filter.setStypeF,
          userDetails.filter.odata,
          "content"
        );
      }

      commansetsort(userDetails.filter.setStypeF, "content");
    } else {
      setAdProductF("");
      setMrToolsF("");
      setdurF("");
      setCDurF("");
      setctypeF("");
      setStypeF("con");
      setCMrToolsF("");
      setCAdProductF("");
      setCctypeF("");
      setPStypeF("con");
      commansetsort("con", "program");
      commansetsort("con", "content");

      if (id == "search") {
        setStypeFV("Select");
      }
    }
  }
  const filterName = categoryData.filter(
    (category) => category.id.toString() === id
  );
  //console.log(userDetails);

  // async function edGetCategoryData()

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  const handlecontentLocation = (OD, OBJID) => {
    const breadcrumbs = { ...userDetails };
    breadcrumbs.location = {
      from: breadcrumbs.location.from,
      topicName: breadcrumbs.location.topicName,
      topicId: breadcrumbs.location.topicId,
      contentName: OD.ON,
    };
    dispatch(awsSignIn(breadcrumbs));
    history.push(`/content?id=${OBJID}`);
  };

  const handleCourseLocation = (tid, title) => {
    const courseLocation = { ...userDetails };
    courseLocation.location = {
      from: courseLocation.location.from,
      topicName: courseLocation.location.topicName,
      topicId: courseLocation.location.topicId,
      courseName: title,
    };
    dispatch(awsSignIn(courseLocation));
    history.push(`/course?id=${tid}`);
  };

  async function searchData() {
    const bodyParam = {
      body: {
        searchTerm: name,
        tenant: userDetails.locale,
        schema: config.schema,
        urid: userDetails?.uData?.ur_id,
      },
      headers: {
        "content-Type": "application/json",
        Accept: "application/json",
      },
    };

    setIsLoading(true);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/searchTopics",
        bodyParam
      );
      let OCourse = [];
      const uniqueId = new Set();
      const uniqueSession = response.search.filter((el)=>{
        const isDuplicate = uniqueId.has(el.sid);
        uniqueId.add(el.sid);
        if(!isDuplicate){
          return true;
        }
        return false
      })
      let apiData = uniqueSession;
      console.log("Serach", response);

      if (apiData !== undefined && apiData.length > 0) {
        let { objects, courses } = convertSearch(apiData);

        let temp = [];

        if (courses.length > 0) {
          for (let i = 0; i < courses.length; i++) {
            if (
              courses[i].pid != undefined &&
              courses[i].pid != 0 &&
              courses[i].pid[0].replace(/\s/g, "").length != 0
            ) {
              temp.push(courses[i]);
            } else {
              OCourse.push(courses[i]);
            }
          }

          OCourse.sort((a, b) => {
            if (a.tn < b.tn) {
              return -1;
            }
            if (a.tn > b.tn) {
              return 1;
            }
            return 0;
          });
          setCourseData(OCourse);
          setOCourseData(OCourse);
        }
        let result = groupByKey(temp, "pid");

        let program = [];
        for (const [key, value] of Object.entries(result)) {
          let obj = {};
          obj.pid = value[0].pid;
          obj.pname = value[0].pname;
          obj.courses = value;
          program.push(obj);
        }
        setProgramData(program);
        setOProgramData(program);

        objects.sort((a, b) => b["con"] - a["con"]);

        setObjectData(objects);

        setOObjectData(objects);
      } else {
        setCourseData([]);
        setOCourseData([]);
      }

      filterset();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  function convertSearch(data) {
    let courses = [];
    let objects = [];
    let res = {};

    data.forEach((val) => {
      let v = val;

      if (v.itype !== undefined && v.itype === 1) {
        let obj = {};

        obj.tid = val.sid;
        obj.dur = v.dur !== undefined ? v.dur : 0;
        obj.nugcnt = v.nugcnt !== undefined ? v.nugcnt : 0;
        obj.nav = v.nav !== undefined ? v.nav : "";
        obj.ctype = v.ctype !== undefined ? v.ctype : "";
        obj.cert = v.cert !== undefined ? v.cert : "";
        obj.tn = v.tn !== undefined ? v.tn : "";
        obj.pid = v.pid !== undefined ? v.pid : undefined;
        obj.pname = v.pname !== undefined ? v.pname : undefined;
        obj.sub_date = v.sub_date !== undefined ? v.sub_date : undefined;
        obj.comp_date = v.comp_date !== undefined ? v.comp_date : undefined;
        courses.push(obj);
      }

      if (v.itype !== undefined && v.itype === 2) {
        let obj = {};

        let od = {};
        obj.con = v.con !== undefined ? v.con : "";
        obj.dur = v.dur !== undefined ? v.dur : 0;
        obj.objid = v.sid;
        obj.otype = v.otype !== undefined ? v.otype : 0;
        obj.oname = v.tn !== undefined ? v.tn : 0;
        obj.sortdt = v.sortdt !== undefined && v.sortdt;
        obj.OD = od;

        objects.push(obj);
      }
    });

    res.courses = courses;
    res.objects = objects;

    return res;
  }

  function programViewAll() {
    let sdata = { ...userDetails };
    sdata.curExploreData = {
      courseName: courseName,
      from: id === "search" ? "search" : "explore",
      type: "program",
      data: programData,
      topicName: name,
      topicId: id,
      viewall: true,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      viewAll: true,
      viewAllId: sdata.location.topicId,
    };
    dispatch(awsSignIn(sdata));
    history.push("/ExploreViewAll");
  }

  function contentviewAll() {
    let sdata = { ...userDetails };

    sdata.curExploreData = {
      courseName: courseName,
      from: id == "search" ? "search" : "explore",
      type: "content",
      data: oObjectData,
      topicName: name,
      topicId: id,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      viewAll: true,
    };
    if (sdata.filter !== undefined) {
      let filters = { ...sdata.filter };
      filters.cdata = oObjectData;
      sdata.filter = filters;
    } else {
      sdata.filter = {};
      sdata.filter.cdata = oObjectData;
    }

    dispatch(awsSignIn(sdata));
    history.push("/ExploreViewAll");
  }

  function courseViewAll() {
    let sdata = { ...userDetails };
    sdata.curExploreProgramData = {
      from: id === "search" ? "search" : "explore",
      pname: "View all",
      type: "Courses",
      courses: oCourseData,
      tnAME: name,
      TOPICID: id,
      viewall: false,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      viewAll: true,
      viewAllId: sdata.location.topicId,
    };

    dispatch(awsSignIn(sdata));

    history.push(`/ExploreCourseViews`);
  }

  function programClick(pname, pid, courses) {
    let sdata = { ...userDetails };
    sdata.curExploreProgramData = {
      prevLoc: userDetails.location.from === "Home" ? "Home" : "Explore",
      from: id == "search" ? "search" : "explore",
      pname: pname,
      pid: pid,
      courses: courses,
      tnAME: name,
      TOPICID: id,
      viewall: false,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      programName: pname,
      programId: pid,
    };
    dispatch(awsSignIn(sdata));
    history.push(`/ExploreCourseView?id=${pid}`);
  }

  function filter(otype, adprod, mrtool, dur, vtype) {
    let data;

    if (vtype == "course") {
      data =
        oCourseData === undefined || oCourseData == ""
          ? userDetails.filter.cdata
          : oCourseData;
    } else {
      data =
        oObjectData === undefined || oObjectData == ""
          ? userDetails.filter.odata
          : oObjectData;
    }

    let filteredData = [];

    filteredData = data.filter(function(e) {
      let obj;
      if (vtype == "content") {
        obj = { ...e.OD };
      } else {
        obj = { ...e };
      }
      let res = false;
      let o = false;
      let a = false;
      let m = false;
      let d = false;

      if (otype != "" && vtype == "content") {
        if (otype == e.otype) {
          o = true;
        }
        if (
          otype == "video" &&
          (e.otype == "scorm" || e.otype == "youtube" || e.otype == "vimeo")
        ) {
          o = true;
        }
      }

      if (adprod != "") {
        if (vtype == "content") {
          if (obj.ADPRODUCTS != undefined) {
            for (let i = 0; i < obj.ADPRODUCTS.length; i++) {
              if (adprod == obj.ADPRODUCTS[i]) {
                a = true;
                break;
              }
            }
          }
        } else {
          if (obj.adproducts != undefined) {
            for (let i = 0; i < obj.adproducts.length; i++) {
              if (adprod == obj.adproducts[i]) {
                a = true;
                break;
              }
            }
          }
        }
      }

      if (mrtool != "") {
        if (vtype == "content") {
          if (obj.MRTOOLS != undefined) {
            for (let i = 0; i < obj.MRTOOLS.length; i++) {
              if (mrtool == obj.MRTOOLS[i]) {
                m = true;
                break;
              }
            }
          }
        } else {
          if (obj.mrtools != undefined) {
            for (let i = 0; i < obj.mrtools.length; i++) {
              if (mrtool == obj.mrtools[i]) {
                m = true;
                break;
              }
            }
          }
        }
      }
      if (vtype == "content") {
        if (dur != "") {
          if (dur == "5") {
            if (e.dur < 300) {
              d = true;
            }
          } else if (dur == "15") {
            if (e.dur < 900) {
              d = true;
            }
          } else if (dur == "30") {
            if (e.dur < 1800) {
              d = true;
            }
          } else if (dur == "30_60") {
            if (e.dur >= 1800 && e.dur <= 3600) {
              d = true;
            }
          } else if (dur == "60") {
            if (e.dur > 3600) {
              d = true;
            }
          }
        }
      } else {
        if (dur != "") {
          if (dur == "5") {
            if (e.dur < 300) {
              d = true;
            }
          } else if (dur == "15") {
            if (e.dur < 900) {
              d = true;
            }
          } else if (dur == "30") {
            if (e.dur < 1800) {
              d = true;
            }
          } else if (dur == "30_60") {
            if (e.dur >= 1800 && e.dur <= 3600) {
              d = true;
            }
          } else if (dur == "60") {
            if (e.dur > 3600) {
              d = true;
            }
          }
        }
      }

      // All Value
      if (otype != "" && adprod != "" && mrtool != "" && dur != "") {
        if (o && a && m && d) {
          res = true;
        }
      }

      // otype adprod mrtool
      if (otype != "" && adprod != "" && mrtool != "" && dur == "") {
        if (o && a && m) {
          res = true;
        }
      }

      // otype adprod  dur
      if (otype != "" && adprod != "" && mrtool == "" && dur != "") {
        if (o && a && d) {
          res = true;
        }
      }

      // otype  mrtool dur
      if (otype != "" && adprod == "" && mrtool != "" && dur != "") {
        if (o && m && d) {
          res = true;
        }
      }

      //  adprod mrtool dur
      if (otype == "" && adprod != "" && mrtool != "" && dur != "") {
        if (a && m && d) {
          res = true;
        }
      }

      // otype adprod
      if (otype != "" && adprod != "" && mrtool == "" && dur == "") {
        if (o && a) {
          res = true;
        }
      }

      //  adprod mrtool
      if (otype == "" && adprod != "" && mrtool != "" && dur == "") {
        if (a && m) {
          res = true;
        }
      }

      //   mrtool dur
      if (otype == "" && adprod == "" && mrtool != "" && dur != "") {
        if (m && d) {
          res = true;
        }
      }

      // otype  dur
      if (otype != "" && adprod == "" && mrtool == "" && dur != "") {
        if (o && d) {
          res = true;
        }
      }

      // otype
      if (otype != "" && adprod == "" && mrtool == "" && dur == "") {
        if (o) {
          res = true;
        }
      }

      // adprod
      if (otype == "" && adprod != "" && mrtool == "" && dur == "") {
        if (a) {
          res = true;
        }
      }

      // mrtool
      if (otype == "" && adprod == "" && mrtool != "" && dur == "") {
        if (m) {
          res = true;
        }
      }

      // dur
      if (otype == "" && adprod == "" && mrtool == "" && dur != "") {
        if (d) {
          res = true;
        }
      }
      if (otype == "" && adprod == "" && mrtool == "" && dur == "") {
        res = true;
      }

      return res;
    });

    console.log("filteredData", filteredData);

    if (vtype == "course") {
      setCourseData(filteredData);
    } else {
      setObjectData(filteredData);
    }

    let valstype = stype;

    if (userDetails.filter !== undefined) {
      valstype = userDetails.filter.setStypeF;
      if (vtype == "course") {
        valstype = userDetails.filter.setCStypeF;
      }
    } else if (valstype == "") {
      valstype = "con";
    }
    sortFun(valstype, filteredData, vtype);
  }
  function sortFun(val, obdata, codata, vtype) {
    let data = obdata == undefined ? [...objectData] : [...obdata];

    let cdata = codata == undefined ? [...courseData] : [...obdata];

    if (vtype == "course") {
      if (val == "AZ") {
        cdata.sort((a, b) => {
          if (a.tn < b.tn) {
            return -1;
          }
          if (a.tn > b.tn) {
            return 1;
          }
          return 0;
        });
      } else if (val == "TV") {
        cdata.sort((a, b) => {
          return b.sortdt.tv - a.sortdt.tv;
        });
      } else if (val == "BKMD") {
        cdata.sort((a, b) => {
          return b?.sortdt?.bookmark - a?.sortdt?.bookmark;
        });
      } else if (val == "SHRD") {
        cdata.sort((a, b) => {
          return b.sortdt.share - a.sortdt.share;
        });
      } else if (val == "STAR") {
        cdata.sort((a, b) => {
          return b.sortdt.rate - a.sortdt.rate;
        });
      } else if (val == "CON") {
        cdata.sort((a, b) => {
          return b["con"] - a["con"];
        });
      } else {
        cdata.sort((a, b) => b[val] - a[val]); //.reverse();
      }
      setCourseData(cdata);
    } else if (vtype == "program") {
      data.sort((a, b) => b[val] - a[val]); //.reverse();
      setProgramData(data);
    } else {
      if (val == "AZ") {
        //console.log(data);
        data.sort((a, b) => {
          if (a.oname < b.oname) {
            return -1;
          }
          if (a.oname > b.oname) {
            return 1;
          }
          return 0;
        });
      } else if (val == "TV") {
        data.sort((a, b) => {
          return b.sortdt.tv - a.sortdt.tv;
        });
      } else if (val == "BKMD") {
        data.sort((a, b) => {
          return b?.sortdt?.bookmark - a?.sortdt?.bookmark;
        });
      } else if (val == "SHRD") {
        data.sort((a, b) => {
          return b.sortdt.share - a.sortdt.share;
        });
      } else if (val == "STAR") {
        data.sort((a, b) => {
          return b.sortdt.rate - a.sortdt.rate;
        });
      } else if (val == "CON") {
        data.sort((a, b) => {
          return b["con"] - a["con"];
        });
      } else {
        data.sort((a, b) => b[val] - a[val]); //.reverse();
      }

      setObjectData(data);
    }
  }
  const commansetsort = (val, vtype) => {
    if (vtype == "content") {
      if (val == "tv") {
        setStypeFV("Most Viewed");
      }
      if (val == "STAR") {
        setStypeFV("Rating");
      }
      if (val == "BKMD") {
        setStypeFV("Most Bookmarked");
      }
      if (val == "SHRD") {
        setStypeFV("Most Shared");
      }
      if (val == "con") {
        setStypeFV("Newest");
      }
      if (val == "AZ") {
        setStypeFV("A-Z");
      }
    } else if (vtype == "course") {
      if (val == "tv") {
        setCStypeFV("Most Viewed");
      }
      if (val == "STAR") {
        setCStypeFV("Rating");
      }

      if (val == "BKMD") {
        setCStypeFV("Most Bookmarked");
      }
      if (val == "SHRD") {
        setCStypeFV("Most Shared");
      }
      if (val == "con") {
        setCStypeFV("Newest");
      }
      if (val == "AZ") {
        setCStypeFV("A-Z");
      }
    } else {
      if (val == "tv") {
        setPStypeFV("Most Viewed");
      }
      if (val == "con") {
        setPStypeFV("Newest");
      }
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    show();
  };
  const handleCourseClick = (event) => {
    setAnchorCEl(event.currentTarget);
    setCOpen(!copen);
    setvisibleCourse(false);
    showCourse();
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  const contentTypeChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setCDurF = cdurF;
    // filterstoredata.filter.setMrToolsF = mrToolsF;
    // filterstoredata.filter.setCMrToolsF = cmrToolsF;
    // filterstoredata.filter.vtype = vtype;

    if (ctype == event.target.value) {
      setctypeF("");
      val = "";
      filterstoredata.filter.setctypeF = "";
    } else {
      setctypeF(event.target.value);
      filterstoredata.filter.setctypeF = event.target.value;
    }
    filterstoredata.filter.odata = oObjectData;
    filterstoredata.filter.cdata = oCourseData;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setCStypeF = cstype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    dispatch(awsSignIn(filterstoredata));

    filter("", cadProductF, cmrToolsF, cdurF, "course");

    filter(val, adProductF, mrToolsF, durF, "content");
  };

  function clear() {
    setObjectData(oObjectData);
    setctypeF("");
    setAdProductF("");
    setMrToolsF("");
    setdurF("");
    setAnchorEl(null);
    setOpen(!open);
    setvisible(false);
    setStypeF(stype);
    setStypeFV(stypeV);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    sortFun(stype, oObjectData, "content");
  }
  function sclear() {
    setCctypeF("");
    setCAdProductF("");
    setCMrToolsF("");
    setCDurF("");
    setAnchorCEl(null);
    setCrOpenS(false);
    setCrOpen(false);
    setCStypeF(stype);
    setCStypeFV(stypeV);
    setvisibleCourse(false);
    setCourseData(oCourseData);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    sortFun(stype, oCourseData, "course");
  }

  const AdHandleChange = (event, vtype) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setCStypeF = cstype;

    filterstoredata.filter.vtype = vtype;

    if (vtype == "course") {
      if (cadProductF == event.target.value) {
        setCAdProductF("");
        filterstoredata.filter.setCAdProductF = "";
        val = "";
      } else {
        setCAdProductF(event.target.value);
        filterstoredata.filter.setCAdProductF = event.target.value;
      }
      filterstoredata.filter.setAdProductF = adProductF;
    } else {
      if (adProductF == event.target.value) {
        setAdProductF("");
        filterstoredata.filter.setAdProductF = "";
        val = "";
      } else {
        setAdProductF(event.target.value);
        filterstoredata.filter.setAdProductF = event.target.value;
      }
      filterstoredata.filter.setCAdProductF = cadProductF;
    }
    filterstoredata.filter.odata = oObjectData;
    filterstoredata.filter.cdata = oCourseData;
    dispatch(awsSignIn(filterstoredata));
    if (vtype == "course") {
      filter("", val, cmrToolsF, cdurF, vtype);
    } else {
      filter(ctype, val, mrToolsF, durF, vtype);
    }
  };

  const ToolsHandleChange = (event, vtype) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setCStypeF = cstype;
    filterstoredata.filter.vtype = vtype;

    if (vtype == "course") {
      if (cmrToolsF == event.target.value) {
        setCMrToolsF("");
        filterstoredata.filter.setCMrToolsF = "";
        val = "";
      } else {
        setCMrToolsF(event.target.value);
        filterstoredata.filter.setCMrToolsF = event.target.value;
      }
      filterstoredata.filter.setMrToolsF = mrToolsF;
    } else {
      if (mrToolsF == event.target.value) {
        setMrToolsF("");
        val = "";
        filterstoredata.filter.setMrToolsF = "";
      } else {
        setMrToolsF(event.target.value);
        filterstoredata.filter.setMrToolsF = event.target.value;
      }
      filterstoredata.filter.setCMrToolsF = cmrToolsF;
    }
    filterstoredata.filter.cdata = oCourseData;
    filterstoredata.filter.odata = oObjectData;
    dispatch(awsSignIn(filterstoredata));
    if (vtype == "course") {
      filter("", cadProductF, val, cdurF, vtype);
    } else {
      filter(ctype, adProductF, val, durF, vtype);
    }
  };

  const DurHandleChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setPStypeF = pstype;
    filterstoredata.filter.setCStypeF = cstype;
    // filterstoredata.filter.vtype = vtype;

    let vtype;

    if (vtype == "course") {
      if (cdurF == event.target.value) {
        setCDurF("");
        val = "";
        filterstoredata.filter.setCDurF = "";
      } else {
        setCDurF(event.target.value);
        filterstoredata.filter.setCDurF = event.target.value;
      }
      filterstoredata.filter.setdurF = durF;
    } else {
      if (durF == event.target.value) {
        setdurF("");
        val = "";
        filterstoredata.filter.setdurF = "";
      } else {
        setdurF(event.target.value);
        filterstoredata.filter.setdurF = event.target.value;
      }
      filterstoredata.filter.setCDurF = cdurF;
    }
    // filterstoredata.filter.odata = oObjectData;
    // filterstoredata.filter.cdata = oCourseData;

    if (cdurF == event.target.value) {
      setCDurF("");
      val = "";
      filterstoredata.filter.setCDurF = "";
    } else {
      setCDurF(event.target.value);
      filterstoredata.filter.setCDurF = event.target.value;
    }

    dispatch(awsSignIn(filterstoredata));

    filter("", cadProductF, cmrToolsF, val, "course");

    filter(ctype, adProductF, mrToolsF, val, "content");
  };
  const handleClickS = (event) => {
    setAnchorElS(event.currentTarget);
    setOpenS(!openS);
  };
  const chandleClickS = (event) => {
    setCAnchorElS(event.currentTarget);
    setCrOpenS(!crOpenS);
  };
  const handleproClickS = (event) => {
    setPAnchorElS(event.currentTarget);
    setpOpen(!pOpen);
  };
  const handleCloseS = () => {
    setAnchorElS(null);
    setOpenS(!openS);
  };
  const handleproCloseS = () => {
    setPAnchorElS(null);
    setpOpen(!pOpen);
  };
  const chandleCloseS = () => {
    setCAnchorElS(null);
    setCrOpenS(!crOpenS);
  };
  const sortTypeChange = (event) => {
    let data, cdata;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setctypeF = ctype;
    filterstoredata.filter.setdurF = durF;
    filterstoredata.filter.setCDurF = cdurF;
    //filterstoredata.filter.vtype = viewtype;

    // if (viewtype == "program") {
    //   setPStypeF(event.target.value);
    //   data = programData;
    //   filterstoredata.filter.pdata = oprogramData;
    //   filterstoredata.filter.setPStypeF = event.target.value;
    //   filterstoredata.filter.setCStypeF = cstype;
    //   filterstoredata.filter.setStypeF = stype;
    // } else

    // if (viewtype == "course") {
    //   setCStypeF(event.target.value);
    //   data = courseData;
    //   filterstoredata.filter.cdata = oCourseData;
    //   filterstoredata.filter.setCStypeF = event.target.value;
    //   filterstoredata.filter.setStypeF = stype;
    //   filterstoredata.filter.setPStypeF = pstype;
    // } else {
    //   setStypeF(event.target.value);
    //   data = objectData;
    //   filterstoredata.filter.odata = oObjectData;
    //   filterstoredata.filter.setCStypeF = cstype;
    //   filterstoredata.filter.setPStypeF = pstype;
    //   filterstoredata.filter.setStypeF = event.target.value;
    // }

    setCStypeF(event.target.value);

    let val = event.target.value;

    sortFun(val, data, cdata, "course");
    sortFun(val, data, cdata, "content");
    commansetsort(val);
    //for redex changes
    dispatch(awsSignIn(filterstoredata));
  };

  async function addAndRemoveBookmark(val, id, idx) {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tid: id,
          type: "topics",
          eid: userDetails.username,
          userfullname: userDetails.name,
          emailid: userDetails?.email,
          tenant: userDetails?.locale,
          ur_id: userDetails?.uData?.ur_id,
          bookmark: val === 1 ? true : false,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (val === 1) {
        bodyParam.body.bookmark_date = 1;
      }

      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );
      let updateBookmark = courseData ? [...courseData] : [...oCourseData];
      // updateBookmark = updateBookmark[idx].bookmark = val === 1 ? true : false;
      updateBookmark[idx].bk = val === 1 ? true : false;
      if (courseData && courseData?.length > 0) {
        setCourseData(updateBookmark);
      } else {
        setOCourseData(updateBookmark);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const addAndRemoveBookmarkContent = async (val, id, idx) => {
    const bodyParam = {
      body: {
        objid: id,
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (val === 0) {
      bodyParam.body.bookmark = true;
      bodyParam.body.bookmark_date = 1;
    } else {
      bodyParam.body.bookmark = false;
    }

    try {
      //console.log(JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      let updateBookmark = objectData ? [...objectData] : [...oObjectData];
      // updateBookmark = updateBookmark[idx].bookmark = val === 1 ? true : false;
      updateBookmark[idx].bk = val === 0 ? true : false;
      if (objectData && objectData?.length > 0) {
        setObjectData(updateBookmark);
      } else {
        setOObjectData(updateBookmark);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div>
      {userDetails !== 0 && (
        <>
          {userDetails.location.from !== undefined &&
            (userDetails.location.from === "Home" ||
              userDetails.location.from === "Explore" ||
              userDetails.location.from === "Search") && (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="large"
                    style={{ fill: "#1974b5" }}
                  />
                }
                aria-label="breadcrumb"
                className={classes.headerLinks}
              >
                <Link
                  color="inherit"
                  to={userDetails.location.from === "Home" ? "/home" : "#"}
                  style={{
                    color: `${
                      userDetails.location.from === "Home" ? "#1974b5" : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: `${
                      userDetails.location.from === "Home"
                        ? "pointer"
                        : "context-menu"
                    }`,
                  }}
                >
                  {userDetails.location.from}
                </Link>
                {userDetails.location.topicName &&
                userDetails.location.topicId ? (
                  <Link
                    style={{
                      color: "#777",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: "context-menu",
                    }}
                    color="inherit"
                    to={`/explore/${userDetails.location.topicName}/${userDetails.location.topicId}`}
                  >
                    {userDetails.location.topicName}
                  </Link>
                ) : null}
              </Breadcrumbs>
            )}
        </>
      )}

      <div className="flex-between">
        <div
          className="flex-container"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3
            component="h1"
            variant="h4"
            className={classes.mainHeader}
          >
            {/* {filterName[0].name} */}
            {userDetails?.location?.topicName}
          </h3>

          <div
            style={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              // cursor: "pointer",
            }}
          >
            {/* <img src={sorticon} height={"30px"} width={"40px"} alt="Sort" /> */}

            <CourseExploreSort
              sortTypeChange={sortTypeChange}
              cstype={cstype}
              sorttp={userDetails}
            />

            <ExploreFilter
              handleClick={handleClick}
              anchorEl={anchorEl}
              handleClose={handleClose}
              contentTypeChange={contentTypeChange}
              AdHandleChange={AdHandleChange}
              adProductF={adProductF}
              DurHandleChange={DurHandleChange}
              open={open}
              ctype={ctype}
              clear={clear}
              ToolsHandleChange={ToolsHandleChange}
              mrToolsF={mrToolsF}
              durF={durF}
              visible={visible}
              hide={hide}
            />
          </div>
        </div>
      </div>

      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            marginTop: "30px",
          }}
        >
          {/* {courseData && courseData?.length > 0 && ( */}

          {!isLoading &&
          courseData?.length === 0 &&
          objectData?.length === 0 ? (
            <>
              <div className={classes.GifDiv}>
                <img src={noThingIsHere} alt="noThingIsHere" className={classes.noThingIsHereImg} />
              </div>
            </>
          ) : (
            <>
              {courseData?.length === 0 ? (
                <></>
              ) : (
                <>
                  <div>
                    <Slick
                      heading={"Courses"}
                      viewall={courseViewAll}
                      handleClick={handleCourseClick}
                      anchorEl={anchorCEl}
                      handleClose={handleClose}
                      contentTypeChange={contentTypeChange}
                      AdHandleChange={AdHandleChange}
                      cadProductF={cadProductF}
                      DurHandleChange={DurHandleChange}
                      crOpen={crOpen}
                      cctype={cctype}
                      sclear={sclear}
                      ToolsHandleChange={ToolsHandleChange}
                      cmrToolsF={cmrToolsF}
                      cdurF={cdurF}
                      filter="true"
                      viewtype="course"
                      chandleClickS={chandleClickS}
                      canchorElS={canchorElS}
                      chandleCloseS={chandleCloseS}
                      sortTypeChange={sortTypeChange}
                      cstypeV={cstypeV}
                      cropenS={crOpenS}
                      cstype={cstype}
                      hctype={false}
                      visible={visibleCourse}
                      userDetails={userDetails.location.from}
                      data={courseData}
                      oCData={oCourseData}
                      loading={isLoading}
                    >
                      {courseData.map(
                        (
                          {
                            ttitle,
                            tid,
                            tnuggets,
                            tn,
                            nugcnt,
                            sortdt,
                            bk,
                            sub_date,
                            comp_date,
                          },
                          idx
                        ) => (
                          <SwiperSlide
                            key={idx}
                            className="slider-container"
                            // onClick={() => handleCourseLocation(tid, tn)}
                          >
                            <CardLarge
                              tid={tid}
                              title={tn}
                              type={"topic"}
                              count={nugcnt}
                              bookmark={bk}
                              sub_date={sub_date}
                              comp_date={comp_date}
                              handleBookmark={addAndRemoveBookmark}
                              handleLocation={() =>
                                handleCourseLocation(tid, tn)
                              }
                              index={idx}
                              star={sortdt?.rate}
                            />
                          </SwiperSlide>
                        )
                      )}
                    </Slick>
                  </div>
                </>
              )}

              {objectData?.length === 0 ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div>
                    <Slick
                      heading={"Content & Collateral"}
                      viewall={contentviewAll}
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      contentTypeChange={contentTypeChange}
                      AdHandleChange={AdHandleChange}
                      adProductF={adProductF}
                      DurHandleChange={DurHandleChange}
                      open={open}
                      ctype={ctype}
                      clear={clear}
                      ToolsHandleChange={ToolsHandleChange}
                      mrToolsF={mrToolsF}
                      durF={durF}
                      filter="true"
                      viewtype="content"
                      handleClickS={handleClickS}
                      anchorElS={anchorElS}
                      handleCloseS={handleCloseS}
                      sortTypeChange={sortTypeChange}
                      stypeV={stypeV}
                      openS={openS}
                      stype={stype}
                      visible={visible}
                      show={show}
                      hide={hide}
                      userDetails={userDetails.location.from}
                      data={objectData}
                      oCData={oObjectData}
                      loading={isLoading}
                    >
                      {objectData.map(
                        ({ objid, otype, oname, dur, sortdt, bk }, idx) => {
                          let OD = {
                            OTYPE: otype,
                            ON: oname,
                            DUR: dur,
                          };
                          return (
                            <SwiperSlide
                              key={idx}
                              className="slider-container"
                              // onClick={() => {
                              //   handlecontentLocation(OD, objid);
                              // }}
                            >
                              <ContentCard
                                key={objid + "ASDF"}
                                otype={otype}
                                star={sortdt?.rate}
                                title={oname}
                                bookmark={bk}
                                tid={objid}
                                handleBookmark={addAndRemoveBookmarkContent}
                                handleLocation={() =>
                                  handlecontentLocation(OD, objid)
                                }
                                index={idx}
                                dur={dur}
                              />
                            </SwiperSlide>
                          );
                        }
                      )}
                    </Slick>
                  </div>
                </>
              )}
            </>
          )}

          {/* )} */}
          {/* {objectData && objectData?.length > 0 && ( */}

          {/* )} */}
        </div>
      </React.Fragment>

      {/* {!isLoading && courseData?.length === 0 && objectData?.length === 0 && (
        <div className="result_not_found">
          <img className="img" src={noThingIsHere} alt="No Result Found" />
          <h2 className="text">No Result Found</h2>
        </div>
      )} */}
    </div>
  );
}

export default Explore;
