import React, { useState, useEffect } from "react";

import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { Typography } from "@material-ui/core";
import Tippy from "@tippyjs/react";

const defaultTippyProps = {
  // animation: "shift-away-subtle",
  arrow: true,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom-end",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,

  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
export default function ProgramExploreSort({
  handleproClickS,
  panchorElS,
  handleproCloseS,
  sortTypeChange,
  pstypeV,
  popenS,
  pstype,
  stype,
}) {
  return (
    <div style={{ display: "flex" }}>
      {stype != "search" && (
        <Tippy
          content={
            <ListItem>
              <div style={{ width: "200px" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="sortType"
                    name="sortType"
                    value={pstype}
                    onChange={(e) => sortTypeChange(e, "program")}
                  >
                    <FormControlLabel
                      value="TV"
                      control={<Radio />}
                      label="Most Viewed"
                    />

                    <FormControlLabel
                      value="CON"
                      control={<Radio />}
                      label="Newest"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </ListItem>
          }
          {...defaultTippyProps}
        >
          <ListItem style={{ cursor: "context-menu" }}>
            <Typography>
              <span>
                Sort By:{"  "}
                <span
                  style={{ color: "blue", flexGrow: "1", textAlign: "center" }}
                >
                  {pstypeV}
                </span>
              </span>
            </Typography>
            {popenS ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </Tippy>
      )}
    </div>
  );
}
