import React, { useState, useEffect } from "react";

import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import filtericon from "../../../assets/filter.png";
import sorticon from "../../../assets/sort.png";

import { withStyles } from "@material-ui/core/styles";

import { motion } from "framer-motion";
import Menu from "@material-ui/core/Menu";

import { Box, Breadcrumbs, makeStyles, Typography } from "@material-ui/core";
import Tippy from "@tippyjs/react";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    // padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: "12px",
  },
}));
const defaultTippyProps = {
  // animation: "shift-away-subtle",
  arrow: true,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom-end",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,

  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
export default function ExploreSort({
  // handleClickS,
  // anchorElS,
  // handleCloseS,
  sortTypeChange,
  // stypeV,
  // openS,
  stype,
  // ptype,
  sorttp,
}) {
  const classes = useStyles();

  return (
    <motion.div
      style={{ display: "flex" }}
      variants={containerVarients}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Tippy
        content={
          <ListItem>
            <div style={{ width: "200px" }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="sortType"
                  name="sortType"
                  value={stype}
                  onChange={(e) => sortTypeChange(e, "content")}
                >
                  {(sorttp === undefined ||
                    sorttp.toLowerCase() != "search") && (
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="TV"
                      control={<Radio />}
                      label="Most Viewed"
                    />
                  )}
                  {(sorttp === undefined ||
                    sorttp.toLowerCase() != "search") && (
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="STAR"
                      control={<Radio />}
                      label="Rating"
                    />
                  )}
                  {(sorttp === undefined ||
                    sorttp.toLowerCase() != "search") && (
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="BKMD"
                      control={<Radio />}
                      label="Most Bookmarked"
                    />
                  )}
                  {(sorttp === undefined ||
                    sorttp.toLowerCase() != "search") && (
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="SHRD"
                      control={<Radio />}
                      label="Most Shared"
                    />
                  )}
                  {(sorttp === undefined ||
                    sorttp.toLowerCase() != "search") && (
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      value="CON"
                      control={<Radio />}
                      label="Newest"
                    />
                  )}
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="AZ"
                    control={<Radio />}
                    label="A-Z"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
        }
        {...defaultTippyProps}
      >
        <ListItem style={{cursor: "pointer", margin: "1rem", padding: 0,}}>
          {/* <ListItemText className={classes.heading} primary="Filter By" />  */}
          <Typography
            className={classes.heading}
            // style={{ width: "208px", display: "flex" }}
          >
            <div>
              <img src={sorticon} height={"30px"} width={"40px"} alt="Sort" />
            </div>

            {/* <span
              style={{
                flexGrow: "30%",
                fontFamily: `myfontregular`,
                textAlign: "center",
              }}
            >
              {" "}
              Sort By :{" "}
              <span
                style={{
                  color: "blue",
                  flexGrow: "1",
                  textAlign: "center",
                  margin: "10px",
                }}
              > */}
            {/* {stypeV} */}
            {/* <span
                  style={{
                    width: "140px",
                    position: "absolute",
                    bottom: "0px",
                    left: " 80px",
                    borderBottom: "1.5px solid black",
                  }}
                ></span>
              </span>
            </span> */}
          </Typography>
          {/* {openS ? <ExpandLess /> : <ExpandMore />} */}
        </ListItem>
      </Tippy>
    </motion.div>
  );
}
