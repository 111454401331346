import React from "react";
import "./CardLarge.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import config, { Theme } from "../../../aws-exports";

import StarRoundedIcon from "@material-ui/icons/StarRounded";

import Modules from "../../../assets/media/modules.png";
import { makeStyles } from "@material-ui/styles";

import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";

import CardContent from "@material-ui/core/CardContent";
import Tippy from "@tippyjs/react";
import { StyledCard } from "../ContentCard/ContentCard.styles";
import { Skeleton } from "@material-ui/lab";
import {
  Bookmark,
  BookmarkBorderOutlined,
  StarRounded,
} from "@material-ui/icons";
import { CardActions } from "@material-ui/core";
const defaultTippyProps = {
  arrow: false,
  interactive: true,
  interactiveBorder: 10,
  placement: "top-start",
  delay: [0, 0],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,
  theme: "light",
  distance: 24,
  inertia: true,
};

const useStyles = makeStyles({
  outline: {
    fontSize: "3rem",
    fill: "none",
    stroke: "#646464",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  filled: {
    fontSize: "3rem",
    fill: "#FFDA46",
    stroke: "#FFDA46",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  colorSecondary: {
    background: "#cccccc",
  },
});

const CardLarge = ({ title, tid, heading, comp_date, sub_date, count, ...rest }) => {
  const classes = useStyles();

  let src = `${
    config.aws_cloudfront_url
  }${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${tid}.png`;

  // let src1 = `${
  //   config.aws_cloudfront_url
  // }${config.aws_org_id.toLowerCase()}-resources/images/program-images/${pid}.png`;

  return (
    <StyledCard>
      <div className="img-container" onClick={rest.handleLocation}>
        {src ? (
          <img src={src} alt={title} />
        ) : (
          <Skeleton variant="rect" width={210} height={118} />
        )}
        <div className="statusLable" 
        style={{backgroundColor: (comp_date !== null && comp_date !== undefined) ? '#00A300' : ((sub_date !== null && sub_date !== undefined)  ? "#FFAA33" : '#FF5733')}}>
          <span className="rating" style={{margin:"0.2rem"}}>
            {(comp_date !== null && comp_date !== undefined) ? 'Completed' : ((sub_date !== null && sub_date !== undefined)  ? "In-Progress" : 'Not-Started')}
          </span>
        </div>
        <div className="img-container--star-container">
          <StarRounded className="star" />
          <span className="rating" style={{margin:"0.2rem"}}>
            {rest?.star ? rest?.star.toFixed(1) : "0.0"}
          </span>
        </div>
      </div>

      <CardContent className="card-content" onClick={rest.handleLocation}>
        <Tippy content={title} {...defaultTippyProps}>
          <div className="para-container">
            <p>{title}</p>
          </div>
        </Tippy>
      </CardContent>
      <CardActions className="card-actions">
        <div className="card-actions--footer">
          {!rest.bookmarkPage && (
            <div className="card-actions--footer-time">
              <img src={Modules} alt="Modules" width="33px" />
              <p>{count} Modules</p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <div className="bookmark_date-container">
              <p className="bookmark_date-text">Bookmark Date</p>
              <span>:</span>
              <p className="bookmark_date-date">{rest.date}</p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <Bookmark className="bookmark-icon fill" onClick={rest.onClick} />
          )}
          {rest?.bookmark && !rest?.bookmarkPage && (
            <Bookmark
              className="bookmark-icon fill"
              onClick={() => rest.handleBookmark(0, tid, rest.index)}
            />
          )}
          {!rest?.bookmark && !rest?.bookmarkPage && (
            <BookmarkBorderOutlined
              className="bookmark-icon"
              onClick={() => rest.handleBookmark(1, tid, rest.index)}
            />
          )}
        </div>
      </CardActions>
    </StyledCard>
  );
};
export default CardLarge;
