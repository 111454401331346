import { API } from "aws-amplify";
import React from "react";
import { useSelector } from "react-redux";
import config from "../../../aws-exports";
import { authData } from "../../../redux/auth/authSlice";
import Carousel from "../Carousel";
import { useQuery } from "react-query";
import Loading from "../../Loading/Loading";
import { useState } from "react";
import { useEffect } from "react";

const RecentlyViewedCarousel = () => {
  const userDetails = useSelector(authData);
  const [recentlyViewedData, setRecentlyViewedData] = useState([]);

  const { isLoading, error, data } = useQuery("getRecenetView", async () => {
    const bodyParams = {
      body: {
        schema: config.schema,
        ur_id: userDetails?.uData?.ur_id,
        tenant: userDetails?.locale,
        emailid: userDetails?.email,
      },
      header: {
        "Contant-Type": "application/json",
      },
    };

    try {
      const { recentview } = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getRecenetView",
        bodyParams
      );

      console.log("recent",recentview)
      return recentview;
    } catch (err) {
      console.error(err);
      return err.message;
    }
  });

  useEffect(() => {
    if (data) {
      setRecentlyViewedData(data);
    }
  }, [data]);

  return (
    <React.Fragment>
      {data && data.length > 0 && (
        <Carousel
          heading="Recently Viewed"
          cardData={recentlyViewedData}
          setData={setRecentlyViewedData}
          loading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(RecentlyViewedCarousel);
