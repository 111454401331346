import React, { useState, useEffect } from "react";
import "./ViewAll.scss";
// import config from '../../../aws-exports';

import { useSelector, useDispatch } from "react-redux";
// import { awsSignIn, authData } from '../../../redux/auth/authSlice';
// import { decrement, increment } from './counterSlice'

// import BookmarkIcon from '@material-ui/icons/Bookmark';

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
// import { RatingPopUp } from '../../components/ModalPopUp/ModalPopUp';

//React router
// import { useParams } from 'react-router-dom';
import { useHistory, Link } from "react-router-dom";

import { Skeleton } from "@material-ui/lab";
import { Breadcrumbs } from "@material-ui/core";
import Header from "../../components/Header/Header";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import config, { Theme } from "../../aws-exports";
import ContentCard from "../../components/Cards/ContentCard/ContentCard";
import { syncClasses } from "@aws-amplify/datastore/lib-esm/datastore/datastore";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
  gridContainer: {
    marginTop: "2rem",
    boxShadow: Theme.boxShadow,
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "5px",
    '& > div': {
      display: "flex",
    },
    '@media (max-width: 430px)': {
      marginTop: 0,
      padding: "1rem",
      '& > div > div': {
        margin: "0.8rem",
      },
    },
  },
}));

function ViewAll() {
  const { viewAllArr } = useSelector((state) => state.home);
  const userDetails = useSelector(authData);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  const handleFLocation = (ttitle, tid, title, otype) => {
    let breadcrumb = { ...userDetails };

    if (!otype) {
      breadcrumb.location = {
        from: "Home",
        courseName: ttitle ? ttitle : title,
        viewAll: true,
        viewAllRoute: "/viewallcontent",
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`course?id=${tid}`);
    } else {
      breadcrumb.location = {
        from: "Home",
        contentName: ttitle ? ttitle : title,
        viewAll: true,
        viewAllRoute: "/viewallcontent",
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`content?id=${tid}`);
    }
  };

  const history = useHistory();

  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="large" style={{ fill: "#1974b5" }} />
        }
        aria-label="breadcrumb"
        style={{ marginTop: "2rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
        >
          Home
        </Link>
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          View all
        </Link>
      </Breadcrumbs>
      <div className="flex-between">
        <div className="flex-container">
          <Typography component="h1" variant="h4">
            {/* {courseName} */}
          </Typography>
        </div>
      </div>

      <Grid
        container
        spacing={1}
        className={classes.gridContainer}
      >
        {viewAllArr.map(
          ({
            title,
            tid,
            otype,
            ttitle,
            type,
            pname,
            noofnuggets,
            pcount,
            starAvg,
            ref_type,
            oname,
            dur,
          }) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => handleFLocation(ttitle, tid, title, otype)}
              key={tid}
            >
              {ref_type === 1 ? (
                <CardLarge
                  title={title}
                  tid={tid}
                  count={noofnuggets}
                  // handleBookmark={addAndRemoveBookmark}
                  // star={sortdt.rate}
                  // bookmark={bookmark}
                  // index={idx}
                  elseWhere={true}
                />
              ) : (
                <ContentCard
                  otype={otype}
                  title={title || oname}
                  dur={dur}
                  // handleBookmark={addAndRemoveBookmarkContent}
                  elseWhere={true}
                />
              )}
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
}

export default ViewAll;
