import React from "react";
import { motion } from "framer-motion";
import "./Svg.styles.css";

const svgVariants = {
  hidden: { rotate: -180 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1.5,
      ease: "easeInOut",
    },
  },
};
const loadingVarients = {
  exit: {
    y: "100vh",
    transition: { ease: "easeInOut" },
  },
};
const PageLoading = () => {
  return (
    <motion.div className="page-loading" variants={loadingVarients} exit="exit">
      <motion.svg
        id="Layer_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 84.71 84.7"
        variants={svgVariants}
        initial="hidden"
        animate="visible"
      >
        <g id="Layer_1-2">
          <g>
            <motion.path
              fill="none"
              d="M54.86,1.88l-4.81,10.95c-2.46-.65-5.06-.99-7.7-.99-15.21,0-27.85,11.19-30.09,25.78-.26,1.54-.39,3.11-.39,4.72,0,2.02,.19,4,.58,5.9,.75,3.85,2.21,7.44,4.26,10.62,2.36,3.66,5.49,6.76,9.15,9.12l-4.81,10.95c-7.87-4.57-14.13-11.63-17.69-20.08-1.42-3.35-2.41-6.91-2.94-10.62-.26-1.93-.41-3.9-.41-5.9,0-1.59,.1-3.18,.26-4.72C2.6,16.47,20.56,0,42.34,0c4.36,0,8.57,.65,12.52,1.88Z"
              variants={pathVariants}
            />
            <motion.path
              fill="none"
              d="M62.37,37.63H23.78c2.34-8.5,10.09-14.73,19.31-14.73s16.97,6.23,19.28,14.73Z"
              variants={pathVariants}
            />
            <motion.path
              fill="none"
              d="M84.71,42.34c0,2-.14,3.97-.41,5.9-.51,3.71-1.52,7.27-2.91,10.62-6.45,15.19-21.5,25.85-39.05,25.85-3.61,0-7.1-.46-10.45-1.3l4.89-11.1c1.81,.36,3.66,.53,5.56,.53,10.76,0,20.22-5.56,25.66-13.99h-12.86c-3.35,2.53-7.53,4.04-12.06,4.04s-8.71-1.52-12.06-4.04c-3.47-2.62-6.04-6.33-7.22-10.62h48.46c.39-1.9,.58-3.88,.58-5.9,0-1.61-.12-3.18-.36-4.72-1.25-8.02-5.63-15-11.84-19.67l4.86-11.07c10.33,6.74,17.53,17.89,18.92,30.74,.19,1.54,.29,3.13,.29,4.72Z"
              variants={pathVariants}
            />
          </g>
        </g>
      </motion.svg>
    </motion.div>
  );
};

export default PageLoading;
