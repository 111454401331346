/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const Theme = {
  color: {
    primary: " #217FB5",
    secondary: "#1C6D9C",
    appGray: "#7c7f91",
    secondaryLight: "rgba(40, 87, 129, 0.2)",
    grey_light_1: "#f7f7f7",
    grey_light_2: "#eee",

    grey_dark: "#777",
    grey_dark_2: "#999",
    grey_dark_3: "#333",

    white: "#fff",
    black: "#000",
  },
  boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
  backDropOpacity: "rgba(0, 0, 0, 0.85)",
};

const excelearnProd = {
  schema: "enabledsm",
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "us-east-1:b3f3d2c7-4164-483a-80fb-dd0a657676f8",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_dscvgAL0P",
  aws_user_pools_web_client_id: "hm8m4nibq0ll4lhk8hc4ae1va",
  oauth: {},
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "EXCELEARN",
  aws_cloudfront_url: "https://www.excelearn.enabled.live/",
  aws_cloudfront_lurl: "https://www.excelearn.enabled.live",
  DOMAIN: "www.excelearn.enabled.live",

  aws_api_name: "EN-PLATFORM",
  aws_api_endpoint:
    "https://kqd29za2e4.execute-api.ap-south-1.amazonaws.com/EN938437",
  aws_cloud_logic_custom_name: "EN-PLATFORM",
  aws_cloud_logic_custom: [
    {
      id: "kqd29za2e4",
      name: "EN-PLATFORM",
      description: "",
      endpoint:
        "https://kqd29za2e4.execute-api.ap-south-1.amazonaws.com/EN938437",
      region: "ap-south-1",
    },
  ],
  auth_url: "https://axiata.auth.ap-south-1.amazoncognito.com/",
};
const nseDev = {
  schema: "nse",
  aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_KgKOC6o5l",
  aws_user_pools_web_client_id: "5u1k2qqpvpnuuqkr22hjlijsjb",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "NSE",
  aws_cloudfront_url: "https://d13trvs3ngcag9.cloudfront.net",
  aws_cloudfront_lurl: "https://d13trvs3ngcag9.cloudfront.net",
  DOMAIN: "https://d13trvs3ngcag9.cloudfront.net",

  aws_api_name: "nseregistrationapi",
  aws_api_endpoint:
    "https://06cbde50ti.execute-api.ap-south-1.amazonaws.com/dev",
  aws_cloud_logic_custom_name: "nseregistrationapi",
  aws_cloud_logic_custom: [
    {
      id: "06cbde50ti",
      name: "nseregistrationapi",
      description: "",
      endpoint: "https://06cbde50ti.execute-api.ap-south-1.amazonaws.com/dev",
      region: "ap-south-1",
    },
  ],
  // auth_url: "https://axiata.auth.ap-south-1.amazoncognito.com/",
};

const excelearndev = {
  aws_project_region: "us-east-1",
  schema: "enabledsm",
  aws_cognito_identity_pool_id:
    "us-east-1:b9d3a0f2-ad3c-41da-93eb-9ea021ff9397",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_zt3gOZ2Nu",

  aws_user_pools_web_client_id: "4kf26chr0acga63b7bjade31mm",

  oauth: {},
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_org_id: "EXCELSOFT",
  aws_cloudfront_url: "https://www.excelearn.enhanzed.com/",
  aws_cloudfront_lurl: "https://www.excelearn.enhanzed.com",
  DOMAIN: "www.excelearn.enhanzed.com",
  aws_api_name: "EN-PLATFORM",
  aws_api_endpoint:
    "https://97ozlmksfh.execute-api.us-east-1.amazonaws.com/DEV9872",
  aws_cloud_logic_custom_name: "EN-PLATFORM",
  aws_cloud_logic_custom: [
    {
      id: "97ozlmksfh",
      name: "EN-PLATFORM",
      description: "",
      endpoint:
        "https://97ozlmksfh.execute-api.us-east-1.amazonaws.com/DEV9872",
      region: "us-east-1",
    },
  ],
  auth_url: "https://devexcelsoft.auth.us-east-1.amazoncognito.com/",
};

export default nseDev;
