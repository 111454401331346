import React from "react";
import Header from "../../../components/Header/Header";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory, Link } from "react-router-dom";
import { incrementByAmount } from "../../../redux/Myprogram/MyProgramSlice";

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import CardLarge from "../../../components/Cards/CardLarge/CardLarge";
import { useEffect } from "react";
import config from "../../../aws-exports";

export default function MyPrograms() {
  const { viewAllArr, finalArr } = useSelector((state) => state.home);
  // console.log(JSON.stringify(viewAllArr, null, 2));
  let userDetails = useSelector(authData);
  const history = useHistory();
  const dispatch = useDispatch();
  const myTopics = userDetails.myTopics;
  console.log(myTopics);
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  const courseView = (ObjKey) => {
    const myProgramObjList = Object.keys(myTopics).map((key) => {
      return myTopics[ObjKey];
    });

    let ProgramObjList = myProgramObjList[0];
    console.log(ProgramObjList);
    let finalarr = userDetails.finalArr;
    finalarr = JSON.parse(JSON.stringify(finalarr));

    if (ProgramObjList.length > 0) {
      for (var k = 0; k < ProgramObjList.length; k++) {
        for (var l = 0; l < finalarr.length; l++) {
          if (finalarr[l].pid == ProgramObjList[k].pid) {
            for (var j = 0; j < finalarr[l].courses.length; j++) {
              if (finalarr[l].courses[j].tid == ProgramObjList[k].tid) {
                finalarr[l].courses[j].tp = ProgramObjList[k].tp;
                finalarr[l].courses[j].cobj = ProgramObjList[k].cobj;
                finalarr[l].courses[j].tobj = ProgramObjList[k].tobj;
              }
            }
          }
        }
        if (k == ProgramObjList.length - 1) {
          for (let item of finalarr) {
            if (item.pid == ObjKey) {
              ProgramObjList = item.courses;
              ProgramObjList = ProgramObjList.slice()
                .sort((a, b) => b.sqno - a.sqno)
                .reverse();
            }
          }
        }
      }
    }
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "Home",
      programName: ProgramObjList[0].pname,
      programId: ProgramObjList[0].pid,
      viewAll: true,
      viewAllRoute: "/viewAllMyPrograms",
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`courseview/${ObjKey}`);
    dispatch(incrementByAmount(ProgramObjList));
  };

  const handleLocation = (pid, pname) => {
    let breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "Home",
      programName: pname,
      viewAll: true,
      viewAllRoute: "/viewAllMyPrograms",
    };

    // dispatch(awsSignIn(breadcrumb));
  };

  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="large" style={{ fill: "#1974b5" }} />
        }
        aria-label="breadcrumb"
        style={{ marginTop: "2rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
        >
          Home
        </Link>
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          View all
        </Link>
      </Breadcrumbs>
      <div className="flex-between">
        <div className="flex-container"></div>
      </div>
      <div className="featureList">
        {viewAllArr.map(
          ({
            pname,
            pid,
            noofnuggetsm,
            pcount,
            starAvg,
            cobj,
            tobj,
            ccount,
          }) => (
            <div
              style={{ marginBottom: "2rem" }}
              onClick={() => {
                courseView(pid);
                handleLocation(pid, pname);
              }}
              key={pid + "abd"}
            >
              <CardLarge
                ttitle={pname}
                pid={pid}
                pcount={true}
                course={ccount}
                cobj={cobj}
                tobj={tobj}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}
