import React, { useState, useEffect } from "react";

import CardSmall from "../../components/Cards/CardSmall/CardSmall";

import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";

import config from "../../aws-exports";

// import FeatureContentCarousel from "../../components/Carousel/FeatureContentCarousel/FeatureContentCarousel";
import RecentlyViewedCarousel from "../../components/Carousel/RecentlyViewedCarousel/RecentlyViewedCarousel";
// import ContinueLearningCarousel from "../../components/Carousel/ContinueLearningCarousel/ContinueLearningCarousel";
// import CompletedCourseCarousel from "../../components/Carousel/CompletedCourseCarousel/CompletedCourseCarousel";
import { useQuery } from "react-query";
import { API } from "aws-amplify";
import Carousel from "../../components/Carousel/Carousel";
import Loading from "../../components/Loading/Loading";
import { motion } from "framer-motion";
import "../../../src/font/NunitoSans-Regular.ttf";

const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
  exit: {
    x: "-100vw",
    transition: { ease: "easeInOut" },
  },
};

function LandingPage() {
  let userDetails = useSelector(authData);
  const [continueLearning, setContinueLearning] = useState([]);
  const [completed, setCompleted] = useState([]);

  const { isLoading, error, data } = useQuery(
    ["getMyTopics", userDetails?.uData?.ur_id],
    async () => {
      const bodyParams = {
        body: {
          schema: config.schema,
          ur_id: userDetails?.uData?.ur_id,
          tenant: userDetails?.locale,
        },
        header: {
          "Contant-Type": "application/json",
        },
      };

      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getMyTopics",
          bodyParams
        );
        const { myTopics, toptopics } = response;

        if (myTopics || toptopics) {
          const completedCourse = myTopics.filter((top) => {
            top.ref_type = 1;
            if (
              top.comp_date !== null &&
              (top.pid === undefined || top.pid === null || top.pid === 0)
            )
              return true;

            return false;
          });
          const continueLearning = myTopics.filter((top) => {
            top.ref_type = 1;
            if (top.comp_date === null) return true;
            return false;
          });

          return { completedCourse, continueLearning, myTopics, toptopics };
        }
      } catch (err) {
        console.error(err);
      }
    }
  );

  console.log(userDetails);
  useEffect(() => {
    if (!userDetails) {
      if (config.aws_org_id == "AXIATA") {
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    }
    if (data) {
      setContinueLearning(data.continueLearning);
      setCompleted(data.completedCourse);
    }
    console.log(data);
  }, [data]);

  return (
    <motion.div
    // variants={containerVarients}
    // initial="hidden"
    // animate="visible"
    // exit="exit"
    >
      {data?.myTopics.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            fontFamily: `myfontregular`,
          }}
        >
          {/* <FeatureContentCarousel /> */}
          {/* <RecentlyViewedCarousel /> */}
          {data?.toptopics && data?.toptopics.length > 0 && (
            <Carousel
              heading="Featured Courses"
              cardData={data?.toptopics}
              loading={isLoading}
            />
          )}
          <CardSmall />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            fontFamily: `myfontregular`,
          }}
        >
          {data?.continueLearning && data?.continueLearning.length > 0 && (
            <Carousel
              heading="Continue Learning"
              cardData={continueLearning}
              setData={setContinueLearning}
              loading={isLoading}
            />
          )}

          {data?.toptopics && data?.toptopics.length > 0 && (
            <Carousel
              heading="Featured Courses"
              cardData={data?.toptopics}
              loading={isLoading}
            />
          )}
          {/* <FeatureContentCarousel /> */}

          {!isLoading && <RecentlyViewedCarousel />}

          {data?.completedCourse && data?.completedCourse.length > 0 && (
            <Carousel
              heading="Completed Courses"
              cardData={completed}
              setData={setCompleted}
              loading={isLoading}
            />
          )}

          <CardSmall heading="All Topics" />
        </div>
      )}
    </motion.div>
  );
}

export default LandingPage;
