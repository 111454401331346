import { Box } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";

import { API, Auth } from "aws-amplify";

import { awsSignIn } from "../../redux/auth/authSlice";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { CircularProgress, Typography } from "@material-ui/core";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
  SignInAndSignUpValues,
} from "./SignInAndSignUp";

import { useHistory, useLocation } from "react-router-dom";
import CustomInput from "../CustomInput/CustomInput";
import "react-phone-number-input/style.css";

import { useForm } from "../../Hooks/useForm";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
// import ReactCodeInput from "react-verification-code-input";
import { Theme } from "../../aws-exports";
import { useDispatch, useSelector } from "react-redux";
import validate from "./SignInSignUpValidationRules/SignInValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import { HeadingPrimary } from "../Styles/Heading";
import Swal from "sweetalert2";
import "./phoneInput.styles.css";
import config from "../../aws-exports";
import validateResetPasswordVerification, {
  validateResetPassword,
} from "./SignInSignUpValidationRules/ResetPasswordValidation";
import validateResetPasswordEmail from "./SignInSignUpValidationRules/ResetPasswordValidationEmail";
// import { SettingsBrightnessOutlined } from "@mui/icons-material";
import { useQueryParams } from "../../Hooks/useQueryParams";

const SignIn = () => {
  const history = useHistory();
  const query = useQueryParams();
  const location = useLocation();

  // const sessionData = useSelector((state) => state.sessionData.value);
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    setValues,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const { setSignUp, setSignIn, signIn, signUp } = useContext(
    SignInAndSignUpValues
  );

  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPasswordOpenFirst, setResetPasswordOpenFirst] = useState(false);
  const [signInForm, setSignInForm] = useState(true);
  const [emailVerification, setEmailVerification] = useState(false);

  // const [verificationCode, setVerificationCode] = useState("");

  const [errors, setErrors] = useState({});

  const [btnLoading, setBtnLoading] = useState(false);
  const [emailVerifyLoader, setEmailVerifyLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);

  // useEffect(() => {
  //   if(config.aws_org_id == 'EXCELSOFT'){
  //     console.log("configoid==",config.aws_org_id);
  //     setSignInForm(false);
  //     checkUserLogin();
  //   } else {
  //     setSignInForm(false);
  //     checkUserLogin();
  //   }
  // }, []);

  const analyticsAPI = async (data) => {
    //console.log("lda ", datagot.attributes.sub);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        id: data.id,
        eventtype: "AuthenticatedViaCognito",
        email: data.sub,
        emailid: data.email,
        name: data.name,
        tenant: data.tenant,
        ur_id: data.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body, null, 2));

    try {
      const res = await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      console.log(res);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(Object.keys(validate(values)).length);
    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validate(values));
      return;
    } else {
      setBtnLoading(true);
      try {
        const user = await Auth.signIn(values.username, values.password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setSignInForm(false);
          setResetPasswordOpenFirst(true);
          setSignUp(false);
          setValues({ ...values, showPassword: false });
          setForgotPassword(false);
          setBtnLoading(false);
        }
        const info = await Auth.currentUserInfo();
        let userData = info.attributes;
        userData.username = info.username;
        userData.id = info.id;

        console.log(JSON.stringify(userData));
        // analyticsAPI(userData);

        userData.location = {};
        getUserDetails(userData);
      } catch (error) {
        console.error(error.code);
        setBtnLoading(false);
        setErrors(cognitoValidation(error));
      }
    }
  };
  const getUserDetails = async (userdata) => {
    console.log(userdata);
    const bodyParam = {
      body: {
        emailid: userdata.email,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserDetails",
        bodyParam
      );
      console.log("signin===", response, bodyParam.body);
      // if (response.code === 200 && config.aws_org_id === "NSE") {
      //   if (response.data != null) {
      //     userdata.uData = response.data;
      //     // analyticsWebApp(userdata);
      //     history.push("/home");
      //     setBtnLoading(false);
      //     dispatch(awsSignIn(userdata));
      //   } else {
      //     let error = {};
      //     error.code = "UserNotFoundException";
      //     setErrors(cognitoValidation(error));
      //     setBtnLoading(false);
      //   }
      // }
      if (response) {
        userdata.uData = response.body[0];
        analyticsWebApp(userdata);
      }
    } catch (err) {
      console.error(err);
    }
  };
  async function analyticsWebApp(userDetails) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eventtype: "AuthenticatedViaCognito",
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        email: userDetails.username,
        name: userDetails.name,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        ur_id: userDetails.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };
    // bodyParam.body.emailid = userDetails.emailid;
    console.log("analytic==" + JSON.stringify(bodyParam.body));

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      setBtnLoading(false);
      dispatch(awsSignIn(userDetails));
      // var obj = JSON.parse(localStorage.getItem("deeplink"));

      let id = query.get("id");
      if (location.pathname === "/course" && id) {
        history.push(`/course?id=${id}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.push("/home");
      }
      // localStorage.removeItem("deeplink");
      // if (obj !== undefined && obj.type == "course") {
      //   history.push(`/course?id=${obj.tid}`);
      // } else if (obj !== undefined && obj.type == "content") {
      //   history.push(`/content?id=${obj.tid}`);
      // } else {
      //   history.push("/home");
      // }
    } catch (err) {
      history.push("/home");
      // bodyParam.body.err = err;
      // const response2 = await API.post(
      //   config.aws_cloud_logic_custom_name,
      //   "/zTestSes",
      //   bodyParam
      // );
    }

    // window.location.href = homeurl;
  }
  const resetPasswordFC = async (event) => {
    console.log(validateResetPassword(values));
    event.preventDefault();
    if (Object.keys(validateResetPassword(values)).length !== 0) {
      setErrors(validateResetPassword(values));
      return;
    } else {
      try {
        setResetPasswordLoader(true);
        await Auth.signIn(values.username, values.password).then((users) => {
          if (users.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(users, values.confirmPassword);
          }
        });
        setResetPasswordLoader(false);
        Swal.fire({
          title: "Success",
          text: "Password Changed Successfully",
          icon: "success",
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        }).then((willDelete) => {
          if (willDelete) {
            setSignInForm(true);
            setResetPasswordOpenFirst(false);
          }
        });
      } catch (error) {
        console.error(error);
        setErrors(cognitoValidation(error));
      }
    }
  };

  const passwordResetFC = async (event) => {
    event.preventDefault();
    if (Object.keys(validateResetPasswordEmail(values)).length !== 0) {
      setErrors(validateResetPasswordEmail(values));
      return;
    } else {
      if (Object.keys(validateResetPasswordVerification(values)).length !== 0) {
        setErrors(validateResetPasswordVerification(values));
        return;
      } else {
        setResetPasswordLoader(true);
        try {
          await Auth.forgotPasswordSubmit(
            values.verificationEmail,
            values.verificationCode,
            values.confirmPassword
          );
          setResetPasswordLoader(false);
          Swal.fire({
            title: "Success",
            text: "Password Changed Successfully",
            icon: "success",
            confirmButtonColor: Theme.color.primary,
            confirmButtonText: "Ok",
          }).then((willDelete) => {
            if (willDelete.isConfirmed) {
              setSignIn(true);
              setSignInForm(true);
              setForgotPassword(false);
              setSignUp(false);
            }
          });

          // alert("Changed password Successfully");
        } catch (error) {
          setResetPasswordLoader(false);
          setErrors(cognitoValidation(error));
          console.error(error);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {signInForm && (
        <React.Fragment>
          <Box style={{ textAlign: "center" }}>
            <HeadingPrimary>Login to your account</HeadingPrimary>
          </Box>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <div style={inputBoxStyle}>
              <CustomInput
                id="input-username"
                placeholder="Username"
                type="email"
                value={values.username || ""}
                error={errors.email}
                onChange={handleChange("username")}
                style={{ fontFamily: "myfontregular" }}
              >
                <PersonOutlineOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </div>

            <div style={inputBoxStyle}>
              <CustomInput
                id="input-Password"
                placeholder="Password"
                type="password"
                inputValues={values}
                value={values.password || ""}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("password")}
                error={errors.password}
                style={{ fontFamily: `myfontregular` }}
              >
                <LockOutlinedIcon style={inputIconStyle} />
              </CustomInput>
              <Typography
                onClick={() => {
                  setForgotPassword(true);
                  setSignInForm(false);
                  setErrors({});
                  setValues({});
                }}
                variant="caption"
                display="block"
                gutterBottom
                style={{
                  fontSize: "1.4rem",
                  textAlign: "right",
                  cursor: "pointer",
                  fontFamily: `myfontregular`,
                }}
              >
                Forgot password?
              </Typography>
            </div>

            <div style={{ textAlign: "center" }}>
              {btnLoading ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={10} />
                </CustomButton>
              ) : (
                <CustomButton
                  type="submit"
                  primary
                  className="btnlogin"
                  style={{ fontFamily: `myfontregular` }}
                >
                  Login
                </CustomButton>
              )}
            </div>
          </form>
        </React.Fragment>
      )}

      {forgotPassword && (
        <ForgotPassword
          emailVerifyLoader={emailVerifyLoader}
          setEmailVerifyLoader={setEmailVerifyLoader}
          setEmailVerification={setEmailVerification}
          passwordResetFC={passwordResetFC}
          setForgotPassword={setForgotPassword}
          setResetPasswordOpenFirst={setResetPasswordOpenFirst}
          values={values}
          handleChange={handleChange}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
          setSignIn={setSignIn}
          setSignUp={setSignUp}
          signIn={signIn}
          signUp={signUp}
          forgotPassword={forgotPassword}
          setSignInForm={setSignInForm}
        />
      )}

      {resetPasswordOpenFirst && (
        <ResetPassword
          resetPasswordFC={resetPasswordFC}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
          setSignIn={setSignIn}
          setSignUp={setSignUp}
          signIn={signIn}
          signUp={signUp}
        />
      )}
    </React.Fragment>
  );
};

export default SignIn;
