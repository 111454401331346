import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/styles";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import {
  Button,
  CardMedia,
  Typography,
  Fade,
  CircularProgress,
  Modal,
  Box,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  TextField,
  TextareaAutosize,
  Link,
  createStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import { API, Auth } from "aws-amplify";
import ReactPlayer from "react-player";
import TimerIcon from "@material-ui/icons/Timer";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useHistory } from "react-router-dom";
import Timerview from "../../components/CircularProgress/Timerview";
import Rating from "@material-ui/lab/Rating";
import { Offline, Online } from "react-detect-offline";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Tippy from "@tippyjs/react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
//import blackimg from "../../assets/media/black.png";
import { saveAs } from "file-saver";
import axios from "axios";
// import b64toBlob from 'b64-to-blob';
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import FormativeQuiz from "../Quiz/FormativeQuiz";
import SummativeQuiz from "../Quiz/SummativeQuiz";
import CertificatePopUp from "./CertificatePopUp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
//import { SCORM } from "pipwerks-scorm-api-wrapper";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.73)",
    paddingTop: "1%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
    height: "80%",
    borderRadius: ".8rem",
    overflowX: "hidden",
    overflowY: "auto",
    "@media (max-width: 500px)": {
      width: "90%!important",
    },
    "@media (max-width: 770px)": {
      padding: "1rem",
    },
  },
  paper1: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "400px",
    borderRadius: ".8rem",
    "@media (max-width: 430px)": {
      width: "90%",
    },
  },
  ratingroot: {
    //width: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "1.5rem",
    "& > span": {
      margin: 0,
    },
  },
  quizroot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  MuiGriditem: {
    padding: "0 12px",
  },
  quizpaper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  certcover: {
    height: "100%",
  },
  overlay: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,0,0,.2), rgba(0,0,0,.2))",
    width: "1100px",
    height: "660px",
  },
  label: {
    "& .MuiTypography-body1": {
      fontSize: "1.3rem",
      color: "#555555",
      fontWeight: "500",
      overflow: "hidden !important",
    },
  },
  labelSurvey: {
    "& .MuiTypography-body1": {
      fontSize: "1.25rem",
      color: "#000",
      fontWeight: "600",
      overflow: "hidden !important",
    },
    "& .MuiTypography-body1::after": {
      content: "*",
      color: "red",
    },
  },
  label2: {
    "&.MuiFormControlLabel-root": {
      paddingLeft: "30px",
      display: "flex",
      flexDirection: "column",
    },
    "&.MuiTypography-body1": {
      fontSize: "1.3rem",
    },
  },
  contentType: {
    "& .PrivateSwitchBase-input-118": {
      "& ::after": {
        content: "hi !important",
      },
    },
  },
  barResponsive: {
    height: "25px",
    borderRight: "2px solid #000",
    marginLeft: "2rem",
    "@media (max-width: 500px)": {
      display: "none",
    },
  },
  infoResponsive: {
    display: "inline-block",
    height: "inherit",
    width: "50px",
    padding: "0 2rem",
    "@media (max-width: 500px)": {
      padding: "0rem 0.5rem",
    },
  },
  closeResponsive: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    marginRight: "-20px",
    "@media (max-width: 770px)": {
      marginRight: 0,
    },
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainHeader: {
    display: "flex",
    flexWrap: "wrap",
    fontWeight: "600",
    alignItems: "center",
    paddingRight: "4rem",
    //marginBottom: "1rem",
    //marginTop:"2rem",
    // '@media (max-width: 500px)': {
    //   width: '60%',
    // },
    // '@media (max-width: 365px)': {
    //   width: '55%',
    // },
  },
  documentSection: {
    marginTop: "10px",
    width: "95%",
    height: "-webkit-fill-available",
    position: "absolute",
    marginBottom: "6rem",
    //paddingBottom: '20px',
    "@media (max-width: 330px)": {
      width: "100%",
    },
  },
  downloadIcon: {
    position: "absolute",
    right: "55px",
    //top: "2%",
    background: "transparent",
    border: "none",
    height: "2.3rem",
    width: "2.3rem",
    marginLeft: "2rem",
    cursor: "pointer",
    //marginTop: ".5rem",
    zIndex: "10000",
    "@media (max-width: 400px)": {
      right: "40px",
    },
  },
  separator: {
    height: "27px",
    borderRight: "2px solid #333333",
    marginLeft: "2rem",
    "@media (max-width: 500px)": {
      display: "none",
    },
  },
  videoContainer: {
    display: "contents",
  },
  videoInnerContainer: {
    width: "90%!important",
    height: "88%!important",
    margin: "2rem auto 0rem!important",
    "@media (max-width: 700px)": {
      width: "100%!important",
      height: "70%!important",
    },
  },
}));

const styles = () =>
  createStyles({
    h: {
      "&::before": {
        content: '"some content"',
        display: "block",
        height: 60,
        marginTop: -60,
      },
    },
  });

const GreenRadio = withStyles({
  root: {
    color: "#1C6D9C",
    overflow: "hidden",
    "&$checked": {
      color: "#1C6D9C",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StyledButton = withStyles({
  root: {
    background:
      "linear-gradient(45deg, var(--color-button-background) 90%,var(--color-button-background) 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    fontSize: "16px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);
const StyledButtonQuiz = withStyles({
  root: {
    background:
      "linear-gradient(45deg, var(--color-button-background) 90%, var(--color-button-background) 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 50px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    marginTop: "3rem",
    fontSize: "16px",
    marginBottom: "3rem",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export function TransitionsModal({
  setOpen,
  open,
  setCourseDetails,
  courseDetails,
  setCurNugget,
  setCurObject,
  setCurObjIndex,
  setCurNuggetId,
  setCurNugIndex,
  setCertificate,
  setProgress,
  setMarkComplete,
  setStartTime,
  setTpProgress,
  setcourseResume,
  updateCourseAnalytics,
  scormpPause,
}) {
  const classes = useStyles();
  // const classesse = styles();

  const history = useHistory();
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [nextTopics, setNextTopics] = useState({});
  const scroll = useRef();
  const scrollT = useRef();

  const handleOpen = () => {
    if (
      courseDetails.prerequisites == false ||
      courseDetails.prerequisites === "false"
    ) {
      startcourse();
    } else if (
      (courseDetails.prerequisites === true ||
        courseDetails.prerequisites === "true") &&
      courseDetails.prerequisitescourses?.length > 0
    ) {
      let preflg = 0;
      if (courseDetails.comp_topics !== undefined) {
        for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
          if (
            courseDetails.comp_topics !== undefined &&
            !courseDetails.comp_topics.includes(
              courseDetails.prerequisitescourses[i].tid
            )
          ) {
            nextTopics.title = courseDetails.prerequisitescourses[i].ttitle;
            nextTopics.tid = courseDetails.prerequisitescourses[i].tid;
            setNextTopics(nextTopics);
            preflg = 1;
            break;
          }
        }
      } else {
        nextTopics.title = courseDetails.prerequisitescourses[0].ttitle;
        nextTopics.tid = courseDetails.prerequisitescourses[0].tid;
        setNextTopics(nextTopics);
        preflg = 1;
      }
      if (courseDetails.prerequisitescourses === undefined && preflg === 0) {
        preflg = 1;
      }
      if (preflg === 0) {
        startcourse();
      } else {
        var ptag = "";
        if (
          courseDetails.prerequisitestype !== undefined &&
          courseDetails.prerequisitestype == "Required"
        ) {
          for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
            ptag =
              ptag +
              '<p class="classtags">' +
              courseDetails.prerequisitescourses[i].ttitle +
              "</p>";
            if (i === courseDetails.prerequisitescourses.length - 1) {
              Swal.fire(
                {
                  html:
                    '<p class="classtags1"> Please complete the below prerequisite course in order to enable you to start ' +
                    courseDetails.ttitle +
                    "</p>" +
                    "<br><br>",
                  icon: `warning`,
                  showConfirmButton: true,
                  denyButtonText: nextTopics.title,
                  confirmButtonText: "Cancel",
                  confirmButtonClass: "confirmClass",
                  buttonsStyling: false,
                  showDenyButton: true,
                  customClass: {
                    cancelButton: "order-2 ",
                    confirmButton: "order-3",
                    denyButton: "order-1 coursebtn",
                  },
                },
                function() {}
              ).then((result) => {
                if (result.isConfirmed) {
                } else if (result.isDenied) {
                  if (nextTopics.tid !== undefined) {
                    let breadcrumb = { ...userDetails };
                    breadcrumb.location = {
                      from: breadcrumb.location.from,
                      courseName: nextTopics.title,
                      prevLoc: breadcrumb.location.prevLoc,
                      programName: breadcrumb.location.programName,
                      topicName: breadcrumb.location.topicName,
                      topicId: breadcrumb.location.topicId,
                      viewAll: breadcrumb.location.viewAll,
                      programId: breadcrumb.location.programId,
                      cid: breadcrumb.location.cid,
                    };
                    dispatch(awsSignIn(breadcrumb));
                    history.push(`/course?id=${nextTopics.tid}`);
                    window.location.reload();
                  }
                }
              });
            }
          }
        } else if (
          courseDetails.prerequisitestype !== undefined &&
          courseDetails.prerequisitestype == "Suggestion"
        ) {
          for (let i = 0; i < courseDetails.prerequisitescourses.length; i++) {
            ptag =
              ptag +
              '<p class="classtags">' +
              courseDetails.prerequisitescourses[i].ttitle +
              "</p>";
            if (i === courseDetails.prerequisitescourses.length - 1) {
              Swal.fire({
                html:
                  ptag +
                  "</br>" +
                  "</br>" +
                  '<p style="color:#484848;">These are the recommended prerequisites for this course. Click "Cancel" below to start a prerequisite course, or click "OK to continue with this course.</p>',
                icon: `warning`,
                width: "400px",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                customClass: "sweetalert-lgs",
                confirmButtonClass: "confirmClass",
                buttonsStyling: false,
                cancelButtonClass: "cancelClass",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.value) {
                  syncUserProgress();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
              });
            }
          }
        } else {
          startcourse();
        }
      }
    } else {
      startcourse();
    }
  };

  function startcourse() {
    Swal.fire({
      title: "Get started with the course?",
      showDenyButton: true,
      showCancelButton: false,
      reverseButtons: true,
      icon: `warning`,
      denyButtonText: `No`,
      confirmButtonText: `Yes`,
      allowOutsideClick: false,
      customClass: {
        cancelButton: "cancelButton",
        confirmButton: "confirmButton",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        syncUserProgress();
      } else if (result.isDenied) {
      }
    });
  }

  async function syncUserProgress() {
    //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;

    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        logintype: "Cognito",
        status: "new",
        key: courseDetails.tid,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        version: courseDetails.version,
        tnuggets: courseDetails.noofnuggets,
        nav: courseDetails.freenavigation,
        email: userDetails.username,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        pid: courseDetails.pid,
        vtid: courseDetails.vtid,
        tid: courseDetails.tid,
        sub_date: 1,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );

      updateLocalUserData(courseDetails);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
  function updateLocalUserData(courseDetails) {
    courseDetails.userdataset.userdata = true;
    courseDetails.userdataset.sd = Math.round(new Date().getTime() / 1000);
    var tp = "0";
    if (courseDetails.noofnuggets > 0) {
      tp = "1";
      for (var w = 0; w < courseDetails.noofnuggets; w++) {
        if (courseDetails.freenavigation) {
          tp = tp + "-" + "2";
        } else if (w == 0) {
          tp = tp + "-" + "1";
        } else {
          tp = tp + "-" + "0";
        }
      }
    }

    courseDetails.userdataset.tp = tp;
    courseDetails.userdataset.cobj = 0;
    courseDetails.userdataset.cvid = 0;
    courseDetails.userdataset.cdoc = 0;
    courseDetails.userdataset.cfq = 0;
    courseDetails.userdataset.csq = 0;
    courseDetails.userdataset.casmt = 0;
    courseDetails.nresume = {};
    courseDetails.nresume.rnugget = courseDetails.nuggets[0].nid;
    courseDetails.nuggets[0].objects[0].op = 1;
    if (courseDetails.freenavigation) {
      for (let index = 0; index < courseDetails.nuggets.length; index++) {
        if (courseDetails.nuggets[index].objects !== undefined) {
          for (
            let oindex = 0;
            oindex < courseDetails.nuggets[index].objects.length;
            oindex++
          ) {
            courseDetails.nuggets[index].objects[oindex].op = 2;
            if (
              index == courseDetails.nuggets.length - 1 &&
              oindex == courseDetails.nuggets[index].objects.length - 1
            ) {
              courseDetails.nuggets[index].objects[oindex].op = 1;
            }
          }
        }
      }
    }
    if (courseDetails.nuggets[0].objects[0].otype == "scorm") {
      scormpPause.current = false;
      var obj2 = {};
      obj2.ur_id = userDetails.uData?.ur_id;
      obj2.cid = courseDetails.tid;
      obj2.tid = courseDetails.tid;
      obj2.objid = courseDetails.nuggets[0].objects[0].oid;
      obj2.atype = 0;
      obj2.schema = config.schema;
      obj2.curObjIndex = 0;
      obj2.objlength = courseDetails.nuggets[0].objects.length - 1;
      obj2.curNugIndex = 0;
      obj2.nuggetlength = courseDetails.nuggets.length - 1;
      window.cudetails = obj2;
    }
    courseDetails.nresume.robject = 0;
    setTpProgress(tp);
    setCurNugget(courseDetails.nuggets[0]);
    setCurObject(courseDetails.nuggets[0].objects[0]);
    setCurObjIndex(0);
    setCurNuggetId(courseDetails.nuggets[0].nid);
    setCurNugIndex(0);
    setCertificate(false);
    setProgress(Number(courseDetails.nuggets[0].objects[0].oduration));
    setMarkComplete(false);
    setStartTime(new Date().getTime());
    setCourseDetails(courseDetails);
    setcourseResume(courseDetails.nresume);
    setOpen(true);
    setLoading(false);
  }

  return (
    <div>
      {loading && (
        <CircularProgress
          className="spinnerStyle"
          style={{ position: "absolute !important", left: "50%", top: "50%" }}
        />
      )}
      <StyledButton onClick={() => handleOpen()}>Start course</StyledButton>
    </div>
  );
}
const defaultTippyProps = {
  // animation: "shift-away-subtle",
  // arrow: true,
  interactive: true,
  interactiveBorder: 0,
  placement: "bottom-end",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 400,
  theme: "light",
  distance: 24,
};
export function ObjectPopUp({
  setOpen,
  open,
  setCourseDetails,
  courseDetails,
  curObject,
  setCurObject,
  curNugget,
  setCurNugget,
  curObjIndex,
  setCurObjIndex,
  curNuggetId,
  setCurNuggetId,
  progress,
  setProgress,
  markComplete,
  setMarkComplete,
  curNugIndex,
  setCurNugIndex,
  startTime,
  setStartTime,
  courseResume,
  setcourseResume,
  remaining,
  setRemaining,
  totalProgress,
  setTotalProgress,
  tpProgress,
  setTpProgress,
  perScore,
  setPerScore,
  totalScore,
  setTotalScore,
  showScore,
  setShowScore,
  startQuiz,
  setStartQuiz,
  loading,
  setLoading,
  isLoading,
  setIsLoading,
  certificate,
  setCertificate,
  updateCourseAnalytics,
  scormpPause,
  getTopicDetails,
  lastobject,
}) {
  const classes = useStyles();
  const handle = useFullScreenHandle();
  const classesse = styles();
  const scroll = useRef();
  const history = useHistory();
  let userDetails = useSelector(authData);

  const reactplayer = useRef(null);
  const [cutOff, setCutOff] = useState(0);

  const [timerPalyPause, setTimerPalyPause] = useState("pause");
  const [objStatus, setObjStatus] = useState(false);

  const [tryBtnShow, setTryBtnShow] = useState(false);
  const [value, setValue] = React.useState("");
  const [ansShow, setAnsShow] = useState(false);

  const [scoreJson, setScoreJson] = useState({});

  const [certificateList, setCertificateList] = useState({});
  const [error, setError] = useState("");
  const [surveyError, setSurveyError] = useState("");

  const [question, setQuestion] = useState([]);

  const closePopUp = useRef(false);
  const retake = useRef(false);
  //const scormnextbtn = useRef(false);
  const [scormnExtBtn, setScormnExtBtn] = useState(false);
  const nextCourseref = useRef(false);
  let questionsRef = useRef();
  const dispatch = useDispatch();
  let src = `${
    config.aws_cloudfront_url
  }${config.aws_org_id.toLowerCase()}-resources/certificate/`;
  const handleModalClose = () => {
    let getstatus = "";
    try {
      getstatus = window.API.LMSGetValue("cmi.core.lesson_status");
    } catch (error) {
      try {
        getstatus = window.API_1484_11.GetValue("cmi.completion_status");
      } catch (error) {}
    }

    if (
      !certificate &&
      curObject.otype == "scorm" &&
      (courseDetails.certification == "true" ||
        courseDetails.certification === true) &&
      (getstatus === "completed" || getstatus === "passed") &&
      courseDetails.nuggets[curNugIndex].objects[curObjIndex].op != 2 &&
      curObjIndex == courseDetails.nuggets[curNugIndex].objects.length - 1 &&
      curNugIndex == courseDetails.nuggets.length - 1
    ) {
      syncUserCertificate("");
    } else {
      if (certificate) {
        nextcourse();
      }
      setTimerPalyPause("pause");
      setStartQuiz(false);
      questionsRef.current = [];
      setQuestion([]);
      setIsLoading(false);
      if (
        curObject.otype != "quiz" &&
        curObject.otype != "feedback" &&
        curObject.op == 1 &&
        courseDetails.ctype == "enforced" &&
        progress > 0
      ) {
        setOpen(false);
        updateResumeTime();
      } else if (
        curObject.otype != "quiz" &&
        curObject.otype != "feedback" &&
        curObject.op == 1 &&
        courseDetails.ctype == "enforced" &&
        progress <= 0
      ) {
        closePopUp.current = true;
        syncUserProgress(1);
      } else if (
        curObject.otype != "quiz" &&
        curObject.otype != "feedback" &&
        curObject.op == 1 &&
        courseDetails.ctype == "regular"
      ) {
        closePopUp.current = true;
        syncUserProgress(1);
      } else {
        setOpen(false);
      }
    }
    try {
      window.API.LMSTerminate();
    } catch (error) {
      try {
        window.API.Terminate();
      } catch (error) {}
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!scormpPause.current) {
        try {
          if (
            window.API.LMSGetValue("cmi.core.lesson_status") === "completed" ||
            window.API.LMSGetValue("cmi.core.lesson_status") === "passed"
          ) {
            if (
              curObjIndex ==
                courseDetails.nuggets[curNugIndex].objects.length - 1 &&
              curNugIndex == courseDetails.nuggets.length - 1
            ) {
              updateCourseAnalytics("completed");
              let rmtime = { ...remaining };
              rmtime.mremaining = 0;
              rmtime.tremaining = 0;
              setRemaining(rmtime);
              var tp = "2";
              let ttp = 0;
              for (var d = 0; d < courseDetails.nuggets.length; d++) {
                tp = tp + "-" + "2";
                for (
                  let index = 0;
                  index < courseDetails.nuggets[d]?.objects.length;
                  index++
                ) {
                  ttp++;
                }
              }
              setTotalProgress(ttp);
              setTpProgress(tp);
              console.log("==allok==");
            } else {
              setScormnExtBtn(true);
            }
            updateUserScormAnalytics();
            scormpPause.current = true;
          }
        } catch (error) {}
        try {
          console.log("s", window.API.LMSGetValue("cmi.completion_status"));
          console.log(
            "s1",
            window.API_1484_11.GetValue("cmi.completion_status")
          );
          if (
            window.API_1484_11.GetValue("cmi.completion_status") ===
              "completed" ||
            window.API.LMSGetValue("cmi.completion_status") === "passed"
          ) {
            if (
              curObjIndex ==
                courseDetails.nuggets[curNugIndex].objects.length - 1 &&
              curNugIndex == courseDetails.nuggets.length - 1
            ) {
              updateCourseAnalytics("completed");
              let rmtime = { ...remaining };
              rmtime.mremaining = 0;
              rmtime.tremaining = 0;
              setRemaining(rmtime);
              var tp = "2";
              let ttp = 0;
              for (var d = 0; d < courseDetails.nuggets.length; d++) {
                tp = tp + "-" + "2";
                for (
                  let index = 0;
                  index < courseDetails.nuggets[d]?.objects.length;
                  index++
                ) {
                  ttp++;
                }
              }
              setTotalProgress(ttp);
              setTpProgress(tp);
              console.log("==allok==");
            } else {
              setScormnExtBtn(true);
            }
            updateUserScormAnalytics();
            scormpPause.current = true;
          }
        } catch (error) {}
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [scormpPause.current]);

  const handleModalOpen = async (nresume) => {
    if (navigator.onLine === true) {
      for (var k = 0; k < courseDetails.nuggets.length; k++) {
        let nflg = 0;
        for (var l = 0; l < courseDetails.nuggets[k].objects.length; l++) {
          if (
            courseDetails.nuggets[k].objects[l].op === 1 ||
            courseDetails.nuggets[k].objects[l].op === "1"
          ) {
            if (courseDetails.nuggets[k].objects[l].otype == "scorm") {
              scormpPause.current = false;
              var obj2 = {};
              obj2.ur_id = userDetails.uData?.ur_id;
              obj2.cid = courseDetails.tid;
              obj2.tid = courseDetails.tid;
              obj2.objid = courseDetails.nuggets[k].objects[l].oid;
              obj2.atype = 1;
              obj2.schema = config.schema;
              obj2.curObjIndex = curObjIndex;
              obj2.objlength = courseDetails.nuggets[k].objects.length - 1;
              obj2.curNugIndex = curNugIndex;
              obj2.nuggetlength = courseDetails.nuggets.length - 1;
              var tobj = JSON.stringify(obj2);

              var val = await GetApiCall(tobj, obj2);
              console.log("getresumeval", val);
              if (val) {
                setCurNugget(courseDetails.nuggets[k]);
                setCurObject(courseDetails.nuggets[k].objects[l]);
                setCurObjIndex(l);
                setCurNuggetId(courseDetails.nuggets[k].nid);
                setCurNugIndex(k);
                setMarkComplete(false);
                setStartTime(new Date().getTime());
                setProgress(
                  Number(courseDetails.nuggets[k].objects[l].oduration)
                );
                setOpen(true);
                setIsLoading(false);
                setLoading(false);
              }
            } else {
              setCurNugget(courseDetails.nuggets[k]);
              setCurObject(courseDetails.nuggets[k].objects[l]);
              setCurObjIndex(l);
              setCurNuggetId(courseDetails.nuggets[k].nid);
              setCurNugIndex(k);
              setMarkComplete(false);
              setStartTime(new Date().getTime());
              setProgress(
                Number(courseDetails.nuggets[k].objects[l].oduration)
              );
              setOpen(true);
              setIsLoading(false);
              setLoading(false);
            }

            nflg = 1;
            break;
          }
        }
        if (nflg === 1) {
          break;
        }
      }
    }
    //
  };
  async function GetApiCall(json, obj2) {
    let api = "";

    if (config.aws_org_id === "NSE") {
      api =
        "https://06cbde50ti.execute-api.ap-south-1.amazonaws.com/dev/getScormData";
    } else {
      api =
        "https://kqd29za2e4.execute-api.ap-south-1.amazonaws.com/EN938437/getScormData";
    }
    var dd = await fetch(api, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: json,
    })
      .then((response) => response.json())
      .then((data) => {
        window.nextbtn = "get";
        console.log("GetApiCall Success:", data.body);
        if (data?.body?.stime !== undefined && data?.body?.stime !== null) {
          //window.API.LMSSetValue("cmi.core.session_time",data.body.stime);
          obj2.stime = data.body.stime;
          console.log("session time set value done");
        }
        if (
          data?.body?.suspend_data !== undefined &&
          data?.body?.suspend_data !== null
        ) {
          //data["cmi.suspend_data"] = data.body.suspend_data;
          obj2.sdta = data.body.suspend_data;
          window.cudetails = obj2;

          //window.API.LMSSetValue("cmi.exit","suspend");
          //window.API.LMSSetValue("cmi.entry","resume");
          //  window.API.LMSSetValue("cmi.credit","credit");
          console.log("suspend data set value done");
        } else {
          obj2.sdta = "";
          window.cudetails = obj2;
        }
        if (data?.body?.op === 2) {
          window.cudetails.op = 2;
        }

        return true;
        //return data.body.suspend_data;
      })
      .catch((error) => {
        window.nextbtn = "get";
        console.error("LMSSetValue Error:", error);
        return true;
      });
    return dd;
  }

  function renderObjects() {
    let objType = curObject.otype;

    if (objType !== undefined) {
      switch (objType.toLowerCase()) {
        case "video":
          return VideoView(); // loadVideoView(curObject);
        case "audio":
          return AudioView(); // loadAudioView(curObject);
        case "pdf":
          return PdfView(); // loadMultimediaView(curObject);
        case "html":
          return WebView(); // loadMultimediaView(curObject);
        case "interactivity":
          return WebView(); // loadInteractivityView(curObject);
        case "scorm":
          return WebView(); // loadScormView(curObject);
        case "quiz":
          return tempView();
        case "feedback":
          return feedbackView();
        case "vimeo":
          return youtubeView(); // loadVimeoView(curObject);
        case "youtube":
          return youtubeView(); // loadYoutubeView(curObject);
        default:
          return null;
      }
    }
  }
  const playVideoHandler = () => {
    if (courseDetails.ctype == "enforced" && curObject.op == 1) {
      setTimerPalyPause("play");
      if (objStatus === false) {
        setObjStatus(true);
      }

      //setTimerSeconds(timval)
      // timersec = setInterval(() => {
      //     var min =  Math.floor(totalSec /60) ;
      //     var sec =  Math.floor(totalSec % 60) ;
      //    let tsec = totalSec;
      //      tsec--;
      //     setTotalSec(tsec);
      //     setTimerMinuts(min);
      //     setTimerSeconds(sec);
      //     if(totalSec <= 0){
      //       //setMarkComplete(true)
      //       clearInterval(timersec);
      //     }
      //   }, 1000);
      //   return () => {
      //     clearInterval(timersec);
      //   };
    }
    if (curObject.op == 2) {
      setMarkComplete(false);
    }
  };
  const pauseVideoHandler = () => {
    setProgress(progress);
    setTimerPalyPause("pause");
  };
  const onSeekings = (param) => {};
  function VideoView() {
    return (
      <div className={classes.videoContainer}>
        <ReactPlayer
          ref={(p) => {
            reactplayer.current = p;
          }}
          url={curObject.ourl}
          controls={true}
          className={classes.videoInnerContainer}
          onPlay={() => playVideoHandler()}
          onPause={() => pauseVideoHandler()}
          onSeek={(e) => onSeekings(e)}
          onDuration={() => {
            // alert(courseDetails.userdataset.tresume.time);
            if (
              curObject.op == 1 &&
              courseDetails.userdataset !== undefined &&
              courseDetails.userdataset.tresume !== undefined &&
              reactplayer.current !== null
            ) {
              if (courseDetails.userdataset.tresume.oid == curObject.oid) {
                reactplayer.current.seekTo(
                  courseDetails.userdataset.tresume.time
                );
                if (courseDetails.ctype == "enforced") {
                  setProgress(courseDetails.userdataset.tresume.obtime);
                }
              }
            }
          }}
          onBuffer={() => console.log("onBuffer")}
          onStart={() => console.log("onStart")}
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
          onContextMenu={(e) => e.preventDefault()}
          onEnded={() => {
            if (courseDetails.ctype == "regular" && curObject.op == 1) {
              setMarkComplete(true);
            } else if (curObject.op == 2) {
              setMarkComplete(true);
            } else if (courseDetails.ctype == "enforced" && curObject.op == 1) {
              setTimerPalyPause("play");
            }
          }}
        />
        <div className={markComplete && "overlay"}></div>
      </div>
    );
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CardMedia
            image={curObject.ourl}
            ref={(p) => {
              reactplayer.current = p;
            }}
            height="100%"
            component="audio"
            frameBorder={0}
            onPlay={() => playVideoHandler()}
            onPause={() => pauseVideoHandler()}
            onSeek={(e) => onSeekings(e)}
            onEnded={() => {
              if (courseDetails.ctype == "regular" && curObject.op == 1) {
                setMarkComplete(true);
              }
            }}
            controls
          />
          {/* <ReactPlayer
            url={curObject.ourl}
            controls={true}
            width='calc(100% - 15%)'
            height='calc(100% - 20px)'
            onPlay={() => playVideoHandler()}
            onPause={() => pauseVideoHandler()}
            onSeek={e => onSeekings(e)}
            onEnded={() => {
              if (courseDetails.ctype == 'regular' && curObject.op == 1) {
                setMarkComplete(true);
              }
            }}
          /> */}
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
            onPlay={() => playVideoHandler()}
            onPause={() => pauseVideoHandler()}
            onSeek={(e) => onSeekings(e)}
            onEnded={() => {
              if (courseDetails.ctype == "regular" && curObject.op == 1) {
                setMarkComplete(true);
              }
            }}
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          height: "90%",
        }}
      >
        <CardMedia
          image={
            curObject.otype !== "vimeo"
              ? `https://www.youtube.com/embed/${curObject.ourl}?rel=0&showinfo=0`
              : `https://player.vimeo.com/video/${curObject.ourl}?title=0&loop=0`
          }
          height="100%"
          component="iframe"
          onLoad={onDocumentLoadSuccess()}
          frameBorder={0}
          controls
        />
      </div>
    );
  }

  function onDocumentLoadSuccess() {
    if (courseDetails.ctype == "regular" && curObject.op == 1) {
      setMarkComplete(true);
    } else if (courseDetails.ctype == "enforced" && curObject.op == 1) {
      if (timerPalyPause != "play") {
        setTimerPalyPause("play");
      }

      if (objStatus === false) {
        setObjStatus(true);
      }
    } else if (curObject.op == 2) {
      setMarkComplete(true);
    }
  }
  function PdfView() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "88%",
          overflowX: "hidden",
          position: "relative",
          marginTop: "1rem",
        }}
      >
        <FullScreen handle={handle}>
          <CardMedia
            alt="GeeksforGeeks"
            component="iframe"
            title={curObject.otitle}
            src={`${curObject.ourl}#toolbar=0&navpanes=0`}
            type="application/pdf"
            onLoad={onDocumentLoadSuccess()}
            frameBorder={0}
          />
        </FullScreen>
        {/* <Document file={curObject.ourl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document> */}
        {/* <p>Page {pageNumber} of {numPages}</p> */}
      </div>
    );
  }

  function WebView() {
    return (
      <FullScreen handle={handle}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            fontFamily: `myfontregular`,
          }}
        >
          <CardMedia
            alt="GeeksforGeeks"
            component="iframe"
            width="100%"
            height="100%"
            title={curObject.otitle}
            src={curObject.ourl}
            onLoad={onDocumentLoadSuccess()}
            className={classes.documentSection}
          />
        </div>
      </FullScreen>
    );
  }
  async function getQuiz(obj) {
    setIsLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.oid,
        oid: config.aws_org_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuiz",
        bodyParam
      );
      const { qitems, cutoff } = response;

      setCutOff(cutoff);
      setQuestion(qitems);
      setIsLoading(false);
      setStartQuiz(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  function tempView() {
    return (
      <React.Fragment>
        {curObject.qtype === 2 && (
          <React.Fragment>
            <FormativeQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              courseDetails={courseDetails}
              cutOff={cutOff}
              setMarkComplete={setMarkComplete}
              getQuiz={getQuiz}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              curObject={curObject}
              showScore={showScore}
              setShowScore={setShowScore}
              setProgress={setProgress}
              score={perScore}
              setScore={setPerScore}
              nextObject={nextobject}
              setTimerPalyPause={setTimerPalyPause}
              syncUserProgress={syncUserProgress}
              syncUserProgresswhilequiz={syncUserProgresswhilequiz}
              startTime={startTime}
            />
            {!startQuiz && (
              <div className="formative_start_button_container">
                <button
                  className="button-3"
                  onClick={() => {
                    getQuiz(curObject);
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        fontSize: "2.5rem",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    "Start Quiz"
                  )}
                </button>
              </div>
            )}
          </React.Fragment>
        )}
        {curObject.qtype === 1 && (
          <React.Fragment>
            <SummativeQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              getQuiz={getQuiz}
              curObject={curObject}
              setProgress={setProgress}
              setMarkComplete={setMarkComplete}
              syncUserProgresswhilequiz={syncUserProgresswhilequiz}
              courseDetails={courseDetails}
              score={perScore}
              setPerScore={setPerScore}
              setShowScore={setShowScore}
              nextObject={nextobject}
              showScore={showScore}
              curNugIndex={curNugIndex}
              curNugget={curNugget}
              curObjIndex={curObjIndex}
              nextCourseref={nextCourseref}
              setTimerPalyPause={setTimerPalyPause}
              totalScore={totalScore}
              setTotalScore={setTotalScore}
              nextcourse={nextcourse}
              getUserCertificate={getUserCertificate}
              getTopicDetails={getTopicDetails}
              lastobject={lastobject}
            />
            {!startQuiz && !showScore && (
              <div className="formative_start_button_container-2">
                <div>
                  <h3
                    variant="h3"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "600",
                      marginBottom: "5rem",
                    }}
                  >
                    {courseDetails.pname}
                  </h3>
                  <div
                    style={{
                      display: "flex",

                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #777",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "400px",
                        borderRadius: "8px",
                        boxShadow: "1px 2px 2px 2px #f1f5f8",
                        backgroundColor: "#f1f5f8",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          padding: "2rem",
                          borderRight: "1px solid #777",
                          flexGrow: "1",
                        }}
                      >
                        <p>
                          <HelpOutlineIcon style={{ fontSize: 30 }} />{" "}
                        </p>
                        <p>Questions</p>
                        <p>
                          {curObject.totalq
                            ? curObject.totalq
                            : curObject.oduration / 60}
                        </p>
                      </div>
                      <div style={{ padding: "2rem", flexGrow: "1" }}>
                        <p>
                          <TimerIcon style={{ fontSize: 30 }} />
                        </p>
                        <p>Time allocated</p>
                        <p>{`${Math.floor(
                          curObject.oduration / 60
                        )}m : ${Math.floor(curObject.oduration % 60)}s`}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      justifyContent: "center",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3>Score per question: 1</h3>
                    {/* <h3>
                      Minimum score required to pass:{" "}
                      {question.length
                        ? Math.ceil((question.length * 75) / 100)
                        : Math.ceil(((curObject.oduration / 60) * 75) / 100)}
                    </h3> */}
                  </div>
                </div>
                <div style={{ alignSelf: "center" }}>
                  <button
                    className="button-3"
                    onClick={() => {
                      getQuiz(curObject);
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{
                          fontSize: "2.5rem",
                          height: "2.5rem",
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      "Start Quiz"
                    )}
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  function handlefeedbacktextChange(e, qind) {
    let temp_state = [...question];

    let temp_element = { ...temp_state[qind] };
    temp_element.opindex = e.target.value;
    temp_state[qind] = temp_element;

    setQuestion(temp_state);
    questionsRef.current = temp_state;
  }
  const handlefeedbackChange = (event) => {
    let temp_state = [...question];

    // 2. Make a shallow copy of the element you want to mutate
    let temp_element = { ...temp_state[event.target.name] };

    // 3. Update the property you're interested in
    // alert(JSON.stringify(temp_element))
    temp_element.value = event.target.value;
    const index = temp_element.iopts.findIndex(
      (item) => item.content === event.target.value
    );
    if (index !== -1) {
      temp_element.opindex = index;
    }
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    temp_state[event.target.name] = temp_element;
    // 5. Set the state to our new copy

    setQuestion(temp_state);
    questionsRef.current = temp_state;

    setValue(event.target.value);
  };
  function validationcheck() {
    for (var j = 0; j < questionsRef.current.length; j++) {
      if (questionsRef.current[j].score === undefined) {
        setError("Some questions are not answered , Please answer");
        // scrollT.current.scrollTo(0, 0);
        return false;
      }
    }
    setAnsShow(true);
  }
  function challenge(len) {
    if (len === 2) {
      return "challenge2";
    } else if (len === 3) {
      return "challenge3";
    } else if (len === 4) {
      return "challenge4";
    } else if (len === 5) {
      return "challenge5";
    }
  }

  function feedbackView() {
    return (
      <div
        ref={scroll}
        style={
          loading || question.length == 0
            ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }
            : {
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }
        }
      >
        {startQuiz ? (
          <>
            <>
              {loading || question.length == 0 ? (
                <CircularProgress color="default" size={30} />
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                      justifyContent: "center",
                      marginBottom: "3rem",
                      width: "100%",
                    }}
                  >
                    {question.map((question, qind) => (
                      <div
                        style={question.qtype == 3 ? { display: "flex" } : null}
                      >
                        <div style={{ height: "fit-content" }}>
                          <h4
                            style={{
                              marginBottom: "1rem",
                              fontWeight: "600",
                              color: "#555555",
                              display: "inline-block",
                              height:
                                question.istem.length > 100 ? "110px" : "",
                            }}
                          >
                            {qind + 1}. {question.istem}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </h4>
                        </div>
                        <RadioGroup
                          aria-label="gender"
                          name={qind}
                          value={question.value}
                          onChange={handlefeedbackChange}
                        >
                          <div className={classes.quizroot}>
                            {question.qtype == 0 ? (
                              <div style={{ position: "relative" }}>
                                <div
                                  className={challenge(question.iopts.length)}
                                  style={{ zIndex: "2" }}
                                >
                                  {question.iopts.map((option, opind) => (
                                    <div>
                                      <FormControlLabel
                                        value={option.content}
                                        control={<GreenRadio />}
                                        label={option.content}
                                        key={opind}
                                        className={`${classes.label} ${classes.label2} ${classesse.h}`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {/* <div
                                  style={{
                                    borderTop: '1px solid #1C6D9C',
                                    width: '85%',
                                    position: 'absolute',
                                    top: '40%',
                                    left: '8.6%',
                                    zIndex: '1',
                                  }}
                                ></div> */}
                              </div>
                            ) : (
                              <>
                                {question.qtype == 2 ? (
                                  <>
                                    <TextareaAutosize
                                      style={{
                                        width: "97%",
                                        height: "80px",
                                        backgroundColor: "transparent",
                                        resize: "none",
                                        padding: ".8rem 1.5rem",
                                        marginLeft: "2rem",
                                        outline: "none",
                                        border: "1px solid #1C6D9C",
                                        borderRadius: ".5rem",
                                      }}
                                      rowsMax={2}
                                      aria-label="maximum height"
                                      placeholder={question.istem}
                                      value={question.response}
                                      onChange={(e) =>
                                        handlefeedbacktextChange(e, qind)
                                      }
                                      required
                                    />
                                  </>
                                ) : (
                                  <>
                                    {question.qtype == 3 ? (
                                      <select
                                        name="job"
                                        value={question.response}
                                        onChange={(event) => {
                                          let temp_state = [...question];

                                          let temp_element = {
                                            ...temp_state[qind],
                                          };
                                          temp_element.opindex =
                                            event.target.value;
                                          temp_state[qind] = temp_element;
                                          setQuestion(temp_state);
                                          handlefeedbacktextChange(event, qind);
                                        }}
                                        style={{
                                          marginLeft: "2rem",
                                          height: "25px",
                                          width: "200px",
                                        }}
                                        required
                                      >
                                        <option key="select" value="">
                                          select option
                                        </option>
                                        {question.iopts.map((e, key) => {
                                          return (
                                            <option key={key} value={e}>
                                              {e}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : (
                                      <Grid container spacing={5}>
                                        {question.iopts.map((option, opind) => (
                                          <Grid item xs={5}>
                                            <FormControlLabel
                                              value={option.content}
                                              control={
                                                <Radio
                                                  style={{
                                                    color: "black",
                                                  }}
                                                  required
                                                />
                                              }
                                              label={option.content}
                                              key={opind}
                                              className={classes.labelSurvey}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </RadioGroup>
                      </div>
                    ))}
                    {surveyError !== "" ? (
                      <h3
                        style={{
                          color: "red",
                          fontWeight: "600",
                          fontSize: "1.6rem",
                          textAlign: "left",
                          justifySelf: "flex-start",
                          width: "100%",
                        }}
                      >
                        {surveyError}
                      </h3>
                    ) : null}
                    <div className="flex-center">
                      <Online>
                        <StyledButtonQuiz
                          onClick={() => {
                            setSurveyError("");
                            SurveyValidation();
                          }}
                        >
                          Next
                        </StyledButtonQuiz>
                      </Online>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        ) : (
          <>
            <div>
              {loading ? (
                <CircularProgress color="default" size={30} />
              ) : (
                <div className="centeritem" style={{ height: "63vh" }}>
                  <Offline>
                    <StyledButtonQuiz>Take survey</StyledButtonQuiz>
                  </Offline>
                  {curObject.op == 2 ? (
                    <>
                      <Typography variant="h2">Survey Completed</Typography>
                    </>
                  ) : (
                    <StyledButtonQuiz
                      onClick={() => {
                        questionsRef.current = [];
                        setStartQuiz(false);
                        // setRetakeExam(false);
                        retake.current = false;
                        getQuiz(curObject);
                        setShowScore(false);
                        setLoading(true);
                        setSurveyError("");
                      }}
                    >
                      Take survey
                    </StyledButtonQuiz>
                  )}
                  <Online></Online>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
  function handlemarkcomplete() {
    syncUserProgress();
  }
  async function nextobject() {
    setQuestion([]);
    questionsRef.current = [];
    setShowScore(false);
    setStartQuiz(false);
    setLoading(true);
    let coindex = curObjIndex;
    coindex++;
    setCurObjIndex(coindex);
    setMarkComplete(false);
    if (coindex == curNugget.objects.length) {
      let curnugind = curNugIndex;
      setCurObjIndex(0);
      curnugind++;
      setCurNugIndex(curnugind);

      if (courseDetails.nuggets[curnugind] !== undefined) {
        setCurNugget(courseDetails.nuggets[curnugind]);
        //  if(courseDetails.nuggets[curnugind].objects[0].op == 0){
        //    courseDetails.nuggets[curnugind].objects[0].op = 1
        //  }

        setCurObject(courseDetails.nuggets[curnugind].objects[0]);
        if (
          courseDetails.nuggets[curnugind].objects[0].otype == "quiz" &&
          courseDetails.nuggets[curnugind].objects[0].op == 2 &&
          courseDetails.nuggets[curnugind].objects[0].qtype == 1
        ) {
          setLoading(true);
          // getQuizScore(courseDetails.nuggets[curnugind].objects[0]);
        } else {
          setLoading(false);
          setIsLoading(false);
          getTopicDetails();
        }
        if (
          courseDetails.ctype == "enforced" &&
          courseDetails.nuggets[curnugind].objects[0].op == 1
        ) {
          if (
            courseDetails.userdataset !== undefined &&
            courseDetails.userdataset.tresume !== undefined &&
            courseDetails.userdataset.tresume.oid ==
              courseDetails.nuggets[curnugind].objects[0].oid
          ) {
            setProgress(courseDetails.userdataset.tresume.obtime);
          } else {
            setProgress(
              Number(courseDetails.nuggets[curnugind].objects[0].oduration)
            );
          }

          if (
            courseDetails.nuggets[curnugind].objects[0].otype != "video" &&
            courseDetails.nuggets[curnugind].objects[0].otype != "quiz" &&
            courseDetails.nuggets[curnugind].objects[0].otype != "feedback"
          ) {
            setTimerPalyPause("play");
          }
        }
      } else if (curnugind == courseDetails.nuggets.length) {
        if (nextCourseref.current == true) {
          if (
            courseDetails.certification == "true" ||
            courseDetails.certification === true
          ) {
            getUserCertificate();
          } else {
            nextcourse();
          }
          nextCourseref.current = false;
        } else {
          setOpen(false);
        }
        if (
          courseDetails.freenavigation == true ||
          courseDetails.freenavigation == "true"
        ) {
          nextcourse();
        } else {
          setOpen(false);
        }
      } else {
        setOpen(false);
        setLoading(false);
        setIsLoading(false);
        getTopicDetails();
      }
    } else {
      setCurObject(curNugget.objects[coindex]);
      if (
        curNugget.objects[coindex].otype == "feedback" &&
        curNugget.objects[coindex].op == 1
      ) {
        setStartQuiz(true);
      }
      if (
        curNugget.objects[coindex].otype == "scorm" &&
        curNugget.objects[coindex].op == 1
      ) {
        scormpPause.current = false;
        retake.current = false;
      } else {
        scormpPause.current = true;
      }
      if (
        courseDetails.ctype == "enforced" &&
        curNugget.objects[coindex].op == 1
      ) {
        if (
          courseDetails.userdataset !== undefined &&
          courseDetails.userdataset.tresume !== undefined &&
          courseDetails.userdataset.tresume.oid ==
            curNugget.objects[coindex].oid
        ) {
          setProgress(courseDetails.userdataset.tresume.obtime);
        } else {
          setProgress(Number(curNugget.objects[coindex].oduration));
        }

        if (
          curNugget.objects[coindex].otype != "video" &&
          curNugget.objects[coindex].otype != "quiz" &&
          curNugget.objects[coindex].otype != "feedback"
        ) {
          setTimerPalyPause("play");
        }
      }
      if (
        curNugget.objects[coindex].otype == "quiz" &&
        curNugget.objects[coindex].op == 2 &&
        curNugget.objects[coindex].qtype == 1
      ) {
        setLoading(true);
        // getQuizScore(curNugget.objects[coindex]);
      } else {
        setLoading(false);
        setIsLoading(false);
      }
      setLoading(false);
      setIsLoading(false);
    }
    window.nextbtn = undefined;
  }
  function nextcourse() {
    let nxtvar = "",
      btnmsg = "";
    if (
      courseDetails.nextcourse == true ||
      courseDetails.nextcourse == "true"
    ) {
      nxtvar = `Up next, ${courseDetails.nextcoursedata.ttitle}<br>`;
      btnmsg = "Click here to get started";
    } else {
      nxtvar = `To view some of our other courses,<br>`;
      btnmsg = "Please click here";
    }
    Swal.fire({
      title: "Congratulations!",
      width: 400,
      html:
        `<h3 style="font-size:1.5625rem">You completed the course - ${courseDetails?.ttitle} </h3>` +
        "<br >" +
        `<h3 style="font-size:1.5625rem">${nxtvar}</h3>`,
      showCloseButton: true,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: `<h3 style="color:#0a3055;"> ${btnmsg} </h3>`,
      allowOutsideClick: false,
      customClass: "nextcourseswal",
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          courseDetails.nextcourse == true ||
          courseDetails.nextcourse == "true"
        ) {
          let breadcrumb = { ...userDetails };

          breadcrumb.location = {
            from: breadcrumb.location.from,
            courseName: courseDetails?.nextcoursedata?.ttitle,
            prevLoc: breadcrumb.location.prevLoc,
            programName: breadcrumb.location.programName,
            topicName: breadcrumb.location.topicName,
            topicId: breadcrumb.location.topicId,
            viewAll: breadcrumb.location.viewAll,
            programId: breadcrumb.location.programId,
            cid: breadcrumb.location.cid,
          };
          dispatch(awsSignIn(breadcrumb));
          history.push(`/course?id=${courseDetails.nextcoursedata.tid}`);

          window.location.reload();
        } else {
          history.push("/viewAllCourses");
        }
        //syncUserProgress();
      }
      if (result.isDenied) {
        alert("User clicked deny");
      }
    });
  }
  async function syncUserCertificate(otype) {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      // setLoading(true);
      // const bodyParam = {
      //   body: {
      //     oid: config.aws_org_id,
      //     topicid: courseDetails.tid,
      //     ur_id: userDetails.uData.ur_id,
      //     id: userDetails.id,
      //     iid: config.aws_cognito_identity_pool_id,
      //     eid: userDetails.username,
      //     uname: userDetails.name,
      //     emailid: userDetails.emailid,
      //     tenant: userDetails.locale,
      //     pid: courseDetails.pid,
      //     completiondate: new Date().getDate(),
      //     schema: config.schema,
      //   },
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //     // Authorization: jwttoken,
      //   },
      // };
      // const response = await API.post(
      //   config.aws_cloud_logic_custom_name,
      //   "/generateUserCertificate",
      //   bodyParam
      // );

      if (otype == "") {
        setCertificate(true);
        //getUserCertificate();
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  async function getUserCertificate() {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setLoading(true);
      const bodyParam = {
        body: {
          action: 0,
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          topicid: courseDetails.tid,
          ur_id: userDetails.uData.ur_id,
          eid: userDetails.username,
          emailid: userDetails.email,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listUserCerts",
        bodyParam
      );

      setCertificate(true);
      setCertificateList(response);
      setLoading(false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function SurveyValidation() {
    let surveyArr = [];
    for (var j = 0; j < questionsRef.current.length; j++) {
      if (
        questionsRef.current[j].opindex === undefined ||
        questionsRef.current[j].opindex === ""
      ) {
        setSurveyError("Some questions are not answered , Please answer");
        surveyArr.push(false);
      }
      surveyArr.push(true);
    }
    if (!surveyArr.includes(false)) {
      updateSurvey(curObject.oid);
      syncUserProgress();
    } else {
      // scroll.current.scrollTo(0, 0);
    }
  }
  async function updateSurvey(objid) {
    var obj = {};

    obj.survey = [];

    for (var l = 0; l < questionsRef.current.length; l++) {
      var robj = {};
      robj.iid = questionsRef.current[l].iid;
      robj.value = questionsRef.current[l].value;
      robj.opindex = questionsRef.current[l].opindex;
      robj.qtype = questionsRef.current[l].qtype;
      obj.survey.push(robj);

      if (l == questionsRef.current.length - 1) {
        try {
          //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
          setLoading(true);
          const bodyParam = {
            body: {
              oid: config.aws_org_id,
              topicid: courseDetails.tid,
              eid: userDetails.username,
              emailid: userDetails.email,
              tenant: userDetails.locale,
              pid: courseDetails.pid,
              objid,
              surveydata: obj,
              schema: config.schema,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          };
          const response = await API.post(
            config.aws_cloud_logic_custom_name,
            "/updateUserSurvey",
            bodyParam
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
    //surveydata
  }
  async function syncUserProgress(type) {
    // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
    let coindex = curObjIndex;
    coindex++;
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        status:
          courseDetails.freenavigation == "false" ||
          courseDetails.freenavigation == false
            ? "update"
            : "nav",

        tid: courseDetails.tid,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        otype: curObject.otype,
        nugget: curNugIndex + 1,
        tnuggets: courseDetails.noofnuggets,
        nav:
          courseDetails.freenavigation == "false" ||
          courseDetails.freenavigation == false
            ? false
            : true,
        email: userDetails.username,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        tobjects: curNugget.objects.length,
        object: coindex,
        pid: courseDetails.pid,
        ur_id: userDetails.uData.ur_id,
        obj_id: curObject.oid,
        op: 2,
        key: courseDetails.tid,
        obtime: (new Date().getTime() - startTime) / 1000,
        rmc: curNugget.ntitle,
        cert: courseDetails.certification,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };
    if (type !== undefined) {
      bodyParam.body.op = 1;
    }
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      if (closePopUp.current === true) {
        setOpen(false);
        closePopUp.current = false;
      }
      let nrobj, prevobjtime, prevObject;
      if (type === undefined) {
        nrobj = courseResume;
        curNugget.objects[curObjIndex].op = 2;
        prevobjtime = curNugget.objects[curObjIndex].oduration;
        prevObject = curNugget.objects[curObjIndex].otype;
        setCurObjIndex(coindex);
        nrobj.robject = coindex;
        let ttp = totalProgress;
        ttp++;
        setTotalProgress(ttp);
      }

      setQuestion([]);
      questionsRef.current = [];
      setTimerPalyPause("pause");
      setShowScore(false);
      setStartQuiz(false);
      setMarkComplete(false);
      if (coindex == curNugget.objects.length && type === undefined) {
        let curnugind = curNugIndex;
        setCurObjIndex(0);
        nrobj.robject = 0;
        curnugind++;

        setCurNugIndex(curnugind);
        if (courseDetails.nuggets[curnugind] !== undefined) {
          setCurNugget(courseDetails.nuggets[curnugind]);

          nrobj.rnugget = courseDetails.nuggets[curnugind].nid;
          if (courseDetails.nuggets[curnugind].objects[0].op == 0) {
            courseDetails.nuggets[curnugind].objects[0].op = 1;
          }

          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          setRemaining(rmtime);
          setCurObject(courseDetails.nuggets[curnugind].objects[0]);
          if (
            courseDetails.ctype == "enforced" &&
            courseDetails.nuggets[curnugind].objects[0].op == 1
          ) {
            setProgress(
              Number(courseDetails.nuggets[curnugind].objects[0].oduration)
            );

            setTimerPalyPause("play");
          }
          if (
            (courseDetails.ctype == "enforced" &&
              courseDetails.nuggets[curnugind].objects[0].op == 1 &&
              courseDetails.nuggets[curnugind].objects[0].otype == "video") ||
            courseDetails.nuggets[curnugind].objects[0].otype == "quiz"
          ) {
            setTimerPalyPause("pause");
          }
          if (
            courseDetails.nuggets[curnugind].objects[0].otype == "scorm" &&
            courseDetails.nuggets[curnugind].objects[0].op == 1
          ) {
            scormpPause.current = false;
            retake.current = false;
          } else {
            scormpPause.current = true;
          }
          setLoading(false);
        } else if (curnugind == courseDetails.nuggets.length) {
          // close popup
          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;

          setRemaining(rmtime);
          updateCourseAnalytics("completed");
          var tp = "2";
          let ttp = 0;
          for (var d = 0; d < courseDetails.nuggets.length; d++) {
            tp = tp + "-2";
            for (
              let index = 0;
              index < courseDetails.nuggets[d]?.objects.length;
              index++
            ) {
              ttp++;
            }
          }
          setTotalProgress(ttp);
          if (courseDetails.freenavigation) {
            rmtime.tremaining = 0;
            rmtime.mremaining = 0;
            var dataset = courseDetails;
            dataset.userdataset.userprogress =
              dataset.userdataset.userprogress !== undefined
                ? dataset.userdataset.userprogress
                : {};
            setCourseDetails(dataset);
          }
          setTpProgress(tp);
          if (
            prevObject !== "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            syncUserCertificate("");
          } else if (
            prevObject === "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            nextCourseref.current = true;
            syncUserCertificate("quiz");
          } else {
            nextcourse();
            setOpen(false);
            setLoading(false);
          }
        }
      } else if (type === undefined) {
        if (curNugget.objects[coindex].op == 0) {
          curNugget.objects[coindex].op = 1;
        }
        let rmtime = { ...remaining };
        rmtime.tremaining = rmtime.tremaining - prevobjtime;
        rmtime.mremaining = remaining.mremaining;
        setRemaining(rmtime);
        setCurObject(curNugget.objects[coindex]);
        if (
          courseDetails.ctype == "enforced" &&
          curNugget.objects[coindex].op == 1 &&
          curNugget.objects[coindex].otype != "feedback"
        ) {
          setProgress(Number(curNugget.objects[coindex].oduration));
          setTimerPalyPause("play");
        }
        if (
          (courseDetails.ctype == "enforced" &&
            curNugget.objects[coindex].op == 1 &&
            curNugget.objects[coindex].otype == "video") ||
          curNugget.objects[coindex].otype == "quiz"
        ) {
          setTimerPalyPause("pause");
        }
        if (
          curNugget.objects[coindex].op == 1 &&
          curNugget.objects[coindex].otype == "scorm"
        ) {
          scormpPause.current = false;
          retake.current = false;
        } else {
          scormpPause.current = true;
        }

        if (curNugget.objects[coindex].otype == "feedback") {
          questionsRef.current = [];
          setTimerPalyPause("pause");
          setQuestion([]);
          getQuiz(curNugget.objects[coindex]);
          setLoading(true);
        } else {
          setLoading(false);
        }
      }
      if (type === undefined) {
        setcourseResume(nrobj);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function syncUserProgresswhilequiz(score, squiz) {
    try {
      questionsRef.current = [];

      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      let coindex = curObjIndex;
      coindex++;
      setLoading(true);

      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          status:
            courseDetails.freenavigation == "false" ||
            courseDetails.freenavigation == false
              ? "update"
              : "nav",
          key: courseDetails.tid,
          topicid: courseDetails.tid,
          id: userDetails.id,
          iid: config.aws_cognito_identity_pool_id,
          otype: curObject.otype,
          nugget: curNugIndex + 1,
          tnuggets: courseDetails.noofnuggets,
          nav:
            courseDetails.freenavigation == "false" ||
            courseDetails.freenavigation == false
              ? false
              : true,
          email: userDetails.username,
          emailid: userDetails.email,
          tenant: userDetails.locale,
          tobjects: curNugget.objects.length,
          object: coindex,
          pid: courseDetails.pid,
          cert: courseDetails.certification,
          ur_id: userDetails.uData.ur_id,
          obj_id: curObject.oid,
          op: 2,
          score,
          obtime: (new Date().getTime() - startTime) / 1000,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //Authorization: jwttoken,
        },
      };

      if (score) {
        bodyParam.body.score = score;
      }
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );

      let nrobj = courseResume;
      curNugget.objects[curObjIndex].op = 2;
      let prevobjtime = curNugget.objects[curObjIndex].oduration;
      let prevObject = curNugget.objects[curObjIndex].otype;

      nrobj.robject = coindex;
      let ttp = totalProgress;
      ttp++;
      setTotalProgress(ttp);
      setMarkComplete(false);
      if (coindex == curNugget.objects.length) {
        let curnugind = curNugIndex;
        nrobj.robject = 0;
        curnugind++;

        if (courseDetails.nuggets[curnugind] !== undefined) {
          nrobj.rnugget = courseDetails.nuggets[curnugind].nid;
          if (courseDetails.nuggets[curnugind].objects[0].op == 0) {
            courseDetails.nuggets[curnugind].objects[0].op = 1;
          }
          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          setRemaining(rmtime);
        } else if (curnugind == courseDetails.nuggets.length) {
          // close popup
          let rmtime = { ...remaining };
          rmtime.mremaining = rmtime.mremaining - 1;
          rmtime.tremaining = rmtime.tremaining - prevobjtime;
          updateCourseAnalytics("completed");
          setRemaining(rmtime);
          var tp = "2";
          for (var d = 0; d < courseDetails.nuggets.length; d++) {
            tp = tp + "-2";
          }
          setTpProgress(tp);

          if (
            !squiz &&
            prevObject !== "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            syncUserCertificate("");
            setOpen(false);
          } else if (
            !squiz &&
            prevObject === "quiz" &&
            (courseDetails.certification === "true" ||
              courseDetails.certification === true)
          ) {
            nextCourseref.current = true;
            syncUserCertificate("quiz");
          } else if (!squiz) {
            nextcourse();
            setOpen(false);
          } else {
            setLoading(false);
            setIsLoading(false);
          }
        }
      } else {
        if (curNugget.objects[coindex].op == 0) {
          curNugget.objects[coindex].op = 1;
        }
        let rmtime = remaining;
        rmtime.tremaining = rmtime.tremaining - prevobjtime;
        rmtime.mremaining = remaining.mremaining;
        setRemaining(rmtime);
      }

      setcourseResume(nrobj);

      setLoading(false);
      setShowScore(true);
    } catch (error) {
      console.error(error);
    }
  }
  async function updateUserScormAnalytics() {
    try {
      //setLoading(true);
      // JSON.stringify(window.cudetails)
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          //Authorization: jwttoken,
        },
      };
      bodyParam.body = window.cudetails;
      bodyParam.body.atype = 1;
      bodyParam.body.ur_id = userDetails.uData.ur_id;
      bodyParam.body.obj_id = curObject.oid;
      bodyParam.body.op = 2;
      bodyParam.body.susdt = "N";
      bodyParam.body.schema = config.schema;
console.log("scormstatusupdate",bodyParam.body)
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateUserScormAnalytics",
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }
  function handleQuizSubmit() {
    let totalscore = 0;

    for (var j = 0; j < questionsRef.current.length; j++) {
      if (questionsRef.current[j].value === undefined) {
        setError("Some questions are not answered , Please answer");
        return;
      } else if (questionsRef.current[j].score !== undefined) {
        totalscore = totalscore + questionsRef.current[j].score;
      }

      if (j === questionsRef.current.length - 1) {
        let objsr = {};
        objsr.oid = curObject.oid;
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var date1 = date.getDate();
        var sdate = date1 + "." + month + "." + year;
        objsr.name = curObject.otitle;
        objsr.cdate = sdate;
        objsr.score = totalscore;
        objsr.tquestions = questionsRef.current.length;
        setScoreJson(objsr);

        setPerScore((totalscore / questionsRef.current.length) * 100);
        setTotalScore(totalscore);
        // if ((totalscore / questionsRef.current.length) * 100 >= 75) {
        setShowScore(true);
        setMarkComplete(true);
        setTryBtnShow(false);
        handleScoreSubmit(objsr);
        syncUserProgresswhilequiz();
        // } else {
        // handleScoreSubmit(objsr);
        // setMarkComplete(true);
        // setShowScore(true);
        // setTryBtnShow(true);
        // }
        setTimerPalyPause("pause");
      }
    }
  }
  async function handleScoreSubmit(scorejson) {
    //alert(JSON.stringify(scorejson))
    setLoading(true);
    const bodyParam = {
      body: {
        emailid: userDetails.email,
        pid: courseDetails.pid,
        key: courseDetails.tid,
        email: userDetails.username,
        oid: config.aws_org_id,
        uname: userDetails.name,
        tenant: userDetails.locale,
        scorejson,
        score: scorejson.score,
        totalqns: scorejson.tquestions,
        obj_id: curObject.oid,
        ur_id: userDetails.uData.ur_id,
        status: "quiz",
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  async function getQuizScore(objects) {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setLoading(true);
      setIsLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          emailid: userDetails.email,
          obj_id: objects.oid,
          ur_id: userDetails.uData.ur_id,
          tid: courseDetails.tid,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuizScore",
        bodyParam
      );

      setPerScore(response);
      setTotalScore(response);
      setShowScore(true);
      setLoading(false);
      setIsLoading(false);
      setStartQuiz(true);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setLoading(false);
    }
  }
  async function updateResumeTime() {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      var obj = {};
      obj.oid = curObject.oid;
      if (curObject.otype == "video" && reactplayer.current !== null) {
        obj.time = Math.round(reactplayer.current.getCurrentTime());
      }
      obj.obtime = progress;
      var dataset = courseDetails;
      dataset.userdataset.tresume = obj;
      setCourseDetails(dataset);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          obj_id: curObject.oid,
          op: 1,
          tid: courseDetails.tid,
          ur_id: userDetails.uData.ur_id,
          key: courseDetails.tid,
          obtime:
            obj.time !== undefined
              ? obj.time
              : (new Date().getTime() - startTime) / 1000,
          tenant: userDetails.locale,
          eid: userDetails.username,
          tresume: obj,
          nid: curNugget.nid,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function downloadPdf() {
    try {
      let filename,
        type,
        durl = "";
      if (curObject.otype == "pdf") {
        type = "application/pdf";
        filename = curObject.otitle + ".pdf";
        durl = curObject.ourl;
      }
      if (curObject.dtype !== undefined && curObject.dtype.includes("ppt")) {
        type = "application/pdf";
        filename = curObject.otitle + curObject.dtype;
        durl = curObject.durl;
      } else if (
        curObject.dtype !== undefined &&
        curObject.dtype.includes("doc")
      ) {
        type =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        filename = curObject.otitle + curObject.dtype;
        durl = curObject.durl;
      }
      axios
        .get(durl, {
          responseType: "blob",
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": config.DOMAIN,
            "Access-Control-Request-Headers": "Content-Type, Authorization",
            "Content-Type": type,
          },
        })
        .then((res) => {
          let file = new Blob([res.data], { type: type });
          saveAs(file, filename);
        });
    } catch (error) {
      console.log("errrr");
    }
  }
  function ImagetoPrint(source) {
    return (
      "<html><head><scri" +
      "pt>function step1(){\n" +
      "setTimeout('step2()', 10);}\n" +
      "function step2(){window.print();window.close()}\n" +
      "</scri" +
      "pt></head><body onload='step1()'>\n" +
      "<img src='" +
      source +
      "' /></body></html>"
    );
  }

  function PrintImage(source) {
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(ImagetoPrint(source));
    pwa.document.close();
  }
  return (
    <div>
      <Offline>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={true}
        >
          {/* <Alert severity="warning" style={{ fontSize: "15px" }}>
            You are offline. Please check your internet connectivity.
          </Alert> */}
        </Snackbar>
      </Offline>
      {tpProgress.charAt(0) == 1 && !courseDetails.freenavigation && (
        <>
          <Offline>
            <StyledButton>Resume</StyledButton>
          </Offline>
          <Online>
            {!courseDetails.freenavigation && (
              <StyledButton onClick={() => handleModalOpen(courseResume)}>
                Resume
              </StyledButton>
            )}
          </Online>
        </>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        disableEscapeKeyDown
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {loading ? (
          <>
            <CircularProgress
              className="spinnerStyle"
              style={{
                position: "absolute !important",
                left: "50%",
                top: "50%",
              }}
            />
          </>
        ) : (
          <>
            {certificate ? (
              <>
                <button
                  onClick={handleModalClose}
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                    position: "absolute",
                    top: "20px",
                    right: "22%",
                  }}
                >
                  <CloseIcon fontSize="large" style={{ fontSize: "2.4rem" }} />
                </button>
                <CertificatePopUp
                  setOpen={setCertificate}
                  open={certificate}
                  sData={courseDetails}
                  modalPopUpClose={setOpen}
                />
              </>
            ) : (
              <Fade in={open}>
                <div className={classes.paper} style={{ position: "relative" }}>
                  <div className={classes.headerSection}>
                    <h3
                      id="transition-modal-title"
                      className={classes.mainHeader}
                    >
                      {certificate ? "" : curObject.otitle}
                      {curObject.otype == "quiz" ? (
                        <>
                          {question.length !== 0 && question !== undefined ? (
                            <>
                              <span className={classes.separator}></span>
                              <HelpOutlineRoundedIcon
                                style={{
                                  fontSize: "2rem",
                                  margin: "0rem 0rem 0rem 1rem",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "1.5rem",
                                  margin: ".4rem 0rem 0rem .3rem",
                                  color: "#555555",
                                }}
                              >
                                {question.length}Q
                              </span>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <></>
                      )}

                      {curObject.otype == "video" ? (
                        <Tippy
                          {...defaultTippyProps}
                          content={
                            <div
                              style={{
                                background: "transparent",
                                color: "#555555",
                                padding: ".5rem 1rem 1rem 1rem",
                                cursor: "pointer",
                                fontSize: "1.60rem",
                                fontWeight: "400",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: curObject.odescription
                                  ? curObject.odescription
                                  : "No description",
                              }}
                            ></div>
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <span className={classes.barResponsive}></span>
                            <span className={classes.infoResponsive}>
                              <InfoOutlinedIcon
                                className="icon"
                                style={{
                                  display: "inline-block",
                                  color: "#1974b5",
                                }}
                              />
                            </span>
                          </div>
                        </Tippy>
                      ) : (
                        <>
                          {curObject.otype === "pdf" ? (
                            <Link
                              to="#"
                              onClick={() => downloadPdf()}
                              className={classes.downloadIcon}
                            >
                              {!certificate ? (
                                <img
                                  src={downloadPdfSvg}
                                  alt="download"
                                  height="100%"
                                />
                              ) : (
                                ""
                              )}
                            </Link>
                          ) : null}
                        </>
                      )}

                      {courseDetails.ctype == "regular" ? (
                        <></>
                      ) : (
                        <>
                          {loading ? (
                            <></>
                          ) : (
                            <>
                              {timerPalyPause == "play" &&
                              curObject.op == 1 &&
                              curObject.otype != "feedback" ? (
                                <>
                                  <Timerview
                                    courseDetails={courseDetails}
                                    curNugget={curNugget}
                                    curObjIndex={curObjIndex}
                                    setProgress={setProgress}
                                    progress={progress}
                                    timerPalyPause={timerPalyPause}
                                    setTimerPalyPause={setTimerPalyPause}
                                    objStatus={objStatus}
                                    setMarkComplete={setMarkComplete}
                                    handleQuizSubmit={handleQuizSubmit}
                                    question={question}
                                    setLoading={setLoading}
                                    questionsRef={questionsRef}
                                  ></Timerview>
                                </>
                              ) : (
                                <>
                                  {curObject.op == 1 &&
                                  curObject.otype != "quiz" &&
                                  curObject.otype != "feedback" ? (
                                    <>
                                      <div className="timerdiv">
                                        {progress <= 0 ? (
                                          <></>
                                        ) : (
                                          <>
                                            <TimerIcon
                                              style={{ fontSize: "2.3rem" }}
                                            />{" "}
                                            {`${Math.floor(
                                              progress / 60
                                            )}m : ${Math.floor(
                                              progress % 60
                                            )}s`}
                                          </>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </h3>
                    <Typography></Typography>
                    <button
                      onClick={handleModalClose}
                      className={classes.closeResponsive}
                    >
                      <CloseIcon
                        fontSize="large"
                        style={{ fontSize: "2.4rem" }}
                      />
                    </button>
                  </div>

                  {loading ? (
                    <>
                      <CircularProgress
                        className="spinnerStyle"
                        style={{
                          position: "absolute !important",
                          left: "50%",
                          top: "50%",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {scormnExtBtn &&
                      curObjIndex != curNugget.objects.length &&
                      curNugIndex != courseDetails.nuggets.length ? (
                        <>
                          <Online>
                            <div>
                              <Button
                                style={{
                                  position: "absolute",
                                  boxShadow:
                                    "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                  color: "#1C6D9C",
                                }}
                                variant="outlined"
                                className={
                                  curObject.otype == "video"
                                    ? "markcomplete"
                                    : "markcomplete  docmarkcombtn"
                                }
                                onClick={() => {
                                  let prevobjtime;
                                  curNugget.objects[curObjIndex].op = 2;
                                  prevobjtime =
                                    curNugget.objects[curObjIndex].oduration;
                                  let ttp = totalProgress;
                                  let rmtime = { ...remaining };
                                  rmtime.mremaining = rmtime.mremaining - 1;
                                  rmtime.tremaining =
                                    rmtime.tremaining - prevobjtime;
                                  setRemaining(rmtime);
                                  ttp++;
                                  setTotalProgress(ttp);
                                  nextobject();
                                }}
                              >
                                {" "}
                                Next
                              </Button>
                            </div>
                          </Online>
                          <Offline>
                            <Button
                              variant="outlined"
                              style={{ color: "#fff", background: "#1C6D9C" }}
                              className={
                                curObject.otype == "video"
                                  ? "markcomplete"
                                  : "markcomplete docmarkcombtn"
                              }
                            >
                              {" "}
                              Next{" "}
                            </Button>
                          </Offline>
                        </>
                      ) : (
                        <> </>
                      )}
                      {/* certificate */}
                      {/* {certificate ? (
                          <>
                            <CertificatePopUp
                              setOpen={setCertificate} open={certificate} sData={courseDetails}
                            />
                          </>
                        ) : ( */}
                      <>
                        {markComplete &&
                        curObject.otype != "quiz" &&
                        curObject.otype != "scorm" &&
                        ((curObjIndex != curNugget.objects.length &&
                          curNugIndex != courseDetails.nuggets.length) ||
                          curObject.op == 1) ? (
                          <>
                            <Online>
                              <div>
                                <Button
                                  style={{
                                    position: "absolute",
                                    boxShadow:
                                      "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                    color: "#1C6D9C",
                                  }}
                                  variant="outlined"
                                  className={
                                    curObject.otype == "video"
                                      ? "markcomplete"
                                      : "markcomplete  docmarkcombtn"
                                  }
                                  onClick={() => {
                                    curObject.op == 1
                                      ? handlemarkcomplete()
                                      : nextobject();
                                  }}
                                >
                                  {" "}
                                  {curObjIndex != curNugget.objects.length &&
                                  curNugIndex != courseDetails.nuggets.length
                                    ? "Next"
                                    : "Mark complete"}
                                </Button>
                              </div>
                              <div>
                                <FullscreenIcon
                                  onClick={handle.enter}
                                  style={{
                                    fontSize: "2.5rem",
                                    position: "absolute",
                                    right: "1rem",
                                    bottom: "3rem",
                                  }}
                                />
                              </div>
                            </Online>
                            <Offline>
                              <Button
                                variant="outlined"
                                style={{ color: "#fff", background: "#1C6D9C" }}
                                className={
                                  curObject.otype == "video"
                                    ? "markcomplete"
                                    : "markcomplete docmarkcombtn"
                                }
                              >
                                {" "}
                                Next{" "}
                              </Button>
                            </Offline>
                          </>
                        ) : (
                          <> </>
                        )}

                        {renderObjects()}
                      </>
                      {/* )} */}
                    </>
                  )}
                </div>
              </Fade>
            )}
          </>
        )}
      </Modal>
    </div>
  );
}

export function RatingPopUp({ courseDetails }) {
  const classes = useStyles();

  const history = useHistory();
  let userDetails = useSelector(authData);
  const [value, setValue] = React.useState(1);
  const [hover, setHover] = React.useState(-1);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  // const labels = {
  //   0.5: 'Useless',
  //   1: 'Useless',
  //   1.5: 'Poor',
  //   2: 'Poor',
  //   2.5: 'Ok',
  //   3: 'Ok',
  //   3.5: 'Good',
  //   4: 'Good',
  //   4.5: 'Excellent',
  //   5: 'Excellent',
  // };
  const handleClose = () => {
    setOpen(false);
  };
  async function handleRateSubmit() {
    setLoading(true);
    const bodyParam = {
      body: {
        emailid: userDetails.email,
        rating_date: 1,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        tid: courseDetails.tid,
        email: userDetails.username,
        rating: value,
        oid: config.aws_org_id,
        uname: userDetails.name,
        tenant: userDetails.locale,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );
      //setLoading(false);
      history.push(`/course?id=${courseDetails.tid}`);

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {courseDetails.userdataset.userdata == true &&
      courseDetails.userdataset.star !== undefined ? (
        <></>
      ) : (
        <>
          <Button
            variant="outlined"
            color="primary"
            style={{ fontSize: "1.5rem" }}
            onClick={handleOpen}
          >
            {" "}
            Rate Course
          </Button>
        </>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper1}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" id="transition-modal-title"></Typography>
              <button
                onClick={handleClose}
                style={{
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                <CloseIcon fontSize="large" />
              </button>
            </div>
            <div className={classes.ratingroot}>
              <Typography
                variant="h6"
                style={{
                  //width: "330px",
                  fontSize: "2.5rem",
                  textAlign: "center",
                  //marginLeft: "6rem",
                }}
                id="transition-modal-title"
              >
                Course completed!
              </Typography>
              <Rating
                name="hover-feedback"
                icon={<StarRoundedIcon style={{ fontSize: "5rem" }} />}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              {value == null && (
                <Box
                  ml={2}
                  style={{
                    color: "red",
                    fontSize: "12px",
                    width: "100%",
                    marginLeft: "100px",
                  }}
                >
                  You must select a rating before you can click Submit
                </Box>
              )}
              {loading ? (
                <StyledButton>Loading..</StyledButton>
              ) : (
                <StyledButton
                  onClick={() => {
                    if (value !== null) {
                      handleRateSubmit();
                    }
                  }}
                >
                  Submit
                </StyledButton>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
