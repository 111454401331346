import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TimerIcon from '@material-ui/icons/Timer';

function TimerviewProgressWithLabel(props) {
  return (
    <>
      {props.value <= 0 ? (
        <></>
      ) : (
        <>
          <div className='timerdiv' style={{ width: '125px' }}>
            <Box alignItems='center' justifyContent='center'>
              <Typography
                variant='caption'
                component='div'
                color='textSecondary'
                style={{
                  fontSize: '1.5rem',
                  color: '#000',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {' '}
                <TimerIcon
                  style={{ fontSize: '2.3rem', marginLeft: '.5rem' }}
                />{' '}
                {`${Math.floor(props.value / 60)}m : ${Math.floor(
                  props.value % 60
                )}s`}
              </Typography>
            </Box>
          </div>
        </>
      )}
    </>
  );
}

TimerviewProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function TimerviewStatic({
  courseDetails,
  curNugget,
  curObjIndex,
  progress,
  setProgress,
  timerPalyPause,
  setTimerPalyPause,
  setMarkComplete,
  handleQuizSubmit,
  quizQuestion,
  setLoading,
  questionsRef,
}) {
  const counterRef = React.useRef(progress);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (timerPalyPause == 'play' && navigator.onLine === true) {
        // setProgress(prevProgress => prevProgress - 1);
        setProgress(counterRef.current - 1);
        counterRef.current = counterRef.current - 1;
      }
      console.log('navigator.onLine==' + navigator.onLine);
      //console.log("progress=="+counterRef.current)
      if (counterRef.current <= 0) {
        
        console.log('curObjIndex==' + curObjIndex);
        console.log(curNugget.objects[curObjIndex].otype);
        if (curNugget.objects[curObjIndex].otype == 'quiz') {
          handleQuizSubmit();
        } else {
          setMarkComplete(true);
        }
        setTimerPalyPause('pause');
        clearInterval(timer);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <TimerviewProgressWithLabel value={progress} />;
}
