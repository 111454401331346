import React, { useState, useEffect } from "react";

import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { withStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import filtericon from "../../../assets/filter.png"
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Tippy from "@tippyjs/react";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentcanchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: "12px",
  },
}));

const defaultTippyProps = {
  // animation: "shift-away-subtle",
  trigger: "mouseenter click",
  arrow: true,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,
  maxHeight: 400,
  appendTo: document.body,
  sticky: true,
  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
export default function CourseExploreFilter({
  handleClick,
  anchorEl,
  handleClose,
  contentTypeChange,
  AdHandleChange,
  cadProductF,
  DurHandleChange,
  crOpen,
  cctype,
  sclear,
  ToolsHandleChange,
  cmrToolsF,
  cdurF,
  hctype,
  viewtype,
  visible,
}) {
  const classes = useStyles();
  const [showPopover, setShowPopover] = useState(false);
  const open = () => setShowPopover(true);
  const close = () => setShowPopover(false);
  const [arrow, setArrow] = useState(false);
  return (
    <motion.div
      variants={containerVarients}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Tippy
        content={
          <div
            style={{ maxHeight: "290px", overflowY: "auto" }}
            onMouseEnter={() => setArrow(true)}
            onMouseLeave={() => setArrow(false)}
          >
            <ListItem>
              <div style={{ width: "200px",}}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.heading}>
                      Duration
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="Duration"
                          name="Duration"
                          value={cdurF}
                        >
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="5"
                            control={
                              <Radio
                                onClick={(e) => DurHandleChange(e, viewtype)}
                              />
                            }
                            label="<5 m"
                          />
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="15"
                            control={
                              <Radio
                                onClick={(e) => DurHandleChange(e, viewtype)}
                              />
                            }
                            label="<15 m"
                          />
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="30"
                            control={
                              <Radio
                                onClick={(e) => DurHandleChange(e, viewtype)}
                              />
                            }
                            label="<30 m"
                          />
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="30_60"
                            control={
                              <Radio
                                onClick={(e) => DurHandleChange(e, viewtype)}
                              />
                            }
                            label="30 m to 60 m"
                          />
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="60"
                            control={
                              <Radio
                                onClick={(e) => DurHandleChange(e, viewtype)}
                              />
                            }
                            label=">60 m"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </ListItem>
            <div style={{ display: "flex", justifyContent: "center", }}>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={() => sclear(viewtype)}
                style={{
                  width: "100px",
                  background: "#01498e",
                  color: "#fff",
                  marginTop: "1rem",
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        }
        {...defaultTippyProps}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
            paddingLeft: "10px",
            cursor: "pointer",
          }}
          onMouseEnter={() => setArrow(true)}
          onMouseLeave={() => setArrow(false)}
        >
          {/* <button
            style={{
              border: "0",
              color: "#000",
              background: "#fff",
              width: "80px",
              fontSize: "1.6rem",
             fontFamily: `myfontregular`
            }}
          >
         Filter by
          </button> */}

          <img
                src={filtericon}
                height={"30px"}
                width={"40px"}
                alt="Filter"
              />

          {/* <span>{arrow ? <ExpandMore /> : <ExpandMore />}</span> */}
        </div>
      </Tippy>
    </motion.div>
  );
}
