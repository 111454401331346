import React, { useCallback } from "react";
//React router
import { Link } from "react-router-dom";

// Swiper
import "swiper/swiper.scss";
import { Swiper } from "swiper/react";
import { breakpoints } from "./utils/Breakpoints";
import "swiper/components/navigation/navigation.scss";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import ProgramExploreSort from "../../pages/Explore/ExploreSort/ProgramExploreSort";
import { motion, AnimatePresence } from "framer-motion";
import CourseExploreSort from "../../pages/Explore/ExploreSort/CourseExploreSort";
import CourseExploreFilter from "../../pages/Explore/ExploreFilter/CourseExploreFilter";
import ExploreSort from "../../pages/Explore/ExploreSort/ExploreSort";
import ExploreFilter from "../../pages/Explore/ExploreFilter/ExploreFilter";

import noThingIsHere from "../../assets/Nothing here yet.gif";
import viewallicon from "../../assets/viewall.png";

import Loading from "../../components/Loading/Loading";
import { withWidth } from "@material-ui/core";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
function Slick(props) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <motion.div
      className="carousel-container"
      variants={containerVarients}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="carousel-container--between">
        <h2 className="carousel-heading">{props.heading}</h2>
        <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* {props.userDetails && props.viewtype == "course" && (
              <CourseExploreSort
                chandleClickS={props.chandleClickS}
                canchorElS={props.canchorElS}
                handleCloseS={props.handleCloseS}
                sortTypeChange={props.sortTypeChange}
                cstypeV={props.cstypeV}
                cropenS={props.crOpenS}
                cstype={props.cstype}
                filter="true"
                viewtype="course"
                sorttp={props.userDetails}
              />
            )} */}
            {/* {props.viewtype == "course" && (
              <CourseExploreFilter
                handleClick={props.handleCourseClick}
                anchorEl={props.anchorCEl}
                handleClose={props.handleClose}
                contentTypeChange={props.contentTypeChange}
                AdHandleChange={props.AdHandleChange}
                cadProductF={props.cadProductF}
                DurHandleChange={props.DurHandleChange}
                crOpen={props.crOpen}
                cctype={props.cctype}
                sclear={props.sclear}
                ToolsHandleChange={props.ToolsHandleChange}
                cmrToolsF={props.cmrToolsF}
                cdurF={props.cdurF}
                filter="true"
                viewtype="course"
              />
            )} */}

            {/* {props.userDetails && props.viewtype == "content" && (
              <ExploreSort
                handleClickS={props.handleClickS}
                anchorElS={props.anchorElS}
                handleCloseS={props.handleCloseS}
                sortTypeChange={props.sortTypeChange}
                stypeV={props.stypeV}
                openS={props.openS}
                stype={props.stype}
                sorttp={props.userDetails}
              />
            )}

            {props.viewtype == "content" && (
              <ExploreFilter
                handleClick={props.handleClick}
                anchorEl={props.anchorEl}
                handleClose={props.handleClose}
                contentTypeChange={props.contentTypeChange}
                AdHandleChange={props.AdHandleChange}
                adProductF={props.adProductF}
                DurHandleChange={props.DurHandleChange}
                open={props.open}
                ctype={props.ctype}
                viewtype="content"
                clear={props.clear}
                ToolsHandleChange={props.ToolsHandleChange}
                mrToolsF={props.mrToolsF}
                durF={props.durF}
                visible={props.visible}
                hide={props.hide}
              />
            )} */}
          </div>
          {props.viewall !== "" && (
            // <Link
            //   to="#"
            //   style={{
            //     marginRight: "2rem",
            //     fontSize: "1.7rem",
            //     marginTop: "1rem",
            //     fontWeight: "600",
            //   }}
            //   className="viewAllActive"
            //   onClick={props.viewall}
            // >
            //   View all
            // </Link>

            <>
              <img
                onClick={props.viewall}
                style={{
                  marginRight: "2rem",
                  fontSize: "1.7rem",
                  marginTop: "1rem",
                  fontWeight: "600",
                  cursor: "pointer",
                  height: "30px",
                }}
                src={viewallicon}
                alt="ViewAll"
              />
            </>
          )}
        </div>
      </div>
      <AnimatePresence>
        <motion.div
          className="swiper_wrapper"
          variants={containerVarients}
          initial="hidden"
          animate="visible"
        >
          {!props.loading && props.data.length > 0 && (
            <Swiper
              ref={sliderRef}
              breakpoints={breakpoints}
              spaceBetween={8}
              slidesPerGroup={4}
              slidesPerView={4}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onInit={() => {
                console.log("Swiper initialized");
              }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: false }}
              className="slide-container"
            >
              {props.children}
            </Swiper>
          )}
          <span
            id="swiper-right"
            className={`arrow arrow-right ${
              props.data.length === 0 ? "hide" : ""
            }`}
            onClick={handleNext}
          ></span>
          <span
            id="swiper-left"
            className={`arrow arrow-left ${
              props.data.length === 0 ? "hide" : ""
            }`}
            onClick={handlePrev}
          ></span>
          <div className="flex-loading">
            {props.loading && (
              <React.Fragment>
                {[...Array(4).fill(0)].map((_, index) => (
                  <Loading />
                ))}
              </React.Fragment>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        {!props.loading && props.data.length === 0 && (
          <div>
            <AnimatePresence>
              {!props.loading && props.oCData.length === 0 && (
                <motion.div
                  className="slick-center"
                  style={{
                    color: "Grey",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, ease: "easeOut" },
                  }}
                >
                <img src={noThingIsHere} alt="noThingIsHere" />
                  {/* <h2 className="text">Nothing here yet!</h2> */}
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {!props.loading && props.oCData.length !== 0 && (
                <motion.div
                  className="slick-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, ease: "easeInOut" },
                  }}
                >
                  <img src={noThingIsHere} alt="noThingIsHere" />
                  <h2 className="text">Nothing here yet!</h2>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
export default React.memo(Slick);
// return (
//   <div className="carousel-container">
//     <div>
//       <h3 className="carousel-heading">
//         {props.heading !== "" ? <>{props.heading}</> : null}
//       </h3>

//       <div>
//         <div>
//           {props.sort === "true" && props.viewtype == "program" ? (
//             <div>
//               <ProgramExploreSort
//                 handleproClickS={props.handleproClickS}
//                 panchorElS={props.panchorElS}
//                 handleproCloseS={props.handleproCloseS}
//                 sortTypeChange={props.sortTypeChange}
//                 pstypeV={props.pstypeV}
//                 popenS={props.popenS}
//                 pstype={props.pstype}
//                 viewtype={props.viewtype}
//                 stype={props.stype}
//               />
//             </div>
//           ) : null}
//           <div>
//             {props.viewall !== "" ? (
//               <Link
//                 to="#"
//                 onClick={props.viewall}
//                 // className="viewAllActive"
//               >
//                 View all
//               </Link>
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="swiper_wrapper">
//       <Swiper
//         breakpoints={breakpoints}
//         slidesPerView={4}
//         slidesPerGroup={4}
//         navigation
//         pagination={{ clickable: true }}
//         scrollbar={{ draggable: false }}
//         className="slide-container"
//       >
//         {props.children}
//       </Swiper>
//     </div>
//   </div>
// );
