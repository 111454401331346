import * as React from "react";
import { useState } from "react";
import {
  Button,
  Badge,
  List,
  Backdrop,
  CircularProgress,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  IconButton,
  Container,
  SwipeableDrawer,
  Box,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import MenuIcon from "@material-ui/icons/Menu";
// import BookmarkIcon from '@material-ui/icons/Bookmark';
import MessageIcon from "@material-ui/icons/Message";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import CalendarMonthIcon from "@material-ui/icons/DateRange";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import ExploreIcon from "@material-ui/icons/Explore";
// import MessageOutlinedIcon from '@material-ui/icons/MessageOutlinedIcon';
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Header.scss";
import { API, Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn, awsSignOut } from "../../redux/auth/authSlice";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import config, { Theme } from "../../aws-exports";
import moment from "moment";
import Tippy from "@tippyjs/react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
//import ArrowBack from "@material-ui/icons/ArrowBack";
import { AccountCircle, ImportantDevices } from "@material-ui/icons";
//import { motion } from "framer-motion";
import { StorageHelper } from "@aws-amplify/core";

const ExploreButton = withStyles({
  root: {
    background: "transparent",
    border: 0,
    color: "white",
    height: "100%",
    // padding: '0 50px',
    fontSize: "16px",
    textTransform: "unset",
    fontWeight: "400",
    padding: ".5rem 0 .5rem .5rem",
  },
})(Button);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  root1: {
    width: "300px",
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    // border: '0.5px solid #777',

    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    marginTop: "5px",
  },
  root121: {
    maxHeight: "calc(100vh - (100vh/2))",
    overflowX: "hidden",
    overflowY: "auto",
    "@media (min-width: 426px)": {
      width: "300px",
      maxWidth: 360,
    },
  },
  searchOnMobile: {
    width: "100%",
    maxHeight: "calc(100vh - (100vh/2))",
    overflowX: "hidden",
    overflowY: "auto",
  },
  root123: {
    width: "300px",
    maxWidth: 360,
  },
  root12: {
    width: "130px",
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
    // border: '0.5px solid #777',
    fontSize: "1.5rem",

    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    marginTop: "-5px",
  },
  accountButton: {
    position: "relative",
    minWidth: "auto",
    "@media (max-width: 500px)": {
      padding: 0,
    },
  },
  small: {
    width: theme.spacing(3.8),
    height: theme.spacing(3.8),
    "@media (max-width: 500px)": {
      width: "2rem",
    },
  },
  medium: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  badge: {
    "& .MuiBadge-badge": {
      background: "#fff",
      fontSize: "1rem",
      fontWeight: "bold",
      "&:active": {
        color: "#fff",
      },
    },
  },
  headerIcon: {
    color: "white",
    fontSize: "3rem",
    "@media (max-width: 500px)": {
      fontSize: "2.5rem",
    },
  },
  body1: {
    "& .MuiTypography-body1": {
      fontSize: "1.5rem",
      lineHeight: "1.3",
      fontFamily: `myfontregular`,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  exploreContainer: {
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 770px)": {
      display: "none",
    },
  },
  responsiveMenu: {
    "@media (max-width: 770px)": {
      display: "none",
    },
  },
  menuIconResponsive: {
    "@media (min-width: 770px)": {
      display: "none",
    },
  },
  tippyMobileSearch: {
    marginTop: "1.7rem",
    // '@media (max-width: 426px)': {
    //   marginTop: 0,
    // },
  },
  MenuIconList: {
    textDecoration: "none",
    "& li > button > span > div > svg": {
      width: "2.5rem",
      height: "2.5rem",
      color: `var(--color-primary)`,
    },
    "& li > button > span > div > span": {
      fontSize: "1.3rem",
    },
    "& div > button > span > div > svg": {
      width: "2.5rem",
      height: "2.5rem",
      color: `var(--color-primary)`,
    },
    "& div > button > span > div > span": {
      fontSize: "1.3rem",
    },
  },
  menuLogo: {
    width: "60%",
    padding: "0.3rem 0rem 0rem",
    margin: "auto",
  },
}));
const defaultTippyProps = {
  // animation: "shift-away-subtle",
  trigger: "mouseenter click",
  arrow: false,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,
  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
function Header() {
  const location = useLocation();
  const { data } = useSelector((state) => state.category);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openN, setOpenN] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [notification, setNotification] = React.useState([]);
  const [unReadNotify, setUnReadNotify] = React.useState([]);
  const [notRead, setNotRead] = React.useState(0);
  const [list, setList] = useState();
  const [headerLoader, setHeaderLoader] = useState(false);
  const [drawerState, setDrawerState] = React.useState(false);
  let userDetails = useSelector(authData);
  //const [visible, setVisible] = useState(false);
  //const show = () => setVisible(!visible);
  const [headerSearch, setHeaderSearch] = useState(false);
  const theme = useTheme();
  console.log(theme);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const storage = new StorageHelper().getStorage();

  const hide = (category) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "Explore",
      topicName: category.name,
      topicId: category.id,
    };
    delete breadcrumb.filter;
    dispatch(awsSignIn(breadcrumb));
    // setVisible(false);
  };

  React.useEffect(() => {
    getNotifications();
    listBookmark();
  }, []);

  const signingOut = () => {
    Auth.signOut();
    dispatch(awsSignOut());
    setHeaderLoader(true);
    getUserData();
    //history.push('/');
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const prevOpenN = React.useRef(openN);
  React.useEffect(() => {
    if (prevOpenN.current === true && openN === false) {
      anchorRef.current.focus();
    }
    prevOpenN.current = openN;
  }, [openN]);

  const [search, setSearch] = useState("");

  async function getNotifications() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.username,
        tenant: userDetails.locale,
        schema: config.schema,
        ur_id: userDetails.uData?.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getNotifications",
        bodyParam
      );

      const { notifications } = response;
      var unrad = [];
      const notiBadge = notifications.filter((notify) => {
        if (notify.read === 0 || notify.read === "0") {
          unrad.push(notify.nt_id);
          return true;
        }
      });
      setUnReadNotify(unrad);
      setNotRead(notiBadge.length);
      const decNotification = notifications.sort((a, b) => {
        const aTime = parseInt(a.time);
        const bTime = parseInt(b.time);
        return bTime - aTime;
      });
      console.log("notifications", notifications);
      setNotification(decNotification);
    } catch (err) {
      console.error(err);
    }
  }
  async function notificationStatus() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.username,
        tenant: userDetails.locale,
        schema: config.schema,
        ur_id: userDetails.uData?.ur_id,
        unread: unReadNotify,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      if (unReadNotify.length > 0) {
        await API.post(
          config.aws_cloud_logic_custom_name,
          "/postNotificationStatusWeb",
          bodyParam
        );
        setUnReadNotify([]);
        setNotRead(0);
      }
    } catch (err) {
      console.error(err);
    }
  }
  function formatUrl(url) {
    var httpString = "http://",
      httpsString = "https://";
    if (
      url.substr(0, httpString.length) !== httpString &&
      url.substr(0, httpsString.length) !== httpsString
    )
      url = httpString + url;

    return url;
  }
  const openNotification = (msg) => {
    if (msg.type === 1) {
      history.push(`/course?id=${msg.tid}`);
    } else if (msg.link) {
      window.open(formatUrl(msg.link), "_blank");
    } else {
      Swal.fire({
        title: msg.title,
        text: msg.msg,
        width: "400px",
        showConfirmButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "confirmButton",
          title: "swalTitle",
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
      });
    }
  };
  async function listBookmark() {
    const bodyParam = {
      body: {
        ur_id: userDetails?.uData?.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listBookMark",
        bodyParam
      );
      const { items } = response;

      setList(items);
    } catch (err) {
      console.error(err);
    }
  }

  async function getUserData() {
    Cookies.remove("CloudFront-Policy");
    Cookies.remove("CloudFront-Key-Pair-Id");
    Cookies.remove("CloudFront-Signature");
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("id_token");
    var padmin = localStorage.getItem("persist:adminRoot");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root");
    storage.removeItem("persist:root");
    localStorage.removeItem(config.aws_cloudfront_url);
    window.onbeforeunload = () => {
      localStorage.clear();
    };
    padmin !== null && localStorage.setItem("persist:adminRoot", padmin);
    console.log(padmin, "logout");
    if (config.aws_org_id != "AXIATA") {
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      window.location.href = `${config.auth_url}logout?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&state=STATE&scope=email+openid+profile+aws.cognito.signin.user.admin`;
      //window.location.href = `https://devexcelsoft.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=4kf26chr0acga63b7bjade31mm&redirect_uri=https://www.excelearn.enhanzed.com&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
      //window.location.href = `https://axiata.auth.ap-south-1.amazoncognito.com/logout?response_type=code&client_id=unrg40t8sotki8vngipar91uf&redirect_uri=https://www.axiata.enabled.live&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
    }
    //window.location.href = config.aws_cloudfront_lurl;
  }
  const handleSearchBread = (search) => {
    let breadcrumb = { ...userDetails };

    const searchTopic = search.trim();

    if (searchTopic.length !== 0) {
      breadcrumb.location = {
        from: "Search",
        topicName: searchTopic,
        topicId: "search",
      };
      delete breadcrumb.filter;
      dispatch(awsSignIn(breadcrumb));
      setSearch("");
      history.push(`/explore/${encodeURIComponent(search.trim())}/search`);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  const DrawerList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding style={{ padding: 0 }}>
            <img
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/${
                config.aws_org_id
              }.png`}
              alt="marketron logo"
              className={classes.menuLogo}
              onClick={() => history.push("/home")}
            />
          </ListItem>
          <Divider style={{ margin: "0.8rem 0rem" }} />
          <Link to={`/viewAllTopics`} className={classes.MenuIconList}>
            <ListItem disablePadding>
              <Button>
                <ListItemIcon>
                  <ExploreIcon />
                </ListItemIcon>
                <ListItemText primary="Explore" />
              </Button>
            </ListItem>
          </Link>

          {/* <Link to={`/discussion`} className={classes.MenuIconList}>
          <ListItem disablePadding>
            <Button>
              <ListItemIcon>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText primary="Discussion" />
            </Button>
          </ListItem>
        </Link> */}

          <Link to={`/profile`} className={classes.MenuIconList}>
            <ListItem disablePadding>
              <Button>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </Button>
            </ListItem>
          </Link>

          <Link to={`/bookmarks`} className={classes.MenuIconList}>
            <ListItem disablePadding>
              <Button>
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>
                <ListItemText primary="Bookmarks" />
              </Button>
            </ListItem>
          </Link>

          <Link to={`/calender`} className={classes.MenuIconList}>
            <ListItem disablePadding>
              <Button>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText primary="Calendar" />
              </Button>
            </ListItem>
          </Link>

          <Link className={classes.MenuIconList}>
            <ListItem
              disablePadding
              button
              onClick={() => {
                signingOut();
              }}
            >
              <Button>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </Button>
            </ListItem>
          </Link>
        </List>
      </nav>
      <Divider />
    </Box>
  );

  return (
    <>
      {headerLoader ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}

      <div className="headerbackcolor">
        <Container maxWidth="xl">
          <header className="header">
            <div className={classes.menuIconResponsive}>
              <MenuIcon
                className={classes.headerIcon}
                onClick={toggleDrawer("left", true)}
              />
              <SwipeableDrawer
                anchor={"left"}
                open={drawerState}
                onClose={toggleDrawer("left", false)}
                onOpen={toggleDrawer("left", true)}
              >
                {DrawerList("left")}
              </SwipeableDrawer>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                to="/home"
                style={{ display: "flex", gap: 0.5, alignItems: "center" }}
              >
                <img
                  src={`https://${
                    config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-light.png`}
                  alt="marketron logo"
                  className="logo"
                />
                {config.aws_org_id?.toLowerCase() === "icici" && (
                  <img
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/App-icon.png`}
                    alt="logo"
                    height="42.5px"
                  />
                )}
              </Link>
              <Tippy
                content={
                  <div className={classes.root1}>
                    <List
                      component="nav"
                      aria-label="main mailbox folders"
                      className={classes.body1}
                    >
                      {data.map((category) => (
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "Nunito Sans",
                          }}
                          to={`/explore/${category.name.replace(/\s/g, "-")}/${
                            category.id
                          }`}
                          onClick={() => hide(category)}
                        >
                          <ListItem button>
                            <ListItemText primary={category.name} />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </div>
                }
                {...defaultTippyProps}
              >
                <div className={classes.exploreContainer}>
                  <ExploreButton variant="text" color="primary" className="bt">
                    Explore
                    <ExpandMoreIcon
                      style={{ fontSize: "3rem", marginLeft: "1rem" }}
                    />
                    <span
                      style={{
                        width: "88px",
                        position: "absolute",
                        bottom: "0px",
                        left: " 5px",
                        borderBottom: "1px solid #fff",
                      }}
                    ></span>
                  </ExploreButton>
                </div>
              </Tippy>
            </div>
            <div className={`search ${matches && "hide"}`}>
              <input
                type="search"
                className="search__input"
                placeholder="Search ..."
                value={search}
                style={{ padding: "7px 36px 7px 20px", width: "27rem" }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    if (search != "") {
                      handleSearchBread(search);
                    }
                  }
                }}
              />
              <button
                className="search__button"
                onClick={() => {
                  if (search != "") {
                    handleSearchBread(search);
                  }
                }}
              >
                <SearchIcon
                  style={{
                    fontSize: "2.6rem",
                    marginTop: "0.5rem",
                    color: "#217fb5",
                  }}
                />
              </button>
            </div>
            <nav className="user-nav">
              {matches && (
                <div>
                  <Tippy
                    className={classes.tippyMobileSearch}
                    content={
                      <div style={{ padding: "10px 0px" }}>
                        <input
                          type="search"
                          className="search__inputMobile"
                          placeholder="Type here ..."
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              if (search != "") {
                                handleSearchBread(search);
                              }
                            }
                          }}
                        />
                        <button
                          className="search__buttonMobile"
                          onClick={() => {
                            if (search != "") {
                              handleSearchBread(search);
                            }
                          }}
                        >
                          Search
                        </button>
                      </div>
                    }
                    {...defaultTippyProps}
                  >
                    <IconButton
                      onClick={() => {
                        setHeaderSearch(true);
                      }}
                    >
                      {/* Not working */}
                      <SearchIcon className={classes.headerIcon} />
                    </IconButton>
                  </Tippy>
                </div>
              )}
              {/* <div className={classes.responsiveMenu}>
                  <Link to={`/discussion`}>
                    <Badge
                      className={classes.badge}
                      max={9}
                    >
                      {location.pathname === "/discussion" ? (
                        <MessageIcon className={classes.headerIcon} />
                      ) : (
                        <MessageOutlinedIcon className={classes.headerIcon} />
                      )}
                    </Badge>
                  </Link>
                </div> */}
              {!matches && (
                <div>
                  <Link to={`/bookmarks`}>
                    <Badge className={classes.badge} max={9}>
                      {location.pathname === "/bookmarks" ? (
                        <BookmarkIcon className={classes.headerIcon} />
                      ) : (
                        <TurnedInNotIcon className={classes.headerIcon} />
                      )}
                    </Badge>
                  </Link>
                </div>
              )}
              <div>
                <Tippy
                  content={
                    <div
                      className={
                        notification === false || notification.length === 0
                          ? classes.root123
                          : classes.root121
                      }
                      style={{ padding: "10px" }}
                    >
                      <List
                        component="nav"
                        aria-label="main mailbox folders"
                        className={classes.body1}
                      >
                        {notification === false || notification.length === 0 ? (
                          <div style={{ fontFamily: `myfontregular` }}>
                            No Notification
                          </div>
                        ) : (
                          notification.map((notific) => {
                            return (
                              <div>
                                <div
                                  onClick={() => openNotification(notific)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      paddingBottom: "10px",
                                      paddingTop: "10px",
                                      fontFamily: `myfontregular`,
                                    }}
                                  >
                                    <div style={{ fontSize: "15px" }}>
                                      {notific.title}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        color: "rgb(119, 119, 119)",
                                      }}
                                    >
                                      {notific.time !== undefined &&
                                        notific.time !== null &&
                                        moment(parseInt(notific.time)).format(
                                          "DD MMM YYYY"
                                        )}
                                    </div>
                                  </div>
                                  <div style={{ fontSize: "14px" }}>
                                    {notific.msg}
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    backgroundColor: "gray",
                                    marginTop: "6px",
                                  }}
                                />
                              </div>
                            );
                          })
                        )}
                      </List>
                    </div>
                  }
                  {...defaultTippyProps}
                >
                  <div
                    style={{
                      height: "inherit",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Badge
                      badgeContent={
                        notRead === undefined || notRead === 0 ? 0 : notRead
                      }
                      className={classes.badge}
                      max={9}
                    >
                      <NotificationsNoneIcon
                        className={classes.headerIcon}
                        onMouseEnter={notificationStatus}
                      />
                    </Badge>
                  </div>
                </Tippy>
              </div>
              <div className={classes.responsiveMenu}>
                <Tippy
                  content={
                    <div className={classes.root12}>
                      <List style={{ fontSize: "14px" }}>
                        <ListItem
                          button
                          onClick={() => history.push("/profile")}
                        >
                          Profile
                        </ListItem>
                        <Link
                          to={`/calender`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <ListItem button>Calendar</ListItem>
                        </Link>
                        {matches && (
                          <Link
                            to={`/bookmarks`}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <ListItem button>Bookmark</ListItem>
                          </Link>
                        )}
                        <ListItem
                          button
                          onClick={() => {
                            signingOut();
                          }}
                        >
                          Logout
                        </ListItem>
                      </List>
                    </div>
                  }
                  {...defaultTippyProps}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className={`user-nav__user-name ${matches && "hide"}`}>
                      {userDetails.name}
                    </p>
                    <Button className={classes.accountButton}>
                      <AccountCircle
                        style={{ color: "white" }}
                        className={classes.small}
                      />
                    </Button>
                  </div>
                </Tippy>
              </div>
            </nav>
          </header>
        </Container>
      </div>
    </>
  );
}

export default Header;
