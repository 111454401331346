import React, { useState, useRef, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import {
  Button,
  CardMedia,
  Typography,
  Fade,
  CircularProgress,
  Modal,
  Box,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";
import { API, Auth } from "aws-amplify";
//import ReactPlayer from "react-player";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useHistory } from "react-router-dom";
//import useWindowDimensions from "../Window/Window";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
import moment from "moment";
import ENABLED from "../../assets/ENABLED.png";
import EXCELSOFT from "../../assets/EXCELSOFT.png";
import AXIATA from "../../assets/AXIATA.png";
import axios from "axios";
//import { saveAs } from "file-saver";
import "../../components/Cards/CardSmall/CardSmall.scss";
// Swiper
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
//import { breakpoints } from "../../components/Carousel/utils/Breakpoints";
import CertificatePopUp from "../../components/ModalPopUp/CertificatePopUp";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPdf from "react-to-pdf";

import "./ProfilePopUp.scss";
const options = {
  orientation: "landscape",
};
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "2rem 1rem",
    width: "60%",
    borderRadius: ".8rem",
    outline: "none",
    "@media (max-width: 770px)": {
      width: "90%",
    },
    "@media (max-width: 370px)": {
      width: "95%",
    },
  },
  ratingroot: {
    width: "87.5vw",
    height: "80%",
    fontSize: "30px",
    textAlign: "center",
    backgroundColor: "#fff",
    padding: " 0 30px 30px 0px",
  },
  certificateSlider: {
    border: "1px solid #000",
    padding: "6% 18%",
    "@media (max-width: 1200px)": {
      padding: "4% 12%",
    },
    "@media (max-width: 900px)": {
      padding: "2% 6%",
    },
  },
  certificateSliderSection: {
    position: "relative",
    width: "100%",
  },
  certificateSliderContainer: {
    width: "100%",
    height: "50vh",
    textAlign: "center",
    display: "flex",
    padding: "2%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "max-content",
    overflowY: "hidden",
    backgroundColor: "#ffffff",
  },
  certificateImage: {
    "@media (max-width: 500px)": {
      width: "80%",
      height: "80%",
    },
  },
}));
export default function ProfilePopUp({
  profileDetails,
  open,
  setOpen,
  isloading,
  setIsLoading,
  certificateList,
  sindex,
}) {
  const swiperRef = useRef(null);
  const ref = useRef();
  const certificateDiv = React.useRef();
  const classes = useStyles();
  console.log(profileDetails);

  let src = `${
    config.aws_cloudfront_url
  }${config.aws_org_id.toLowerCase()}-resources/certificate/`;
  //const history = useHistory();
  let userDetails = useSelector(authData);
  useEffect(() => {
    console.log("===" + sindex);
    //swiperRef.current.slideTo(sindex -1, 0);
    console.log(profileDetails);
  }, []);

  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function downloadPdf() {
    let filename, type, durl;
    type = "image/png";
    filename = profileDetails.TN + profileDetails.url.split(".").pop();

    console.log(filename);

    // axios
    //   .get(profileDetails.url, {
    //     responseType: 'blob',
    //     headers: {
    //       'Access-Control-Allow-Origin':'*',
    //       'Access-Control-Allow-Credentials': 'true',
    //       'Access-Control-Request-Headers': 'Content-Type',
    //       'Content-Type': type,
    //     },
    //   })
    //   .then(res => {
    //     console.log(res);
    //     let file = new Blob([res], { type: type });
    //     saveAs(file, filename);
    //   });
  }
  async function downloadcode(sData, idx) {
    console.log("sData==", sData);
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#certpdf" + idx), {
      allowTaint: true,
      useCORS: true,
      logging: true,
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight + 200);
    pdf.save(
      sData?.ttitle?.length >= 90
        ? sData?.ttitle.substring(0, 90)
        : sData?.ttitle + ".pdf"
    );
  }

  return (
    <div>
      {config.aws_org_id !== "AXIATA" && (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <Swiper
                  ref={swiperRef}
                  spaceBetween={30}
                  initialSlide={sindex}
                  pagination={{ clickable: true }}
                  navigation={true}
                  className="slider-container"
                >
                  {/* onClick={() => downloadPdf(ourl)} */}
                  {certificateList.map((item, idx) => (
                    <SwiperSlide key={idx} className="slider-container">
                      <div style={{ textAlign: "right", paddingRight: "20px" }}>
                        <button
                          style={{
                            background: "transparent",
                            border: "none",
                            height: "3rem",
                            width: "3rem",
                            marginRight: "2rem",
                            cursor: "pointer",
                            marginTop: "8px",
                          }}
                          onClick={() => downloadcode(item, idx)}
                        >
                          <img
                            src={downloadPdfSvg}
                            alt="download"
                            height="100%"
                          />
                        </button>
                        <button
                          onClick={handleClose}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <CloseIcon fontSize="large" />
                        </button>
                      </div>
                      <div className={classes.certificateSliderSection}>
                        <div>
                          <div
                            className={classes.certificateSliderContainer}
                            id={"certpdf" + idx}
                            ref={certificateDiv}
                          >
                            <div className={classes.certificateSlider}>
                              <div className="img-c">
                                <img
                                  src={
                                    config.aws_org_id === "ENABLED"
                                      ? ENABLED
                                      : config.aws_org_id === "EXCELEARN"
                                      ? EXCELSOFT
                                      : AXIATA
                                  }
                                  alt="logo"
                                  height="100"
                                  className={classes.certificateImage}
                                />
                              </div>
                              <div className="org-container">
                                <p>Certificate awarded to</p>
                              </div>
                              <h1 className="name">{userDetails.name}</h1>
                              <p className="complete">
                                for successfully completing
                              </p>
                              <div className="title-container"></div>

                              <p className="date">
                                Issued on{" "}
                                {moment(item?.comp_date).format("DD MMM YYYY")}
                              </p>
                              <div className="img-c">
                                {/* <img src={config.aws_org_id==='ENABLED' ? ENSIGN : config.aws_org_id==='EXCELSOFT' ? EXSIGN : AXSIGN} alt="signature" height="130" /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Fade>
          </Modal>
        </>
      )}
      {config.aws_org_id === "AXIATA" && (
        <Dialog open={open} maxWidth="xl" scroll="body" onClose={handleClose}>
          <DialogContent className="dialog-container">
            <>
              <div
                className="course-certificate"
                ref={ref}
                id={"#certpdf" + profileDetails.tid}
              >
                <div className="course-certificate_container">
                  <h1 className="course-certificate_container_name">
                    {profileDetails.name}
                  </h1>
                </div>

                <div className="course-certificate_details">
                  <div className="course-certificate_details_top">
                    <p className="course-certificate_details_top-head">
                      has completed the course
                    </p>
                    <p className="course-certificate_details_top-title">
                      {profileDetails?.ttitle}
                    </p>
                  </div>

                  <div className="course-certificate_details_down">
                    <p className="course-certificate_details_down-date">
                      Date :{" "}
                      {moment(profileDetails?.compdate).format("DD/MM/YYYY")}{" "}
                    </p>
                    <p className="course-certificate_details_down-grade">
                      Completion Progress : 100%
                    </p>
                  </div>
                </div>
              </div>

              <div className="certificate_actions">
                <ReactToPdf
                  targetRef={ref}
                  filename="certificate.pdf"
                  options={options}
                  x={8}
                  y={8}
                  scale={1}
                >
                  {({ toPdf }) => (
                    <button className="certificate_actions-btn" onClick={toPdf}>
                      Download
                    </button>
                  )}
                </ReactToPdf>
              </div>
            </>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
