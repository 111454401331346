import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Header from "../../components/Header/Header";
import { useHistory, Link, useLocation } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Button from "@material-ui/core/Button";
import { API } from "aws-amplify";
import config, { Theme } from "../../aws-exports";

import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import BookmarkIcon from "@material-ui/icons/Bookmark";

import {
  Box,
  makeStyles,
  Typography,
  Breadcrumbs,
  Grid,
} from "@material-ui/core";
import { RatingPopUp } from "../../components/ModalPopUp/ModalPopUp";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//React router
import { useParams } from "react-router-dom";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import { Skeleton } from "@material-ui/lab";
import ExploreSort from "./ExploreSort/ExploreSort";
import ExploreFilter from "./ExploreFilter/ExploreFilter";
import { addMethod } from "yup";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: "12px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ExploreCourseView() {
  const userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [ctype, setCtypeF] = useState("");
  const [cadProductF, setCAdProductF] = useState("");
  const [cmrToolsF, setCMrToolsF] = useState("");
  const [cdurF, setCDurF] = useState("");

  const [anchorElS, setAnchorElS] = useState(null);
  const [openS, setOpenS] = useState(false);
  const [cstype, setCStypeF] = useState("CON");
  const [cstypeV, setCStypeFV] = useState("Select");
  const query = useQuery();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [courseData, setCourseData] = useState([]);
  const [oCourseData, setOCourseData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  const [program, setProgram] = useState(true);
  const [courses, setCourses] = useState([]);
  const [proGm, setProGm] = useState({});
  const [errorD, setErrorD] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  let { name, id } = useParams();
  const history = useHistory();
  // let courseName = name.replace('-', ' ');
  useEffect(() => {
    if (!userDetails || userDetails === 0) {
      let obj = {};
      obj.type = "program";
      obj.pid = query.get("id");
      if (query.get("from") !== undefined) {
        obj.from = 0;
      }
      localStorage.setItem("deeplink", JSON.stringify(obj));
      setErrorD(1);

      if(config.aws_org_id == 'AXIATA'){
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    } else {
      listPrograms();
      if (Object.keys(userDetails.location).length == 0) {
        setErrorD(0);
        console.log("hi");
      }
    }

    //edGetCategoryData();
  }, []);
  function filterdata() {
    if (userDetails.curExploreProgramData != undefined) {
      setCourseData(userDetails.curExploreProgramData.courses);
      setOCourseData(userDetails.curExploreProgramData.courses);
      console.log("filter==" + JSON.stringify(userDetails.filter));

      if (
        userDetails.filter !== undefined &&
        userDetails.filter.setCAdProductF !== undefined &&
        userDetails.filter.setCAdProductF != ""
      ) {
        let tstype =
          userDetails.filter.setCStypeF === undefined &&
          userDetails.filter.setCStypeF != ""
            ? "CON"
            : userDetails.filter.setCStypeF;
        setCAdProductF(userDetails.filter.setCAdProductF);
        setCMrToolsF(userDetails.filter.setCMrToolsF);
        setCDurF(userDetails.filter.setCDurF);
        setCtypeF(userDetails.filter.setCtypeF);
        setCStypeF(userDetails.filter.setCStypeF);

        if (
          userDetails.filter.setCAdProductF != "" ||
          userDetails.filter.setCMrToolsF != "" ||
          userDetails.filter.setCDurF != ""
        ) {
          filter(
            "",
            userDetails.filter.setCAdProductF,
            userDetails.filter.setCMrToolsF,
            userDetails.filter.setCDurF
          );
        } else {
          sortFun(tstype, userDetails.curExploreProgramData.courses);
        }
        commansetsort(tstype);
      }

      if (
        userDetails.curExploreProgramData.from != "search" &&
        userDetails.filter !== undefined &&
        userDetails.filter.setCAdProductF !== undefined &&
        userDetails.filter.setCAdProductF != ""
      ) {
      } else {
        setCStypeFV("Newest");

        if (userDetails.curExploreProgramData.PNAME !== "Courses") {
          setCStypeF("sqno");
          sortFun("sqno", userDetails.curExploreProgramData.courses);
        } else {
          setCStypeF("CON");
          sortFun("CON", userDetails.curExploreProgramData.courses);
        }

        console.log(
          "course===" +
            JSON.stringify(userDetails.curExploreProgramData.courses)
        );
      }
    } else if (userDetails === 0) {
      //history.push(`/`);
      window.location.href = "https://learning.marketron.com";
    }
  }
  const listPrograms = async () => {
    setIsLoading(true);
    const bodyParam = {
      body: {
        tenant: userDetails.locale,
        oid: config.aws_org_id,
        pid: query.get("id"),
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listPrograms",
        bodyParam
      );

      const { programs } = response;
      let courses = programs[0].courses;
      console.log(JSON.stringify(courses));
      setProGm(programs);

      if (query.get("from") == 0) {
        getUserDataWeb(courses);
      } else {
        courses.sort((a, b) => Number(b["sqno"]) - Number(a["sqno"])).reverse();
        setCourses(courses);
        setOCourseData(courses);
        //filterdata();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };
  async function getUserDataWeb(coursedt) {
    // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
    const bodyParam = {
      body: {
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        tenant: userDetails.locale,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    //, Authorization: jwttoken,

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserDataWeb",
        bodyParam
      );

      var json = JSON.stringify(response);
      var topic = json.toString();

      if (topic.substring(1, 10) == "No Topics") {
        setCourses(coursedt);
        setIsLoading(false);
      } else {
        const { Records } = JSON.parse(topic);
        console.log(Records);

        let result = coursedt.filter((o1, index) =>
          Records.some((o2) => {
            if (o1.tid === o2.Key) {
              let value = JSON.parse(o2.Value);
              var obj = o1;
              obj.tp = value.tp;
              obj.tobj = value.tobj;
              obj.cobj = value.cobj;
              coursedt[index] = obj;
            }
          })
        );
        console.log(JSON.stringify(coursedt, null, 2));
        coursedt
          .sort((a, b) => Number(b["sqno"]) - Number(a["sqno"]))
          .reverse();
        setCourses(coursedt);
        setOCourseData(coursedt);
        //filterdata();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  }
  const handleLocation = (TID, TN, pro) => {
    let breadcrumb = { ...userDetails };
    if (userDetails !== undefined) {
      if (
        userDetails.location !== undefined &&
        Object.keys(userDetails.location).length == 0
      ) {
        breadcrumb.location = {
          from: "Home",
          courseName: TN,
          programName: pro.pname,
          programId: pro.pid,
        };
        if (query.get("from") === 0 || query.get("from") === "0") {
          breadcrumb.location.pFrom = `/ExploreCourseView?from=0&id=${pro.pid}`;
        } else {
          breadcrumb.location.pFrom = `/ExploreCourseView?id=${pro.pid}`;
        }
      } else {
        breadcrumb.location = {
          from: breadcrumb.location.from,
          topicName: breadcrumb.location.topicName,
          topicId: breadcrumb.location.topicId,
          programName: breadcrumb.location.programName,
          programId: breadcrumb.location.programId,
          courseName: TN,
          viewAll: breadcrumb.location.viewAll,
          viewAllId: breadcrumb.location.viewAllId,
          pFrom: breadcrumb.location.pFrom,
        };
      }
    }

    console.log(breadcrumb);
    dispatch(awsSignIn(breadcrumb));
    history.push(`course?id=${TID}`);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  const contentTypeChange = (event) => {
    setCtypeF(event.target.value);
    let val = event.target.value;
    filter(val, cadProductF, cmrToolsF, cdurF);
  };

  const AdHandleChange = (event) => {
    let val = event.target.value;

    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setCStypeF = cstype;
    if (cadProductF == event.target.value) {
      setCAdProductF("");
      val = "";
      filterstoredata.filter.setCAdProductF = "";
    } else {
      setCAdProductF(event.target.value);
      filterstoredata.filter.setCAdProductF = event.target.value;
    }
    cmnsetfilter(filterstoredata);
    dispatch(awsSignIn(filterstoredata));
    filter("", val, cmrToolsF, cdurF);
  };
  async function cmnsetfilter(filterstoredata) {
    if (userDetails.filter !== undefined) {
      filterstoredata.filter.setDurF = userDetails.filter.setDurF;
      filterstoredata.filter.setMrToolsF = userDetails.filter.setMrToolsF;
      filterstoredata.filter.setStypeF = userDetails.filter.setStypeF;
      filterstoredata.filter.setCtypeF = userDetails.filter.setCtypeF;
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setAdProductF = userDetails.filter.setAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }
  }

  const ToolsHandleChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setCStypeFV = cstypeV;
    if (cmrToolsF == event.target.value) {
      setCMrToolsF("");
      val = "";
      filterstoredata.filter.setCMrToolsF = "";
    } else {
      setCMrToolsF(event.target.value);
      filterstoredata.filter.setCMrToolsF = event.target.value;
    }
    cmnsetfilter(filterstoredata);
    dispatch(awsSignIn(filterstoredata));
    filter("", cadProductF, val, cdurF);
  };

  const DurHandleChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setCStypeFV = cstypeV;
    if (cdurF == event.target.value) {
      setCDurF("");
      val = "";
      filterstoredata.filter.setCDurF = "";
    } else {
      setCDurF(event.target.value);
      filterstoredata.filter.setCDurF = event.target.value;
    }
    cmnsetfilter(filterstoredata);
    dispatch(awsSignIn(filterstoredata));
    filter("", cadProductF, cmrToolsF, val);
  };

  function filter(otype, adprod, mrtool, dur) {
    let data = [...oCourseData];

    let filteredData = [];
    console.log("otype== " + otype + adprod + mrtool + dur);

    filteredData = data.filter(function(e) {
      let obj = { ...e };

      obj.adproducts = e.adproducts != undefined ? e.adproducts : [];
      obj.mrtools = e.mrtools != undefined ? e.mrtools : [];

      obj.tduration = e.tduration != undefined ? e.tduration : 0;
      let res = false;
      let o = false;
      let a = false;
      let m = false;
      let d = false;

      console.log("courseobj " + obj.adproducts);

      // if (otype != "") {
      //   if (otype == obj.OTYPE) {
      //     o = true;
      //   }
      // }

      if (adprod != "") {
        if (obj.adproducts != undefined) {
          for (let i = 0; i < obj.adproducts.length; i++) {
            if (adprod == obj.adproducts[i]) {
              a = true;
              break;
            }
          }
        }
      }

      if (mrtool != "") {
        if (obj.mrtools != undefined) {
          for (let i = 0; i < obj.mrtools.length; i++) {
            if (mrtool == obj.mrtools[i]) {
              m = true;
              break;
            }
          }
        }
      }

      if (dur != "") {
        if (dur == "5") {
          if (obj.tduration < 300) {
            d = true;
          }
        } else if (dur == "15") {
          if (obj.tduration < 900) {
            d = true;
          }
        } else if (dur == "30") {
          if (obj.tduration < 1800) {
            d = true;
          }
        } else if (dur == "30_60") {
          if (obj.tduration >= 1800 && obj.tduration <= 3600) {
            d = true;
          }
        } else if (dur == "60") {
          if (obj.tduration > 3600) {
            d = true;
          }
        }
      }

      // All Value
      if (otype != "" && adprod != "" && mrtool != "" && dur != "") {
        if (o && a && m && d) {
          res = true;
        }
      }

      // otype adprod mrtool
      if (otype != "" && adprod != "" && mrtool != "" && dur == "") {
        if (o && a && m) {
          res = true;
        }
      }

      // otype adprod  dur
      if (otype != "" && adprod != "" && mrtool == "" && dur != "") {
        if (o && a && d) {
          res = true;
        }
      }

      // otype  mrtool dur
      if (otype != "" && adprod == "" && mrtool != "" && dur != "") {
        if (o && m && d) {
          res = true;
        }
      }

      //  adprod mrtool dur
      if (otype == "" && adprod != "" && mrtool != "" && dur != "") {
        if (a && m && d) {
          res = true;
        }
      }

      // otype adprod
      if (otype != "" && adprod != "" && mrtool == "" && dur == "") {
        if (o && a) {
          res = true;
        }
      }

      //  adprod mrtool
      if (otype == "" && adprod != "" && mrtool != "" && dur == "") {
        if (a && m) {
          res = true;
        }
      }

      //   mrtool dur
      if (otype == "" && adprod == "" && mrtool != "" && dur != "") {
        if (m && d) {
          res = true;
        }
      }

      // otype  dur
      if (otype != "" && adprod == "" && mrtool == "" && dur != "") {
        if (o && d) {
          res = true;
        }
      }

      // otype
      if (otype != "" && adprod == "" && mrtool == "" && dur == "") {
        if (o) {
          res = true;
        }
      }

      // adprod
      if (otype == "" && adprod != "" && mrtool == "" && dur == "") {
        if (a) {
          res = true;
        }
      }

      // mrtool
      if (otype == "" && adprod == "" && mrtool != "" && dur == "") {
        if (m) {
          res = true;
        }
      }

      // dur
      if (otype == "" && adprod == "" && mrtool == "" && dur != "") {
        if (d) {
          res = true;
        }
      }
      if (adprod == "" && mrtool == "" && dur == "") {
        res = true;
      }

      return res;
    });

    setCourses(filteredData);
    let valstype = cstype;
    if (
      userDetails.filter !== undefined &&
      userDetails.filter.setCStypeF !== undefined
    ) {
      valstype = userDetails.filter.setCStypeF;
    }
    sortFun(valstype, filteredData);

    setOpenSnackbar(true);
  }

  function clear() {
    setCourses(oCourseData);
    setCtypeF("");
    setCAdProductF("");
    setCMrToolsF("");
    setCDurF("");
    setCStypeF(cstype);
    setCStypeFV(cstypeV);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));

    sortFun(cstype, oCourseData);
  }

  function filterby() {
    return (
      <div style={{ width: "115px" }}>
        <ListItem button onClick={handleClick}>
          {/* <ListItemText className={classes.heading} primary="Filter By" /> */}
          <Typography className={classes.heading}>Filter By</Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ maxHeight: "80%" }}
        >
          <ListItem button>
            <div style={{ width: "200px" }}>
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography className={classes.heading}>Content Type</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <FormControl component="fieldset">

                      <RadioGroup aria-label="ContentType" name="ContentType" value={ctype} onChange={contentTypeChange}>
                        <FormControlLabel classes={{label: classes.label}} value="html" control={<Radio />} label="eBooks" />
                        <FormControlLabel classes={{label: classes.label}} value="pdf" control={<Radio />} label="One Sheets" />
                        <FormControlLabel classes={{label: classes.label}} value="interactivity" control={<Radio />} label="Presentation Slides" />
                        <FormControlLabel classes={{label: classes.label}} value="video" control={<Radio />} label="Videos" />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Ad Products
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="AdProducts"
                        name="AdProducts"
                        value={cadProductF}
                        onChange={AdHandleChange}
                      >
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="CTV"
                          control={<Radio />}
                          label="CTV"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Display"
                          control={<Radio />}
                          label="Display"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Email Marketing"
                          control={<Radio />}
                          label="Email Marketing"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Offline Visits"
                          control={<Radio />}
                          label="Offline Visits"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Geofencing"
                          control={<Radio />}
                          label="Geofencing"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Listings Management"
                          control={<Radio />}
                          label="Listings Management"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Native"
                          control={<Radio />}
                          label="Native"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="OTT"
                          control={<Radio />}
                          label="OTT"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="SEM"
                          control={<Radio />}
                          label="SEM"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="SMS - Mobile Messaging"
                          control={<Radio />}
                          label="SMS - Mobile Messaging"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Social"
                          control={<Radio />}
                          label="Social"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Streaming Audio"
                          control={<Radio />}
                          label="Streaming Audio"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Video"
                          control={<Radio />}
                          label="Video"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Video Geofencing"
                          control={<Radio />}
                          label="Video Geofencing"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Radio"
                          control={<Radio />}
                          label="Radio"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="YouTube"
                          control={<Radio />}
                          label="YouTube"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Marketron Tools
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="MarketronTools"
                        name="MarketronTools"
                        value={cmrToolsF}
                        onChange={ToolsHandleChange}
                      >
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Email Marketing"
                          control={<Radio />}
                          label="Email Marketing"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Listings Management"
                          control={<Radio />}
                          label="Listings Management"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="NXT"
                          control={<Radio />}
                          label="NXT"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="NXT With Digital"
                          control={<Radio />}
                          label="NXT With Digital"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Pitch"
                          control={<Radio />}
                          label="Pitch"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="SMS - Mobile Messaging"
                          control={<Radio />}
                          label="SMS - Mobile Messaging"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Insight for NXT"
                          control={<Radio />}
                          label="Insight for NXT"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="Rev"
                          control={<Radio />}
                          label="Rev"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>Duration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="Duration"
                        name="Duration"
                        value={cdurF}
                        onChange={DurHandleChange}
                      >
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="5"
                          control={<Radio />}
                          label="<5 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="15"
                          control={<Radio />}
                          label="<15 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="30"
                          control={<Radio />}
                          label="<30 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="30_60"
                          control={<Radio />}
                          label="30 m to 60 m"
                        />
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          value="60"
                          control={<Radio />}
                          label=">60 m"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

            {/* <StyledMenuItem>
            
            <ListItemText primary="Drafts" />
          </StyledMenuItem>
          <StyledMenuItem>
            
            <ListItemText primary="Inbox" />
          </StyledMenuItem> */}
          </ListItem>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={clear}
              style={{ width: "100px" }}
            >
              Clear
            </Button>
          </div>
        </StyledMenu>
      </div>
    );
  }

  const handleClickS = (event) => {
    setAnchorElS(event.currentTarget);
    setOpenS(!openS);
  };

  const handleCloseS = () => {
    setAnchorElS(null);
    setOpenS(!openS);
  };

  const sortTypeChange = (event) => {
    setCStypeF(event.target.value);
    let val = event.target.value;
    sortFun(val);
    commansetsort(val);
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setCMrToolsF = cmrToolsF;
    filterstoredata.filter.setCAdProductF = cadProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setCDurF = cdurF;
    filterstoredata.filter.setCStypeF = event.target.value;
    dispatch(awsSignIn(filterstoredata));
  };
  const commansetsort = (val) => {
    if (val == "TV") {
      setCStypeFV("Most Viewed");
    }
    if (val == "STAR") {
      setCStypeFV("Rating");
    }
    if (val == "BKMD") {
      setCStypeFV("Most Bookmarked");
    }
    if (val == "SHRD") {
      setCStypeFV("Most Shared");
    }
    if (val == "CON") {
      setCStypeFV("Newest");
    }
    if (val == "sqno") {
      setCStypeFV("Sequence");
    }
    if (val == "AZ") {
      setCStypeFV("A-Z");
    }
  };
  function sortFun(val, obdata) {
    let data = obdata == undefined ? [...courses] : [...obdata];
    if (val == "STAR") {
      val = "starAvg";
    }
    if (val == "AZ") {
      data.sort((a, b) => {
        if (a.ttitle < b.ttitle) {
          return -1;
        }
        if (a.ttitle > b.ttitle) {
          return 1;
        }
        return 0;
      });
    } else if (val == "ZA") {
      data
        .sort((a, b) => {
          if (a.ttitle < b.ttitle) {
            return -1;
          }
          if (a.ttitle > b.ttitle) {
            return 1;
          }
          return 0;
        })
        .reverse();
    } else {
      data.sort((a, b) => b[val] - a[val]); //.reverse();
    }
    setCourses(data);
  }
  function sortBy() {
    return (
      <div>
        <ListItem button onClick={handleClickS}>
          {/* <ListItemText className={classes.heading} primary="Filter By" /> */}
          <Typography
            className={classes.heading}
            style={{ width: "208px", display: "flex" }}
          >
            <span style={{ flexGrow: "30%" }}> Sort By </span>
            <span style={{ color: "blue", flexGrow: "1", textAlign: "center" }}>
              {cstypeV}
            </span>
          </Typography>

          {openS ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorElS}
          keepMounted
          open={Boolean(anchorElS)}
          onClose={handleCloseS}
          style={{ maxHeight: "80%" }}
        >
          <ListItem button>
            <div style={{ width: "200px" }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="sortType"
                  name="sortType"
                  value={cstype}
                  onChange={sortTypeChange}
                >
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="TV"
                    control={<Radio />}
                    label="Most Viewed"
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="STAR"
                    control={<Radio />}
                    label="Rating"
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="BKMD"
                    control={<Radio />}
                    label="Most Bookmarked"
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="SHRD"
                    control={<Radio />}
                    label="Most Shared"
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="CON"
                    control={<Radio />}
                    label="Newest"
                  />
                  {/* <FormControlLabel
                    classes={{ label: classes.label }}
                    value='sqno'
                    control={<Radio />}
                    label='Sequence'
                  /> */}
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
        </StyledMenu>
      </div>
    );
  }

  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ fontSize: "1.6rem" }}
      />
    );
  }

  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <div>
      {userDetails != 0 && (
        <>
          {userDetails.location !== undefined && errorD == 0 ? (
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="large"
                  style={{ fill: "#1974b5" }}
                />
              }
              aria-label="breadcrumb"
              style={{ marginBottom: "3rem" }}
            >
              <Link
                color="inherit"
                to="/home"
                style={{
                  color: "#777",
                  fontSize: "1.5rem",
                  textDecoration: "none",
                  cursor: "context-menu",
                }}
              >
                Home
              </Link>
              <Link
                color="inherit"
                to="/ExploreCourseView"
                style={{
                  color: "#777",
                  fontSize: "1.5rem",
                  textDecoration: "none",
                }}
              >
                {proGm.pname}
              </Link>
            </Breadcrumbs>
          ) : null}
          {userDetails.location.from !== undefined &&
            (userDetails.location.from === "Home" ||
              userDetails.location.from === "Explore" ||
              userDetails.location.from === "Search") && (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="large"
                    style={{ fill: "#1974b5" }}
                  />
                }
                aria-label="breadcrumb"
                style={{ marginBottom: "3rem" }}
              >
                <Link
                  color="inherit"
                  to={userDetails.location.from === "Home" ? "/home" : "#"}
                  style={{
                    color: `${
                      userDetails.location.from === "Home" ? "#1974b5" : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: `${
                      userDetails.location.from === "Home"
                        ? "pointer"
                        : "context-menu"
                    }`,
                  }}
                >
                  {userDetails.location.from}
                </Link>
                {userDetails.location.topicName && (
                  <Link
                    style={{
                      color: "#1974b5",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                    color="inherit"
                    to={`/explore/${userDetails.location.topicName.replaceAll(
                      /\s/g,
                      "-"
                    )}/${userDetails.location.topicId}`}
                  >
                    {userDetails.location.topicName}
                  </Link>
                )}
                {userDetails.location.viewAll &&
                  userDetails.location.programName && (
                    <Link
                      to="/ExploreViewAll"
                      style={{
                        color: "#1974b5",
                        fontSize: "1.5rem",
                        textDecoration: "none",
                      }}
                      color="inherit"
                    >
                      View all
                    </Link>
                  )}
                {userDetails.location.viewAll &&
                  userDetails.location.viewAllId && (
                    <Link
                      to="/ExploreViewAll"
                      style={{
                        color: "#777",
                        fontSize: "1.5rem",
                        textDecoration: "none",
                      }}
                      color="inherit"
                    >
                      View all
                    </Link>
                  )}
                {userDetails.location.programName && (
                  <Link
                    color="inherit"
                    to="/ExploreCourseView"
                    style={{
                      color: "#777",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                  >
                    {userDetails.location.programName}
                  </Link>
                )}
              </Breadcrumbs>
            )}

          <div className="flex-between">
            <div className="flex-container" style={{ width: "100%" }}>
              <h3 style={{ width: "100%", fontWeight: "600" }}>
                {userDetails.location.programName}
              </h3>
              <strong
                style={{
                  float: "right",
                  paddingRight: "4.0rem",
                  cursor: "pointer",
                  display: "flex",
                }}
              ></strong>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ width: "100%" }}>
                {" "}
                <ExploreSort
                  handleClickS={handleClickS}
                  anchorElS={anchorElS}
                  handleCloseS={handleCloseS}
                  sortTypeChange={sortTypeChange}
                  stypeV={cstypeV}
                  openS={openS}
                  stype={cstype}
                  sorttp={userDetails.location.from}
                />
              </span>
              {/* <span style={{ width: "115px" }}>{filterby()}</span> */}
              <span>
                {
                  <ExploreFilter
                    handleClick={handleClick}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    contentTypeChange={contentTypeChange}
                    AdHandleChange={AdHandleChange}
                    adProductF={cadProductF}
                    DurHandleChange={DurHandleChange}
                    open={open}
                    ctype={ctype}
                    clear={clear}
                    ToolsHandleChange={ToolsHandleChange}
                    mrToolsF={cmrToolsF}
                    durF={cdurF}
                    type="course"
                  />
                }
              </span>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "4rem",
              }}
            >
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
            </div>
          ) : (
            <Grid
              container
              spacing={3}
              style={{
                marginTop: "2rem",
                boxShadow: Theme.boxShadow,
                backgroundColor: "#fff",
                padding: "2rem",
                borderRadius: "5px",
              }}
            >
              {courses.map(
                ({ tid, ttitle, noofnuggets, starAvg, cobj, tobj }, idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={idx + "sdlkfd"}
                    onClick={() => handleLocation(tid, ttitle, proGm)}
                  >
                    <CardLarge tid={tid} title={ttitle} count={noofnuggets} />
                  </Grid>
                )
              )}

              {courses.length === 0 ? (
                <div
                  style={{
                    height: "75vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h2>Nothing here yet</h2>
                </div>
              ) : null}
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseA}
                message="Copied the link..!"
              >
                <Alert onClose={handleCloseA} severity="success">
                  {courseData.length} Results
                </Alert>
              </Snackbar>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

export default ExploreCourseView;
