import React from "react";
import PropTypes from "prop-types";
import { Theme } from "../../aws-exports";
import {
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxStyle: {
    width: "150px",
    height: "150px",
    zIndex: "7",
    '@media (max-width: 800px)': {
      width: "80px",
      height: "80px",
    },
    // //http://localhost:3000/#/course?id=ED00102
  },
  boxStyle2: {
    width: "150px",
    height: "150px",
    right: "0%",
    '@media (max-width: 800px)': {
      width: "80px",
      height: "80px",
    },
  },
  circleStyle: {
    color: "#77777777",
    width: "150px!important",
    height: "150px!important",
    '@media (max-width: 800px)': {
      width: "80px!important",
      height: "80px!important",
    },
  },
  circleStyle1: {
    borderRadius: "50%",
    width: "150px!important",
    height: "150px!important",
    color: `${Theme.color.primary}`,
    '@media (max-width: 800px)': {
      width: "80px!important",
      height: "80px!important",
    },
  },
}));

function CircularProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <div style={{ position: "relative" }}>
      <Box
        position="relative"
        display="inline-flex"
        className={classes.boxStyle}
      >
        <CircularProgress
          thickness={2.5}
          variant="determinate"
          className={classes.circleStyle1}
          {...props}circleStyle1
        />

        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            style={{
              fontSize: "22px",
              fontWeight: "500",
              color: "black",
            }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
      <Box
        position="absolute"
        display="inline-flex"
        className={classes.boxStyle2}
      >
        <CircularProgress
          thickness={2.5}
          variant="determinate"
          className={classes.circleStyle}
          value={100}
        />
      </Box>
    </div>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({ courseDetails, totalProgress, open }) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (courseDetails.userdataset.userdata == true) {
      if (
        courseDetails.userdataset.tp !== undefined &&
        (courseDetails.userdataset.tp.charAt(0) == 2 ||
          courseDetails.userdataset.tp.charAt(0) == 3)
      ) {
        setProgress(100);
      } else {
        let cobj = totalProgress === undefined ? 0 : totalProgress;
        setProgress((cobj / courseDetails.tobj) * 100);
      }
    }

    // const timer = setInterval(() => {
    //   setProgress(prevProgress =>
    //     prevProgress >= 100 ? 0 : prevProgress + 10
    //   );
    // }, 3000);
    // return () => {
    //   clearInterval(timer);
    // };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}
