import React, { useCallback } from "react";
//React router
import { Link, useHistory } from "react-router-dom";

// Swiper
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { breakpoints } from "../utils/Breakpoints";
import "swiper/components/navigation/navigation.scss";
import noThingIsHere from "../../../assets/nothing-here-icon.png";
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper";

// import { cardData } from '../../Data';
import CardLarge from "../../Cards/CardLarge/CardLarge";

import { API } from "aws-amplify";
import  { useState , useEffect} from "react";
import { useQuery } from "react-query";

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import { setViewAllArrData } from "../../../redux/HomeData/HomeDataSlice";
import "../Carousel.scss";
import { makeStyles } from "@material-ui/styles";
import ContentCard from "../../Cards/ContentCard/ContentCard";
import Loading from "../../../components/Loading/Loading";

import config from "../../../aws-exports";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual]);
const BookmarkContentCarousel = ({ heading, cardData, loading, ...rest }) => {
 
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const useStyles = makeStyles({
    outline: {
      fontSize: "3rem",
      fill: "none",
      stroke: "#646464",
      strokeWidth: "1px",
      strokeLinejoin: "round",
    },
    filled: {
      fontSize: "3rem",
      fill: "#FFDA46",
      stroke: "#FFDA46",
      strokeWidth: "1px",
      strokeLinejoin: "round",
    },
  });
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { isLoading, error, data } = useQuery("/listBookMark", async () => {
    const bodyParam = {
      body: {
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
        tenant: userDetails?.locale,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(userDetails, "===", bodyParam);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listBookMark",
        bodyParam
      );
      const { items } = response;

      console.log("BOokmark", items);
      console.log("List", list);

      return items;
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
     
    }
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    if (data) {
      setList(data);
    }
  }, [data]);

  const classes = useStyles();
  const history = useHistory();
  function bookMarkFilled() {
    return classes.filled;
  }

  /*  let src1 = `${config.aws_cloudfront_url}marketron-resources/images/program-images/${pid}.png`; */

  async function deleteBookmark(tid, type) {
    const bodyParam = {
      body: {
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (type === 1) {
      bodyParam.body.tid = tid;
    } else {
      bodyParam.body.objid = tid;
    }
    bodyParam.body.bookmark = false;
    let book = list.filter((prev) => prev.tid !== tid);
    setList(book);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        type === 1 ? "/updateTopicReport" : "/updateContentReport",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  }
  let src = "";
  const handleLocation = (data, btype, tid) => {
    let breadcrumb = { ...userDetails };
    let value = data[0];
    if (btype === 1) {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        courseName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`course?id=${tid}`);
    } else {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        contentName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`content?id=${tid}`);


    }
  };


  return (
    <div className="carousel-container">
      <div className="heading-container">
        <h2 className="carousel-heading">{heading}</h2>
        {!loading && cardData?.length >= 10 ? (
          <Link
            to="viewallcontent"
            style={{
              marginRight: "2rem",
              fontSize: "1.7rem",
              fontWeight: "600",
            }}
            className="viewAllActive"
           // onClick={handleViewAll}
          >
            View all
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className="swiper_wrapper">
        {!loading && cardData && cardData?.length > 0 && (
          <Swiper
            ref={sliderRef}
            breakpoints={breakpoints}
            spaceBetween={8}
            slidesPerGroup={4}
            slidesPerView={4}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onInit={() => {
              console.log("Swiper initialized");
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: false }}
            className="slide-container"
          >
            {cardData.map(
              (
                {
                  title,
                  vtid,
                  tid,
                  otype,
                  ttitle,
                  type,
                  pname,
                  objid,
                  dur,
                  noofnuggets,
                  pcount,
                  starAvg,
                  ref_type,
                  oname,
                  sortdt,
                  dtls,
                  bk,
                  ravg,
                  tname,
                  bookmarkPage,
                  bookmark_date,
                  star,
                  sub_date,
                  comp_date
                
                },
                idx
              ) => (
                <SwiperSlide key={"ddlJ" + idx} className="slider-container">
                  {type === 1 ? (
                   <CardLarge
                   bookmarkPage={true}
                   tid={tid}
                   date={bookmark_date}
                   title={tname[0]?.title}
                   star={star}
                   onClick={() => deleteBookmark(tid, type)}
                   handleLocation={() =>
                     handleLocation(tname, type, tid)
                   }
                   sub_date={sub_date}
                   comp_date={comp_date}
                 />
                  ) : (
                    <ContentCard
                          bookmarkPage={true}
                          date={bookmark_date}
                          otype={tname[0]?.otype}
                          title={tname[0]?.title}
                          star={star}
                          onClick={() => deleteBookmark(tid, type)}
                          handleLocation={() =>
                            handleLocation(tname, type, tid)
                          }
                        />
                  )}
                </SwiperSlide>
              )
            )}
          </Swiper>
        )}
        <span
          id="swiper-right"
          className={`arrow arrow-right ${
            cardData?.length === 0 ? "hide" : ""
          }`}
          onClick={handleNext}
        ></span>
        <span
          id="swiper-left"
          className={`arrow arrow-left ${cardData?.length === 0 ? "hide" : ""}`}
          onClick={handlePrev}
        ></span>
        <div className="flex-loading">
          {loading && (
            <React.Fragment>
              {[...Array(4).fill(0)].map((_, index) => (
                <Loading />
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
      {cardData?.length === 0 && (
        <div
          className="slick-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{
            opacity: 0,
            transition: { duration: 1, ease: "easeInOut" },
          }}
        >
          <img src={noThingIsHere} alt="noThingIsHere" />
          {/* <h3>
                    No {props.viewtype === "course" ? "Course" : "Content"} Data
                  </h3> */}
        </div>
      )}
    </div>
  );
};

export default React.memo(BookmarkContentCarousel);
