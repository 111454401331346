import { Container } from "@material-ui/core";
import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Layout.scss";
import { motion } from "framer-motion";

const mainVarients = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: { delay: 0.5, duration: 0.5, type: "spring", stiffness: 100 },
  },
  exit: {
    x: "-100vw",
    transition: { delay: 1, duration: 1, ease: "easeInOut" },
  },
};

function Layout({ children }) {
  return (
    <div className="Layoutmain">
      <Header />
      <div className="mycontainer">
        <motion.main
          className="main"
          variants={mainVarients}
          // initial="hidden"
          // animate="visible"
          exit="exit"
        >
          <Container maxWidth="xl">
            {/* <Container maxWidth="xl" className="layout_container"> */}
            {children}
            {/* </Container> */}
          </Container>
        </motion.main>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
