import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import { API, Auth } from "aws-amplify";
import {
  Inject,
  ScheduleComponent,
  Day,
  Month,
  Week,
  WorkWeek,
  EventSettingsModel,
} from "@syncfusion/ej2-react-schedule";
import "./calendar.scss";

import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "start",
    //color: theme.palette.text.secondary,
    marginLeft: "50px",
  },
  paperEvents: {
    padding: theme.spacing(2),
    textAlign: "start",
    //color: theme.palette.text.secondary,
  },
  calenderSection: {
    display: "flex",
    gap: "4rem",
    paddingTop: "3rem",
    //paddingLeft: "3rem",
    fontFamily: `myfontregular`,
    '@media (max-width: 800px)': {
      display: "grid",
      paddingLeft: 0,
    },
  },
  calenderContent: {
    width: "80%",
    display: "grid",
    fontFamily: `myfontregular`,
    '@media (max-width: 800px)': {
      width: "100%",
      display: "grid",
    },
  },
  eventsContent: {
    width: "55%" ,
    fontFamily: `myfontregular`,
    '@media (max-width: 800px)': {
      marginTop: "4rem",
      width: "100%",
    },
  },
}));

function Calender() {
  const classes = useStyles();
  let userDetails = useSelector(authData);
  const [events, setevents] = useState([]);
  const [upEvents, setupEvents] = useState([]);

  const [allEvents, setAllEvents] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    getEvents();
    return () => {};
  }, []);

  async function getEvents() {
    console.log("res");
    //alert(JSON.stringify(userDetails))

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getEvents`,
        bodyParam
      );
      console.log("respon", response);
      convertEventData(response.events);
      setAllEvents(response.events);
      //alert(JSON.stringify(response))
      /*  convertEventData(response.result.events);
      setAllEvents(response.result.events);
      setIsLoading(false); */
      //upcoming();
      //alert("Events "+JSON.stringify(EventsJSON));
    } catch (error) {
      console.error(error);
    }
  }

  function convertEventData(data) {
    let upEvents = data;

    /* if (data.length > 5) {
      upEvents = data.slice(-3);
      // setupEvents(upEvents);
    } */
    filter(upEvents);
    function filter(data) {
      let m = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        let res = new Date(Number(data[i].start));

        let d = new Date();
        // let month = 0;

        if (
          res.getFullYear() == d.getFullYear() &&
          res.getMonth() >= d.getMonth() &&
          res.getDate() >= d.getDate()
        ) {
          let obj = {
            title: data[i].title,
            desc: data[i].desc,
            month: m[res.getMonth()],
            day: res.getDate(),
            link: data[i].link,
          };
          temp.push(obj);
        }
      }
      setupEvents(temp);
    }
    //alert(JSON.stringify(data))
    let temp = [];

    for (let i = 0; i < data.length; i++) {
      let res = new Date(Number(data[i].start));

      let obj = {
        title: data[i].title,

        startTime: res,
        endTime: res,
      };
      temp.push(obj);
    }
    setevents(temp);
  }
  let fields = {
    subject: { name: "title", default: "Event" },
    //description: { name: 'description' },
    startTime: { name: "startTime" },
    endTime: { name: "endTime" },
  };
  console.log("up", events);
  console.log("up", upEvents);
  return (
    <div >
      <div className="flex-between">
        <div className="flex-container" style={{justifyContent:"start"}}>
          <h3
            component="h1"
            variant="h4"
            style={{ fontWeight: "600", fontSize: "2rem", fontFamily: `myfontregular`}}
          >
            {/* {filterName[0].name} */}
            Calendar
          </h3>
        </div>
      </div>
      <div className={classes.calenderSection}>
        <Paper className={classes.calenderContent} elevation={8}>
          <ScheduleComponent
            currentView="Month"
            width="100%"
            readonly={true}
            fontFamily= "myfontregular"
            //timeScale={{ enable: false}}
            eventSettings={{
              dataSource: events,
              fields: fields,
              enableTooltip: false,
            }}
          >
            <Inject style={{fontFamily: `myfontregular` }}services={[Month, Day, Week, WorkWeek]} />
          </ScheduleComponent>
        </Paper>
        <div className={classes.eventsContent}>
          <h3
            style={{
              marginTop: "-52px",
              marginLeft: "-0px",
              fontWeight: "600",
              fontSize: "2rem",
              paddingBottom: "18px",
              fontFamily: `myfontregular`
            }}
            className="titleStyle"
          >
            Upcoming events
          </h3>
          <Paper className={classes.paperEvents} elevation={8}>
            {upEvents.length === 0 || upEvents === undefined ? (
              <p style={{ fontSize: "16px", textAlign: "center" }}>
                {" "}
                Nothing here yet
              </p>
            ) : (
              upEvents.map((event) => (
                <div style={{ marginTop: "30px" ,fontFamily: `myfontregular`}}>
                  {/* <Typography component="list" variant="h1">
                  {isLoading ? <Skeleton /> : null}
                </Typography>
                <Typography component="list" variant="h1"> 
                  {isLoading ? <Skeleton /> : null}
                </Typography> */}
                  <div>
                    <div className="iconTop">{event.month}</div>
                    <div className="iconBottom">{event.day}</div>
                  </div>
                  <div
                    style={{
                      fontSize: "medium",
                      whiteSpace: "normal",
                      minHeight: "10px",
                      marginLeft: "61px",
                      fontFamily: `myfontregular`,
                    }}
                  >
                    <div style={{ marginTop: "-22px" ,}}>{event.title}</div>
                    <div style={{ fontSize: "12px" }}>{event.desc}</div>
                    {event.link && (
                      <div>
                        <span>
                          Link:{" "}
                          <a href={event.link} target="_blank" rel="noreferrer">
                            {event.link}
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                  <hr className="eventsDivider" />
                </div>
              ))
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default Calender;
