import React, { useState, useEffect } from "react";
import Amplify, { API } from "aws-amplify";

import App from "./App";
import awsExports from "./aws-exports";
import PageLoading from "./components/PageLoading/PageLoading";
import { motion, AnimatePresence } from "framer-motion";

function AppLoad() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function apiload() {
      let domain = window.location.hostname.replace("www.", "");

      const bodyParam = {
        body: {
          domain,
          // domain: "d13trvs3ngcag9.cloudfront.net",
          // domain: "www.excelearn.enhanzed.com",
          // domain: "excelearn.enabled.live",
          // domain: "www.iciciprudna.enabled.live",
          // domain: "www.nse.enabled.live",
          // domain: "www.enabled.live",
          // domain: "www.axiata.enabled.live"
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const response = await API.post(
          awsExports.aws_cloud_logic_custom_name,
          "/getConfig",
          bodyParam
        );
        console.log("getconfig", response);
        setConfig(response);
      } catch (error) {
        console.error(error);
      }
    }
    // setTimeout(apiload, 5000);
    apiload();
  }, []);

  function setConfig(res) {
    var r = document.querySelector(":root");

    r.style.setProperty("--color-grey-dark-1", res.mainColor1);
    r.style.setProperty("--color-grey-dark-2", res.mainColor1);
    r.style.setProperty("--sub-heading-color", res.mainColor2);
    r.style.setProperty("--button-background", res.mainColor2);
    r.style.setProperty("--header-color", res.mainColor2);
    r.style.setProperty("--color-button-background", res.mainColor2);
    r.style.setProperty("--color-primary", res.mainColor1);
    r.style.setProperty(
      "--linear-gradient",
      `linear-gradient(${res.mainColor1}, ${res.mainColor1})`
    );
    console.log("res===", res);
    document.title = res.title;
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    link.href = `https://${
      res.domain
    }/${res?.oid?.toLowerCase()}-resources/images/org-images/App-icon.png`;

    console.log("appicon", link.href);
    awsExports.aws_org_id = res.oid;
    awsExports.main_color_1 = res.mainColor1;
    awsExports.main_color_2 = res.mainColor2;
    awsExports.platform_main_theme = `linear-gradient(${res.mainColor1}, ${res.mainColor1})`;
    awsExports.button_color_web = res.mainColor1;
    awsExports.DOMAIN = res.domain;
    // alert(awsExports.main_color_1);
    awsExports.aws_cloudfront_url = "https://" + res.domain + "/";
    awsExports.aws_cloudfront_lurl = "https://" + res.domain;
    awsExports.aws_cognito_identity_pool_id = res.identitypoolid;
    awsExports.aws_user_pools_id = res.webpoolid;
    awsExports.aws_user_pools_web_client_id = res.webclientid;
    if (res.SCHEMA_NAME !== undefined) {
      awsExports.schema = res.SCHEMA_NAME;
    }
    if (res.webinvokeurl !== undefined) {
      awsExports.aws_cloud_logic_custom_endpoint = res.webinvokeurl;
      awsExports.aws_cloud_logic_custom_name = res.webapiname;
      awsExports.aws_cloud_logic_custom = [
        {
          id: res.webapiid,
          name: res.webapiname,
          description: "",
          endpoint: res.webinvokeurl,
          region: "ap-south-1",
        },
      ];
    }
    Amplify.configure(awsExports);
    setIsLoading(false);
  }
  return (
    <AnimatePresence>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {isLoading ? <PageLoading /> : <App />}
      </motion.div>
    </AnimatePresence>
  );
}

export default AppLoad;
