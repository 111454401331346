import React, { useEffect, useRef, useState } from "react";
import "./FormativeQuiz.scss";

import config from "../../aws-exports";

import { API } from "aws-amplify";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { HelpOutline, Timer } from "@material-ui/icons";
import { useQueryParams } from "../../Hooks/useQueryParams";

const SummativeQuiz = ({
  question,
  setQuestion,
  loading,
  setLoading,
  startQuiz,
  curObject,
  setProgress,
  setMarkComplete,
  syncUserProgresswhilequiz,
  courseDetails,
  qpage,
  ...rest
}) => {
  const userDetails = useSelector(authData);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [tryBtnShow, setTryBtnShow] = useState(false);
  const [unAnsweredPopup, setUnAnsweredPopup] = useState(false);

  const retake = useRef(false);
  const query = useQueryParams();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 150,
    width: 350,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  };

  useEffect(() => {}, []);

  function handleQuizSubmit() {
    if (
      question.filter((e) => e.youAnswered === undefined || false).length > 0
    ) {
      //newly added
      setUnAnsweredPopup(true);
    } else {
      let totalscore = question.filter((item) => item.answeredOption).length;

      let objsr = {};
      objsr.oid = curObject.oid;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var date1 = date.getDate();
      var sdate = date1 + "." + month + "." + year;
      objsr.name = curObject.otitle;
      objsr.cdate = sdate;
      objsr.score = totalscore;
      objsr.tquestions = question.length;

      rest.setPerScore((totalscore / question.length) * 100);
      rest.setTotalScore(totalscore);
      if (curObject.op === 1) {
        console.log("1persss===" + (totalscore / question.length) * 100);
        rest.setShowScore(true);
        setMarkComplete(true);
        syncUserProgresswhilequiz(totalscore, "sumative");
        rest.lastobject.current = true;
      }
      // else {
      //   console.log("persss222===" + (totalscore / question.length) * 100);
      //   handleScoreSubmit(objsr);
      //   setMarkComplete(true);
      //   setTryBtnShow(true);
      //   rest.setShowScore(true);
      // }
      rest.setTimerPalyPause("pause");
    }
  }

  async function handleScoreSubmit(scorejson) {
    //alert(JSON.stringify(scorejson))
    setLoading(true);
    const bodyParam = {
      body: {
        emailid: userDetails.email,
        pid: courseDetails.pid,
        key: courseDetails.tid,
        email: userDetails.username,
        oid: config.aws_org_id,
        uname: userDetails.name,
        tenant: userDetails.locale,
        scorejson,
        score: scorejson.score,
        totalqns: scorejson.tquestions,
        obj_id: curObject.oid,
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      console.log("quizscoresave====" + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      console.log("updateSummativeQuiz==" + response);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleAnswerClick = (idx) => {
    const currentAnsquestion = [...question];
    currentAnsquestion[currentQuestion].iopts.map((item) => {
      item.youAnswered = false;
      return item;
    });

    currentAnsquestion[currentQuestion].iopts[idx].youAnswered = true;

    if (currentAnsquestion[currentQuestion].iopts[idx].youAnswered === true) {
      delete currentAnsquestion[currentQuestion].skip;
    }
    currentAnsquestion[currentQuestion].youAnswered = true;
    if (currentAnsquestion[currentQuestion].iopts[idx].correct) {
      currentAnsquestion[currentQuestion].answeredOption = true;
    } else {
      currentAnsquestion[currentQuestion].answeredOption = false;
    }

    setQuestion(currentAnsquestion);
  };

  const handleNextQuestion = () => {
    setQuestion((prevQues) => {
      let yourAns = prevQues[currentQuestion].youAnswered;
      if (yourAns === undefined || yourAns == null) {
        prevQues[currentQuestion].skip = true;
      }
      return prevQues;
    });
    if (currentQuestion !== question?.length - 1)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestion !== 0)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion - 1);
  };

  const gotoQuestion = (idx) => {
    setCurrentQuestion(idx);
  };
  return (
    <React.Fragment>
      {!loading && startQuiz && !rest.showScore && (
        <div className="quiz_container">
          <div className="quiz_wrapper">
            <div className="quiz_wrapper_ques_ans">
              <div
                className="quiz_wrapper_ques_ans--question"
                style={{ marginTop: "2rem" }}
              >
                <div>
                  Q {currentQuestion + 1}/{question?.length}
                </div>
                <div>
                  <p>{question[currentQuestion]?.istem}</p>
                </div>
              </div>

              {question[currentQuestion].imageurl && (
                <div className="quiz_wrapper_ques_ans_img-container">
                  <img
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${query.get(
                      "id"
                    )}/${question[currentQuestion].imageurl}`}
                    alt=""
                  />
                </div>
              )}
              <div className="quiz_wrapper_ques_ans--answers">
                {question[currentQuestion]?.iopts.map((item, index) => (
                  <div
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={`ans-btn ${
                      item.youAnswered ? "selected-answer" : ""
                    }
                    ${
                      question[currentQuestion].checkAnswer && item.correct
                        ? "green"
                        : ""
                    }
                    `}
                    onClick={() => handleAnswerClick(index)}
                  >
                    {item.content}
                  </div>
                ))}
              </div>
            </div>

            <div className="quiz_wrapper_grow" />

            <div className="quiz_wrapper--actions">
              <button
                className="btn btn-outline primary"
                onClick={handlePreviousQuestion}
                disabled={currentQuestion === 0 ? true : false}
              >
                Previous
              </button>
              <div>
                {currentQuestion !== question?.length - 1 && (
                  <button
                    className="btn btn-contained primary"
                    onClick={handleNextQuestion}
                    disabled={
                      currentQuestion === question?.length - 1 ? true : false
                    }
                  >
                    Next
                  </button>
                )}
                {currentQuestion === question?.length - 1 && (
                  <button
                    className="btn btn-contained primary"
                    onClick={() => {
                      if (qpage !== undefined && qpage === "object") {
                        rest.setStartQuiz(false);
                        setCurrentQuestion(0);
                      } else {
                        handleQuizSubmit();
                      }
                    }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>

          <hr className="divider" />

          <div className="quiz_container--footer">
            {question?.map((item, index) => (
              <button
                key={item.iid}
                style={{ cursor: "pointer" }}
                className={`btn contained ${item.skip ? "border" : ""} ${
                  item.youAnswered ? "green" : ""
                }`}
                onClick={() => gotoQuestion(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          {/* <pre>{JSON.stringify(question, null, 2)}</pre> */}
        </div>
      )}

      {rest.showScore && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  padding: "24px",
                  border: tryBtnShow ? "1px solid red" : "1px solid green",
                  width: "180px",
                  height: "155px",
                  borderRadius: "8px",
                  boxShadow: "1px 2px 2px 2px #f1f5f8",
                  backgroundColor: "#f1f5f8",
                  textAlign: "center",
                  marginBottom: "2.5rem",
                }}
                className="flex-center"
              >
                <h3
                  style={{
                    textTransform: "capitalize",
                    color: "#555555",
                    marginBottom: "1rem",
                  }}
                >
                  Your score
                </h3>
                <h2 style={{ textTransform: "capitalize" }}>
                  {/* {rest.totalScore && rest.totalScore} */}
                  {curObject.score ? curObject.score[0] : rest.totalScore}
                </h2>
              </div>
            </div>
          </div>

          {tryBtnShow ? (
            <>
              <h3
                style={{
                  margin: "2.5rem 0",
                  textAlign: "center",
                  color: "#555555",
                  fontSize: "1.75rem",
                  maxWidth: "calc(100% - 15%)",
                }}
              >
                We're sorry, but you did not achieve a passing score of 75% in
                order to earn the Certificate of Completion. Please try again.
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  className="button-3"
                  onClick={() => {
                    setMarkComplete(false);
                    // setRetakeExam(true);
                    retake.current = true;
                    rest.getQuiz(curObject);
                    rest.setShowScore(false);

                    setProgress(Number(curObject.oduration));
                  }}
                >
                  {" "}
                  Retake exam{" "}
                </button>
              </div>
            </>
          ) : (
            <div>
              {rest.curNugget?.objects[rest.curObjIndex] !== undefined &&
                courseDetails.nuggets[courseDetails.nuggets.length - 1].objects[
                  courseDetails.nuggets[courseDetails.nuggets.length - 1]
                    .objects.length - 1
                ].oid !== rest.curNugget?.objects[rest?.curObjIndex].oid && (
                  <>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        textAlign: "center",
                        marginTop: "2.5rem",
                      }}
                    >
                      Please let us know the feedback by taking a short survey
                      below:
                    </h2>
                  </>
                )}
              {loading ? (
                <CircularProgress color="default" size={30} />
              ) : (
                <div className="flex-center">
                  {rest.curNugget?.objects[rest.curObjIndex + 1] == undefined ||
                  (rest.curNugget?.objects[rest.curObjIndex + 1].op == 2 &&
                    (curObject.op == 2 ||
                      rest.nextCourseref.current == true)) ? (
                    <button
                      className="button-3"
                      onClick={() => {
                        rest.setTimerPalyPause("pause");
                        setLoading(true);
                        const lastObj =
                          courseDetails.nuggets[
                            courseDetails.nuggets.length - 1
                          ].objects[
                            courseDetails.nuggets[
                              courseDetails.nuggets.length - 1
                            ].objects.length - 1
                          ];
                        const curObj =
                          rest.curNugget?.objects[rest.curObjIndex];
                        console.log({
                          lastObj,
                          lastobject: rest.lastobject.current,
                          curObj,
                        });
                        if (
                          courseDetails.certification === true &&
                          rest.lastobject.current &&
                          curObj !== undefined &&
                          lastObj.oid === curObj.oid
                        ) {
                          rest.getUserCertificate();
                          rest.lastobject.current = false;
                          console.log("hi 3");
                        } else if (
                          rest.lastobject.current &&
                          curObj !== undefined &&
                          lastObj.oid !== curObj.oid
                        ) {
                          console.log("hi 1");
                          rest.nextcourse();
                        } else {
                          rest.nextObject();
                          console.log("hi 2");
                          // rest.getTopicDetails();
                        }
                      }}
                    >
                      {rest.curNugget?.objects[rest.curObjIndex] !==
                        undefined &&
                      courseDetails.nuggets[courseDetails.nuggets.length - 1]
                        .objects[
                        courseDetails.nuggets[courseDetails.nuggets.length - 1]
                          .objects.length - 1
                      ].oid !==
                        rest.curNugget?.objects[rest.curObjIndex].oid ? (
                        <>Next</>
                      ) : (
                        <>Cancel</>
                      )}
                    </button>
                  ) : (
                    <button
                      className="button-3"
                      onClick={() => {
                        rest.setTimerPalyPause("pause");
                        setLoading(true);
                        rest.setStartQuiz(true);
                        retake.current = false;
                        setQuestion([]);
                        rest.getQuiz(
                          rest.curNugget?.objects[rest.curObjIndex + 1]
                        );
                        rest.nextObject();
                      }}
                    >
                      {rest.curNugget?.objects[rest.curObjIndex] !==
                        undefined &&
                      courseDetails.nuggets[courseDetails.nuggets.length - 1]
                        .objects[
                        courseDetails.nuggets[courseDetails.nuggets.length - 1]
                          .objects.length - 1
                      ].oid !==
                        rest.curNugget?.objects[rest.curObjIndex].oid ? (
                        <>Take survey</>
                      ) : (
                        <>Next</>
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <Modal open={unAnsweredPopup}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            You have some unanswered questions
          </Typography>
          <button
            className="okButton"
            onClick={() => setUnAnsweredPopup(false)}
          >
            OK
          </button>
        </Box>
      </Modal>
      {/* {JSON.stringify(lastobject)} */}
    </React.Fragment>
  );
};

export default SummativeQuiz;
